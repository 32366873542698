import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: '#f4f2f2',  
        borderRadius: 12,
        marginVertical: 3,
        overflow: 'hidden',
        
    },
    shipmentNo:{
        fontSize: 16,
        fontWeight: 'bold',
    },
    statusTitle:{
        fontSize:8,
        marginRight: 5,
        marginBottom: -3
    },
    status:{
        fontSize:12,
    }
});

export default styles;
