import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        minHeight: 52,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: '#fff',  
        borderRadius: 12,
        marginVertical: 3,
        overflow: 'hidden',
    },
    listText:{
        fontSize: 14,
        color: '#000',
        marginRight: 5,
        width: '20%',
    },
    listText1:{
        fontSize: 14,
        color: '#000',
        marginRight: 5,
        width: '15%',
        textAlign: 'right',   
    },
    itemButton:{
        marginLeft: 5, 
        marginTop: 4,
    },
    bottomLine:{
        height: 3, 
        backgroundColor: '#d72323',
    },
    bottomLine1:{
        height: 3, 
        backgroundColor: '#d72323',
        position: 'absolute',
        left: 0, 
        bottom: 0,
    },
    statusContainer:{
        position: 'absolute',
        left: 0, 
        bottom: 0,
        width: '100%'
    },
    statusTextWrapper:{
        flexDirection: 'row'
    },
    statusCompletedText:{
        width: '25%',
        fontSize: 10,
        paddingHorizontal: 10,
        color: '#d7bbbf'
        
    },
    statusActiveText:{
        width: '25%',
        fontWeight: '700',
        fontSize: 12,
        paddingHorizontal: 10,
    },
    statusOpenText:{
        width: '25%',
        fontSize: 10,
        opacity: '20%',
        paddingHorizontal: 10,
    },
    separatorLine:{
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        width:3, 
        height: 20,
        backgroundColor: '#d72323',
    },
    separatorLineOpen:{
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        width:3, 
        height: 20,
        backgroundColor: '#d72323',
        opacity: '20%'
    },
    payButton:{
        flexDirection: 'row',
        backgroundColor: '#d72323',
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 20,
        alignItems: 'center',
        marginVertical: -5,
    },
    addressWrapper:{
        marginTop: 15,
        borderRadius: 10,
        width: '100%',
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderColor: '#f5f2f2',
        borderWidth: 1,
        
    },
    itemWrapper:{
        marginTop: 5,
        borderRadius: 0,
        width: '100%',
        paddingVertical: 5,
        borderBottomColor: '#f5f2f2',
        borderTopColor: '#f5f2f2',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        
    },
    itemTitle:{
        fontSize: 10,
        fontWeight: '400',
        marginBottom: 5,
        color: 'gray'

    },
    lineItemDiv:{
        paddingVertical: 5,
        marginRight: 20
    },
    divTitle:{
        fontWeight: 'bold',
        marginTop: 20,
    },
    lineDiv:{
        flexDirection: 'row',
        marginBottom: 10
    }

        
    

});

export default styles;