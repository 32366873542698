import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native';
import { useAssets } from 'expo-asset';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Route from './src/navigation/main';
import { Dimensions } from 'react-native';
import {Provider} from 'react-redux' 
import thunk from 'redux-thunk'
import {createStore, applyMiddleware} from 'redux'

import rootReducer from './src/redux/reducers/index'

const store = createStore(rootReducer, applyMiddleware(thunk))
const Stack = createNativeStackNavigator();
const Container = styled.View`
display: block;
position: relative;
marginTop: 0;
flex: 1;
height: ${(Dimensions.get('window').height)}px;
background-color: yellow;
align-items: center;
justify-content: center;
`;
const Title = styled.Text`
color: #000;
text-align: center;
font-size: 16px;
`;

function App() {
  return (
    <Provider store={store}> 
      <Route />
    </Provider>
    
  );
}

function Main(){
  const [assets] = useAssets(
    require("./assets/icon.png")
  )
  if(!assets){
    return <Text>Loading ...</Text>
  }
  return <App />
}

export default App


