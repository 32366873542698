import { View } from 'react-native';
import React from 'react'
import styles from './styles'
import { Text } from 'react-native-web';

import { auth } from '../../../../redux/actions';


const ChatSingleItem = ({ item }) => {
  
  
  const isCurrentUser = item.creator === auth.currentUser.uid 

  return (
    
        <View style={[styles.container, { justifyContent: isCurrentUser ? 'flex-end' : 'flex-start' }]}>
            <View style={styles.bubbleSize}>
                <View style={[styles.bubble, { backgroundColor: isCurrentUser ? '#FFCFCF' : '#f5f2f2' }]}>
                    <View style={{ flexShrink: 1 }}>
                        <Text style={[styles.messageText, { color: isCurrentUser ? '#000' : '#262626' }]}>
                            {item.message}
                        </Text>
                    </View>
                    <View style={styles.bubbleTime}>
                        <Text style={[styles.bubbleTimeText, { color: isCurrentUser ? 'gray' : 'black'  }]}>
                            {item.creation? new Date(item.creation.seconds * 1000).toLocaleTimeString('en-GB').slice(0,5) : ''}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    
    
  );
}

export default ChatSingleItem