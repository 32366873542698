import { View, Text } from 'react-native'
import React from 'react'
import { TouchableOpacity } from 'react-native-web'
import { useDispatch } from 'react-redux'
import { SELECTED_COMPANY } from '../../../redux/constants'

export default function CompanyListItem({ item }) {
    const dispatch = useDispatch() 
    const handleSetCompany = () =>{
      dispatch({type: SELECTED_COMPANY, selectedCompany: item})
    }
  return (
    <View style={{paddingVertical: 5, borderBottomWidth: 1, borderBottomColor: 'white'}}>
        <TouchableOpacity
        onPress={() => handleSetCompany()}>
            <Text>{item.title}</Text>
        </TouchableOpacity>
    </View>
  )
}