import {CURRENT_USER_SHIPMENTS_UPDATE} from '../constants'

const initialState = {
    currentUserShipments: null, 
}

export const shipments = (state = initialState, action) => {
    switch(action.type){
        case CURRENT_USER_SHIPMENTS_UPDATE:
            return{
                ...state, 
                currentUserShipments: action.currentUserShipments,
            }
        default : 
            return state;
    }
}