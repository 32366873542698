import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'


import NewShipmentScreen from '../../screens/newShipment'
import ShipmentsScreen from '../../screens/shipments'
import ProfileScreen from '../../screens/profile'
import DashboardScreen from '../../screens/dashboard'
import InvoicesScreen from '../../screens/invoices'
import CheckoutScreen from '../../screens/checkoutPage'
import SupportScreen from '../../screens/support/list'
import { useChats } from '../../hooks/useChats'
import ChatSingleScreen from '../../screens/support/single'
import NewSupportCaseScreen from '../../screens/support/new'
import ShipmentScreen from '../../screens/shipment'
import AboutScreen from '../../screens/about'




const Stack = createNativeStackNavigator()

export default function HomeScreens() {
    useChats();
    return (
        
            <Stack.Navigator>
                <Stack.Screen name='dashboard' component={DashboardScreen} options={{headerShown: false}} />
                <Stack.Screen name='newShipment' component={NewShipmentScreen} options={{headerShown: false}} />
                <Stack.Screen name='shipments' component={ShipmentsScreen} options={{headerShown: false}} />
                <Stack.Screen name='shipment' component={ShipmentScreen} initialParams={{ id: null }} options={{headerShown: false}} />
                <Stack.Screen name='invoices' component={InvoicesScreen} options={{headerShown: false}} />
                <Stack.Screen name='profile' component={ProfileScreen} options={{headerShown: false}} />
                <Stack.Screen name='about' component={AboutScreen} options={{headerShown: false}} />
                <Stack.Screen name='support' component={SupportScreen} options={{headerShown: false}} />
                <Stack.Screen name='checkout' component={CheckoutScreen} initialParams={{ id: null }} options={{headerShown: false}} />  
                <Stack.Screen name='chatSingle' component={ChatSingleScreen} options={{headerShown: false}} />  
                <Stack.Screen name='newSupportCase' component={NewSupportCaseScreen} options={{headerShown: false}} /> 
            </Stack.Navigator>
    )
}
