import { View, Text } from 'react-native'
import React, { useState } from 'react'
import { ContentContainerWhite, NavTitle, } from '../../layout/'
import { ActivityIndicator, CheckBox, FlatList, TextInput, TouchableOpacity } from 'react-native-web'
import styles from './styles'
import CompanyListItem from '../CompanyList'
import { useDispatch, useSelector } from 'react-redux'
import { SP_INIT, SELECTED_COMPANY_OPEN, SELECTED_COMPANY_RESET } from '../../../redux/constants'
import { getCurrentUserData, setUpCompanyProfile } from '../../../redux/actions'
import {SvgGTSSuccess} from '../../svg/success'
import {SvgGTSFail} from '../../svg/fail'



export default function CompanyProfileSetUpComponent() {

    const [isAuthorisedSelected, setAuthorisedSelection] = useState(false);
    const [searchQuery, setSearchQuery] = useState('')

    const CHA = require('companies-house-api-es6');
    const cha = new CHA('d906dbc5-19f8-4447-bbdc-75cd00e838ad');

    const [apiResult, setApiResult] = useState('')
    const company = useSelector(state => state.company.selectedCompany)
    const open = useSelector(state => state.company.open)
    const set = useSelector(state => state.company.set)
    const user = useSelector(state => state.auth.currentUser)

    const dispatch = useDispatch() 

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [taxID, setTaxID] = useState('')
    const profileSetupState = useSelector(state => state.auth.sp)
    
    const checkTextInput = () => {
        // if (!company.title.trim()) {
        //     alert('Please enter your company name and select it from the list');
        //     return;
        // }
        if (!taxID.trim()) {
            alert('Please enter the company VAT/Tax number');
            return;
        }
        if (taxID.length !== 11){
            alert('Please enter your VAT prefix and 9 digit number ');
            return;
        } 
        if (taxID.slice(0,2) !== "GB"){
            alert('We currently only support GB VAT numbers, please contact us at 0203 670 0008');
            return;
        }
        if (!firstName.trim()) {
            alert('Please enter your first name');
            return;
        }
        if (!lastName.trim()) {
            alert('Please enter your last name');
            return;
        }
        if (!jobTitle.trim()) {
            alert('Please enter your job title');
            return;
        }
        if (!contactPhone.trim()) {
          alert('Please enter your phone number');
          return;
        }
        if (!contactEmail.trim()) {
            alert('Please enter your business email');
            return;
        }
        if (!isAuthorisedSelected) {
            alert('Please confirm the declaration of authority');
            return;
        
        }
        //Checked Successfully
        handleSetUpCompanyProfile();
        };
        function wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
            end = new Date().getTime();
            }
        }

        const handleSetUpCompanyProfile =() => {
            dispatch(setUpCompanyProfile(company, firstName, lastName, jobTitle, contactPhone, contactEmail, isAuthorisedSelected, taxID, user ))
            .then(() => {
                console.log('login successful')
            })
            .catch((error) => {
                console.log('login unsuccessful')
                alert(error.message)
            })
        }
    

    
console.log(apiResult)





    const handleFetch = () => {
        if(searchQuery.length >= 5 && open === true){
            cha.searchForCompanyByGenericTerm(searchQuery).then(result => {
                setApiResult(result)
            }).catch(err => {
                console.log(err);
            });
        }
    }
    if(searchQuery.length == 0 && apiResult !== ''){
        setApiResult('');
    }
    
    if (set === true){
            setSearchQuery(company.title)
            dispatch({type: SELECTED_COMPANY_RESET, selectedCompany: company})
        }
       
    
    
    
    // {company != null? console.log(company.title) :(console.log("company not selected yet"))}
    // console.log(searchQuery)
  return ( 
            <>
            {profileSetupState.loading ?
            <>
            <ContentContainerWhite>                
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '30%'}}>
                        <ActivityIndicator
                        color='#d72323'
                        size='large'
                        />
                    </View>
                    <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Loading...</Text>
                        <View style={{height: 40}}></View>
                        <Text style={styles.popupText}></Text>
                        <TouchableOpacity
                        disabled
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Please wait...</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ContentContainerWhite>
            </>
            :
            profileSetupState.error == null?
            <>
            <ContentContainerWhite>                
                <View>
                    <NavTitle>Company Details</NavTitle>
                    
                    <View style={{flexDirection: 'row', marginTop: 10}}>
                        <View style={{width: '100%', marginRight: 20,}}>
                            
                            <Text>Company Name</Text>
                            <TextInput
                            onChangeText={(text) => {handleFetch(), setSearchQuery(text), dispatch({type: SELECTED_COMPANY_OPEN})}}
                            style={[styles.textInput, {zIndex: 50} ]}
                            value={searchQuery}
                            autoComplete='none'                           
                            />
                            {open == false?
                            <></>
                            :                            
                            <FlatList
                            numColumns={1}
                            removeClippedSubviews
                            data={apiResult}
                            keyExtractor={(item) => item.title}
                            renderItem={({item}) => (<CompanyListItem item={item}/>)}
                            style={styles.dropDownFlatlist}
                            />
                            }
                        </View>
                    </View>
                </View>
            </ContentContainerWhite>  
            <ContentContainerWhite>                
                <View>
                    <NavTitle>Tax Information</NavTitle>
                    <View style={{flexDirection: 'row', marginTop: 10}}>
                        <View style={{width: '45%', marginRight: '5%'}}>
                            
                            <Text>VAT Number</Text>
                            <TextInput
                            onChangeText={(text) => setTaxID(text)}
                            style={styles.textInput}
                            placeholder='VAT or tax id (GB123456789)'
                            />
                        </View>
                    </View>
                    <NavTitle>Registered Address</NavTitle>
                    <View style={{flexDirection: 'row', marginTop: 10}}>
                        <View style={{width: '45%', marginRight: '5%'}}>
                            
                            <Text>Premises</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder={company == null? 'Enter No.': company.address.premises}
                            />
                        </View>
                        <View style={{width: '50%',}}>
                            <Text>Street</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder={company == null? 'Street name': company.address.address_line_1}
                            />
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: '50%', marginRight: '5%'}}>
                            
                            <Text>Address Line 2</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder={company == null? 'Address line 2': company.address.address_line_2 != null? company.address.address_line_2 :'N/A'}
                            />
                        </View>
                        <View style={{width: '40%',}}>
                            <Text>City</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder={company == null? 'Address line 2': company.address.locality != null? company.address.locality :'N/A'}
                            />
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: '20%', marginRight: '5%'}}>
                            
                            <Text>Postcode</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder={company == null? 'Postcode': company.address.postal_code != null? company.address.postal_code :'N/A'}
                            />
                        </View>
                        <View style={{width: '40%', marginRight: '5%'}}>
                            
                            <Text>Region</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder={company == null? 'Region': company.address.region != null? company.address.region :'N/A'}
                            
                            />
                        </View>
                        <View style={{width: '30%',}}>
                            <Text>Country</Text>
                            <TextInput
                            disabled
                            style={styles.textInput}
                            placeholder='United Kingdom'
                            />
                        </View>
                    </View>
                </View>
            </ContentContainerWhite>
            <ContentContainerWhite>                
                <View>
                    <NavTitle>Account Administrator Details</NavTitle>
                    <View style={{flexDirection: 'row', marginTop: 10}}>
                        <View style={{width: '47.5%', marginRight: '5%'}}>
                            
                            <Text>First Name</Text>
                            <TextInput
                            onChangeText={(text) => setFirstName(text)}
                            style={styles.textInput}
                            placeholder='Enter first name'
                            />
                        </View>
                        <View style={{width: '47.5%',}}>
                            <Text>Last Name</Text>
                            <TextInput
                            onChangeText={(text) => setLastName(text)}
                            style={styles.textInput}
                            placeholder='Enter last name'
                            />
                        </View>
                    </View>
                    <View style={{width: '100%', marginRight: 20}}>
                        
                        <Text>Job Title</Text>
                        <TextInput
                        onChangeText={(text) => setJobTitle(text)}
                        style={styles.textInput}
                        placeholder='Enter your Job Title'
                        />
                    </View>
                    <View style={{width: '100%', marginRight: 20}}>
                        
                        <Text>Contact Phone No.</Text>
                        <TextInput
                        onChangeText={(text) => setContactPhone(text)}
                        style={styles.textInput}
                        placeholder='Enter your Phone No.'
                        />
                    </View>
                    <View style={{width: '100%', marginRight: 20}}>
                        
                        <Text>Contact Email</Text>
                        <TextInput
                        onChangeText={(text) => setContactEmail(text)}
                        style={styles.textInput}
                        placeholder='Enter your Business Email'
                        />
                    </View>
                    {company == null? <View style={styles.checkBoxContainer}></View> : 
                    <View style={styles.checkBoxContainer}>
                        <CheckBox
                            value={isAuthorisedSelected}
                            onValueChange={() => setAuthorisedSelection(true)}
                            style={[styles.checkbox, {alignSelf: 'center', marginRight: 10}]}
                            color={'#d72323'}
                            />
                        <Text>I hereby declare that I am authorised to act on behalf of {company.title} </Text>
                    </View>
                    }
                    <TouchableOpacity
                        onPress={() => checkTextInput()}
                        style={styles.submitButton}
                        >
                        <Text style={styles.submitButtonText}>Complete</Text>
                </TouchableOpacity>
                </View>
            </ContentContainerWhite>
            </>
            :
            !profileSetupState.error ?
            <>
            <ContentContainerWhite>                
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSSuccess/>
                    </View>
                    <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Success</Text>
                        <Text style={styles.popupText}>Your profile is setup</Text>
                        <TouchableOpacity
                        onPress={() => dispatch(getCurrentUserData())}
                        style={styles.submitButton}
                        
                        >
                            <Text style={styles.submitButtonText}>Continue</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ContentContainerWhite>
            </>
            :
            <>
            <ContentContainerWhite>                
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSFail/>
                    </View>
                    <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Error</Text>
                        <Text style={styles.popupText}>There was an error processing your data</Text>
                        <TouchableOpacity
                        onPress={() => {
                            dispatch({type: SP_INIT})
                        }}
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Try again</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ContentContainerWhite>
            </>
            }
        </>
  )
}
