import { View, Text } from 'react-native'
import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native-web'
import styles from './styles'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { Feather } from '@expo/vector-icons'; 
import { createChat } from '../../../../services/chats'

const ShipmentHelpListItem = ({ item }) => {
    const navigation = useNavigation()
    const category = 'Shipment'
    const subCategory= false
    const shipmentId= item.id
    const chats = useSelector(state => state.chat.list)
    const isActive = chats.filter((item) => item.shipmentId === shipmentId && item.status === 'open').length !== 0? chats[0].id : false;
    console.log(isActive)
    const handleCreateChat = () => {
        isActive? navigation.navigate('chatSingle', { chatId: isActive }) : createChat(shipmentId, category, subCategory).then((res) => navigation.navigate('chatSingle', { chatId: res.id }));
    }
  return (
    <TouchableOpacity style={styles.container} onPress={() => handleCreateChat()}>
        <Text style={styles.shipmentNo}>{item.uid}</Text>
        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center',marginLeft: 24}}>
            <Text style={styles.statusTitle}>status:</Text>
            <Text style={styles.status}>{item.status == "TBQ"? "Pending Quotation" : item.status == "PP"? "Pending Payment" : item.status == "PC"? "Awaiting Collection" : item.status == "EN"? "Enroute" : item.status == "D"? "Delivered" : item.status == "CO"? "Complete" : item.status == "CA"? "Cancelled" : item.status == "Expired"? "Quote Expired" : "Error"}</Text>
        </View>
            <Text style={styles.status}>{!isActive? 'Create Case':'Go to active chat'}</Text>
        <Feather name='chevron-right' size={24} color='black' />

    </TouchableOpacity>
    
  )
}

export default ShipmentHelpListItem
