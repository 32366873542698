import { Pressable, View } from 'react-native';
import React from 'react'
import { Text } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons'; 

import { Container, ContentContainer, ContentContainerWhite, NavTitle, NavTitleActive, Pagewrapper } from '../../components/layout';
import { signOutHandler } from '../../redux/actions/auth';
import NavBar from '../../components/nav';
import styles from './styles';
import { useSelector } from 'react-redux';


export default function AboutScreen() {
    
    function cS(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const navigation = useNavigation()
    const user = useSelector(state => state.auth.currentUser)
    return (
    <Pagewrapper>
        <Container>
            <NavBar active={""}/>
            <ContentContainer>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Pressable
                    onPress={() => navigation.navigate("dashboard")}
                    >
                        <Feather name='chevron-left' size={24} color='black' />
                    </Pressable>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>About</Text>
                    <Pressable
                    disabled
                    style={{width: 24}}
                    >
                        
                    </Pressable>
                </View>
            </ContentContainer>
            <ContentContainerWhite>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Application</NavTitle>
                    </View>
                    <NavTitle>{user.isBusiness? "LCC Business": "LCC Easy Send"}</NavTitle>
                </View>
                <View style={styles.divider}/>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Current Version</NavTitle>
                    </View>
                    <NavTitle>2.0.0</NavTitle>
                </View>
          </ContentContainerWhite>
          <ContentContainerWhite>
            <NavTitle>Whats new in version 2.0.0</NavTitle>
            <Text style={{marginTop: 10}}>We are excited to announce the release of Version 2.0.0 with two exciting new features that will streamline our LCC Business Clients' shipment processes.{"\n"}{"\n"}Firstly, we have enabled multi-waypoint routes, which allows up to six loading or unloading stops on a shipment's route. This feature will help clients save time and optimize their logistics planning. To use multi-waypoint routes, simply select "multi drop/collections" in the shipment type tab when creating a new shipment.{"\n"}{"\n"}Secondly, we have introduced the ability to clone previous shipments or save and load shipment templates. This feature is particularly useful for clients who have repeat shipments with similar details. With just a few clicks, clients can create a template for their shipments, and the next time they create a similar shipment, they can load the template and save time on data input. To create a template, enter all the details of the shipment that you want to be saved, then press "save as new Template," give it a name, and you're ready to use it for your next shipment. We hope these new features will make our clients' shipment processes even more efficient and enjoyable.</Text>
          </ContentContainerWhite>
      </Container>
    </Pagewrapper>

    
      
    
  );
}