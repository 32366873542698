import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({


    submitButton:{
        width: '50%',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        backgroundColor: '#d72323',
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitButtonText:{
        color: '#fff',

    },
    popupTitle:{
        textAlign: 'center',
        fontSize: 32,
        fontWeight: '500',
        marginBottom: 10,
    },
    popupText:{
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 20,
        
    },

})

export default styles;