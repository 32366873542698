import { View, Text } from 'react-native';
import React from 'react';
import { NavBarWrapper, NavButton, NavButtonActive, NavTitle, ProfileButton, ProfileTitle } from '../layout';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { NAV_HOME, NAV_NEWSHIPMENT, NAV_SHIPMENTS } from '../../redux/constants';
import Avatar from '../profilePicture';

export default function ProfileButtonComponent() {
    const navState = useSelector(state => state.auth.nav)
    const navigation = useNavigation()
    const dispatch = useDispatch
    const auth = useSelector(state => state.auth)
  return (
    
    <ProfileButton
    onPress={() => navigation.navigate('profile')}
    >
        <Avatar/>
        <View>
            {auth.currentUser.isCompany? 
            <>
            <ProfileTitle>{auth.currentUser.company.companyName}</ProfileTitle>
            <View style={{ flexDirection: 'row', width: 150,overflow: "hidden" }}>
            <Text >{auth.currentUser.accountAdmin.firstName} {auth.currentUser.accountAdmin.lastName}</Text>
            </View>
            </>
            :
            <>
            <ProfileTitle>{auth.currentUser.profile.userDetails.firstName} {auth.currentUser.profile.userDetails.lastName}</ProfileTitle>
            <Text style={{fontSize: 10, }}>{auth.currentUser.email}</Text>
            </>
            }
            
        </View>
    </ProfileButton>
  );
}
