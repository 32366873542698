import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../redux/actions';

export const chatsListener = (listener) => {
    const b = query(collection(db, "supportTicket"), where("members", "array-contains", auth.currentUser.uid), orderBy('lastUpdate', 'desc'));
    onSnapshot(b, (listener))
}

export const messagesListener = (listener, chatId) => {
    const b = query(collection(db, `supportTicket/${chatId}/messages`), orderBy('creation', 'desc'));
    onSnapshot(b, (listener))
}

export const sendMessage = (chatId, message) =>{
    const docRef = doc(db, "supportTicket", chatId);
    const colRef = collection(docRef, "messages")
    addDoc(colRef, {
        creator: auth.currentUser.uid,
        message,
        creation: serverTimestamp(),
    });
    updateDoc(docRef, {
        lastUpdate: serverTimestamp(),
        lastMessage: message,
    });
}

export const createChat = (shipmentId, category, subCategory) => new Promise((resolve, reject) =>{
    const colRef = collection(db, "supportTicket");
    addDoc(colRef, {
        creator: auth.currentUser.uid,
        lastUpdate: serverTimestamp(),
        lastMessage: 'Chat created',
        shipmentId,
        category,
        subCategory,
        members: [auth.currentUser.uid],
        status: 'open'
    })
    .then(resolve)
    .catch(reject)
})
