import { View, Text } from 'react-native';
import React from 'react';
import ShipmentsListItem from './item';
import { FlatList } from 'react-native-web';
import styles from './styles';
import { ContentContainerWhite } from '../layout';

export default function ShipmentsList({shipments}) {


  var sortOrder = ['TBQ','Expired','PP','PC','EN','D','C','Cancelled',]; 
  shipments?.sort(
      function(a, b){                             
          if(a.status == b.status){                  
              return a.id.localeCompare(b.id);
          }else{                                   
              return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status); 
          }
      }
  );
  return (
    <View style={styles.container}>
      <ContentContainerWhite style={styles.listHeader}>
      
                <Text style={styles.listHeaderText}>Shipment #</Text>
                <Text style={[styles.listHeaderText, {width: '36%'}]}>Route</Text>
                <Text style={[styles.listHeaderText, {width: '22%'}]}>Collection Date</Text>
                <Text style={styles.listHeaderText1}>Status</Text>
                <View style={{width: 16}}/>
      </ContentContainerWhite>
      <FlatList
        numColumns={1}
        removeClippedSubviews
        data={shipments}
        keyExtractor={(item) => item.id}
        renderItem={({item}) => (<ShipmentsListItem item={item}/>)}
        /> 
    </View>
  );
}
