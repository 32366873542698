import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text:{
        color: 'black'
    },
    textInput:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: '15px',
        borderColor: '#f5f2f2',
        borderWidth: 1,
        marginTop: '7px'
    },
    textInputMultiline:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: '15px',
        borderColor: '#f5f2f2',
        borderWidth: 1,
        marginTop: '7px',
        height: '100px',
    },
    signUpContainer:{
        flexDirection: 'row',
        marginBottom: 15,
    },
    smallSignupButton:{

    },
    smallSignupButtonText:{

    },
    buttonText:{
        color: '#fff',
    },
    dateButton:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: '15px',
        borderColor: '#f5f2f2',
        borderWidth: 1,
        marginTop: '7px',
        width: '30%',
        marginRight: '5%',
    },
    checkBoxContainer:{
        flexDirection: 'row',
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        marginBottom: '15px',
        marginTop: '7px'  
    },
    submitButton:{
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        backgroundColor: '#d72323',
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitButtonText:{
        color: '#fff',

    },
    popupTitle:{
        textAlign: 'center',
        fontSize: 32,
        fontWeight: '500',
        marginBottom: 10,
    },
    popupText:{
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 20,
        width: '200%',
        marginLeft: '-50%'
    },
    tabNav:{
        width: '33.333%',
        paddingHorizontal: 10,
        paddingVertical: 5,
        textAlign: 'center',
        borderRadius: 15,
    },
    titleText:{
        fontWeight: 'bold',
        
    },
    infoText:{
        paddingVertical: 30,
    },
    optionalText:{
        fontSize: 12,
        color: '#ff9494',
    },
    dropDownFlatlist:{
        top: -40,
        paddingTop: 40,
        paddingBottom: 10,
        paddingHorizontal: '0.5rem',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        backgroundColor: '#f5f2f2',
    },
    dropDownFlatlistAbsolute:{
        zIndex: 1,
        position: "absolute",
        bottom: -"100%",
        marginTop: -25,
        width: "100%",
        paddingTop: 25,
        paddingBottom: 10,
        paddingHorizontal: '0.5rem',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        backgroundColor: '#f5f2f2',
    },
    warningWrapper:{
        borderWidth: 1,
        borderColor: 'red',
        borderRadius: 8,
        borderStyle: 'solid',
        marginVertical: 5,
        padding: 5,
    },
    warningText:{

    },

    containerNew: {
        flex: 1,
        marginTop: 40
      },
      addressContainer: {
        flexDirection: "column",
      },
      input: {
        height: 40,
        borderColor: "gray",
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
        marginBottom: 10,
      },
      picker: {
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '0.2rem',
        borderRadius: 15,
        borderColor: '#f5f2f2',
        borderWidth: 1,
      },
      positionButtons: {
        flexDirection: "row",
        justifyContent: "flex-end",
      },
      positionButtonText: {
        marginRight: 10,
        color: "#fff",
        backgroundColor: "#d72323",
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 10,
      },
      positionButtonDisabled: {
        opacity: 0.2
      },
      routeText:{
        fontSize: 20,
        fontWeight: "600"
      },
      addButton:{
        color: "#d72323",
        fontWeight: "600"
      },
      freightItemContainer:{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        backgroundColor: '#fff',  
        borderRadius: 12,
        marginVertical: 3,
        overflow: 'hidden',
    },
    freightItemListText:{
        fontSize: 16,
        color: '#000',
        marginRight: 5,
    },
    freightItemItemButton:{
        marginLeft: 5, 
        marginTop: 4,
        width: '5%'
    },
    optionsWrapper:{
        backgroundColor: "#fff",
        paddingVertical: 32,
        paddingHorizontal: 24,
        borderRadius: 15,
    },
    templateItem:{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 3,
        paddingHorizontal: 10,
        borderRadius: 10,
        backgroundColor: "#f5f2f2",
        marginBottom: 5
    },
    loadItems:{
        maxHeight: "30VH",
        marginTop: 10
    },
    buttonWithDropdown:{
        flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center"
    }
});

export default styles;