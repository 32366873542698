import styled from 'styled-components/native';

export const Pagewrapper = styled.View`
    background-color: #f5f2f2;
    width: 100%;
    min-height: 100VH;
    height: auto;
    padding-left: calc(100vw - 100%);
  `;
  
export const Container = styled.View`
    width: 100%;
    maxWidth: 1300px;
    align-self: center;
    padding: 20px;
    position: relative;
  `;

  export const ContentContainer = styled.View`
  width: 80%;
  maxWidth: 550px;
  align-self: center;
  margin-top: 32;
  padding-vertical: 12;
  padding-horizontal: 20;
  heigth: 400px;
  border-radius: 15;

`;
export const ContentContainerWhite = styled.View`
width: 80%;
maxWidth: 550px;
align-self: center;
margin-top: 32;
padding-vertical: 12;
padding-horizontal: 20;
heigth: 400px;
border-radius: 15;
background-color: #fff;

`;

export const NavBarWrapper = styled.View`
width: 100%;
background-color: #f5f2f2;
maxWidth: 248px;
align-self: center;
padding-vertical: 32px;
padding-horizontal: 24px;
position: absolute;
left: 0;
height: 95vh;
flex: 1;
flex-direction: column;
justify-content: space-between;
`;

export const OptionsBarWrapper = styled.View`
position: absolute;
top: 145px;
width: 100%;
height: calc(100% - 145px);
maxWidth: 248px;
align-self: center;
right: 0;
flex: 1;
justify-content: space-between;
flex-direction: column;

// width: 100%;
// top: 145px;
// background-color: red;
// maxWidth: 248px;
// align-self: center;
// position: sticky;
// height: 95vh;
// right: 0;
// flex: 1;
// flex-direction: column;
// justify-content: space-between;
`;

export const OptionsContainer = styled.View`
position: sticky;
top: 50px;
width: 100%;
// top 0;
// flex-direction: column;
// justify-content: space-between;
`;



export const NavButton = styled.TouchableOpacity`
  width: 100%;
  background-color: #f5f2f2;
  maxWidth: 200px;
  align-self: center;
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-radius: 15;
  &:hover {
    background-color: #d7bbbf;
    color: #fff;
  }

`;
export const NavButtonActive = styled.TouchableOpacity`
  width: 100%;
  background-color: #fff;
  maxWidth: 200px;
  align-self: center;
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-radius: 15;

`;

export const ProfileButton = styled.TouchableOpacity`
  width: 100%;
  background-color: #f5f2f2;
  maxWidth: 200px;
  align-self: center;
  padding-vertical: 12px;
  border-radius: 15;
  margin-bottom: 24;
  flex-direction: row;
  align-items: center;
  &:hover {
    background-color: #d7bbbf;
    color: #fff;
  }

`;

export const NavTitle = styled.Text`
    font-size: 16px;
    font-weight: 500;
`;

export const ProfileTitle = styled.Text`
    font-size: 16px;
    font-weight: 500;
    inline-size: 150px;
    overflow-wrap: break-word;
`;

export const NavTitleActive = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: #d72323;
`;

export const PageTitle = styled.Text`
font-size: 28px;
font-weight: 500;
`;



export const LoginContainer = styled.View`
  width: 100%;
  maxWidth: 1300px;
  align-self: center;
  margin-vertical: 100px;
  padding: 20px;
`;
export const ContentWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
`;
export const Box = styled.View`
  width: 50%;
  max-width: 400px;
  justify-content: center;
  padding-horizontal: 50px;
`;
export const BoxRed = styled.View`
  background-color: #fff;
  width: 50%;
  max-width: 400px;
  justify-content: center;
  padding-horizontal: 50px;
  padding-vertical: 80px;
`;
export const Title = styled.Text`
  color: #000;

  text-align: center;
  font-size: 16px;
`;
export const Header = styled.Text`
  color: #000;
  text-align: Left;
  font-size: 34px;
  font-weight: 600;
`;
export const EmailField = styled.TextInput`
  background-color: #fff;
  text-align: left;
  font-size: 16px;
  padding: 0.5rem 1rem;
  border-radius: 15;
  margin-bottom: 15px;

`;
export const Button = styled.TouchableOpacity`
  background-color: #d72323;
  text-align: center;
  font-size: 16px;
  padding: 12px;
  border-radius: 15;
  margin-vertical: 30px;

`;

export const Message = styled.View`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`

export const MessageText = styled.Text`
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`

export const MessageBuffer = styled.Text`
  display: inline-block;
  width: 74px;
  vertical-align: middle;
  background-color: #d72323;
`

export const MessageDate = styled.View`
  float: right;
  margin: -10px 0 -5px 4px;
`
export const MessageDate2 = styled.View`
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.6875rem;
  height: 15px;
  line-height: 15px;
`
export const MessageDateText = styled.Text`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.6875rem;
  height: 15px;
  line-height: 15px;
`

export const MessageContainer = styled.View`
  display: block;
  position: relative;
  flex: none;
  overflow: hidden;
  maxWidth: 65%
  padding: 6px 7px 8px 9px;
  box-sizing: border-box;
  user-select: text;
  
`