import {NAV_HOME, NAV_NEWSHIPMENT, NAV_SHIPMENTS, NS_INIT, NS_FAIL, NS_START, NS_SUCCESS, USER_STATE_CHANGE, USER_UPDATE,SETCHECKOUTID} from '../constants'
import {VERIFY_FAIL, VERIFY_SUCCESS, VERIFY_START, LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_START, REGISTER_SUCCESS, REGISTER_FAIL, SP_INIT, SP_START, SP_SUCCESS, SP_FAIL, CHECKOUT_INIT, CHECKOUT_START, CHECKOUT_SUCCESS, CHECKOUT_FAIL} from '../constants'
const initialState = {
    currentUser: null, 
    loaded: false,
    verifyEmail:{
        error: null,
        loading: false,
    },
    loginUser:{
        error:null,
        loading: false
    },
    registerUser:{
        error:null,
        loading: false,
        generating: false,
    },
    nav:{
        home: true,
        shipments: false,
        newShipment: false,
    },
    ns:{
        error: null,
        loading: false
    },
    sp:{
        error: null,
        loading: false
    },
    checkout:{
        loading: false,
        error: null,
    }
}

export const auth = (state = initialState, action, payload) => {
    switch(action.type){
        case USER_STATE_CHANGE:
            return{ ...state, currentUser: action.currentUser, loaded: action.loaded }
        case USER_UPDATE:
            return{ ...state, currentUser: action.currentUser,}
        case NAV_HOME:
            return{ ...state, nav: { ...state.nav, home: true, shipments: false, newShipment: false,}}
        case NAV_SHIPMENTS:
            return{ ...state, nav: { ...state.nav, home: false, shipments: true, newShipment: false,}}
        case NAV_NEWSHIPMENT:
            return{ ...state, nav: { ...state.nav, home: false, shipments: false, newShipment: true,}}
        case VERIFY_START:
            return{ ...state, verifyEmail: { ...state.verifyEmail, loading: true }}
        case VERIFY_SUCCESS:
            return{ ...state, verifyEmail: { ...state.verifyEmail, loading: false, error: false, }}
        case VERIFY_FAIL:
            return{ ...state, verifyEmail: { ...state.verifyEmail, loading: false, error: payload, }}
        case LOGIN_START:
            return{ ...state, loginUser: { ...state.loginUser, loading: true }}
        case LOGIN_SUCCESS:
            return{ ...state, loginUser: { ...state.loginUser, loading: false, error: false, }}
        case LOGIN_FAIL:
            return{ ...state, loginUser: { ...state.loginUser, loading: false, error: payload, }}
        case REGISTER_START:
            return{ ...state, registerUser: { ...state.registerUser, loading: true }}
        case REGISTER_SUCCESS:
            return{ ...state, registerUser: { ...state.registerUser, loading: true, error: null, generating: true }}
        case REGISTER_FAIL:
            return{ ...state, registerUser: { ...state.registerUser, loading: false, error: action.error, }}
        case NS_INIT:
            return{ ...state, ns: { ...state.ns, loading: false, error: null }}
        case NS_START:
            return{ ...state, ns: { ...state.ns, loading: true }}
        case NS_SUCCESS:
            return{ ...state, ns: { ...state.ns, loading: false, error: false, }}
        case NS_FAIL:
            return{ ...state, ns: { ...state.ns, loading: false, error: payload, }}
        case SP_INIT:
            return{ ...state, sp: { ...state.sp, loading: false, error: null }}
        case SP_START:
            return{ ...state, sp: { ...state.sp, loading: true }}
        case SP_SUCCESS:
            return{ ...state, sp: { ...state.sp, loading: false, error: false, }}
        case SP_FAIL:
            return{ ...state, sp: { ...state.sp, loading: false, error: payload, }}
        case CHECKOUT_INIT:
            return{ ...state, checkout: { ...state.checkout, loading: false, error: null }}
        case CHECKOUT_START:
            return{ ...state, checkout: { ...state.checkout, loading: true }}
        case CHECKOUT_SUCCESS:
            return{ ...state, checkout: { ...state.checkout, loading: false, error: false, }}
        case CHECKOUT_FAIL:
            return{ ...state, checkout: { ...state.checkout, loading: false, error: payload, }}            
        case SETCHECKOUTID:
            return{ ...state, checkout: { ...state.checkout, loading: false, id: payload, }}
        default : 
            return state;
    }
}