import { View, Text } from 'react-native';
import React from 'react';
import { FlatList } from 'react-native-web';
import styles from './styles';
import { ContentContainerWhite } from '../layout';
import InvoicesListItem from './item';

export default function InvoicesList({shipments}) {
  return (
    <View style={styles.container}>
      <ContentContainerWhite style={styles.listHeader}>
      
                <Text style={[styles.listHeaderText, {width: '20%'}]}>Invoice #</Text>
                <Text style={[styles.listHeaderText, {width: '20%'}]}>Total Amount</Text>
                <Text style={[styles.listHeaderText, {width: '30%'}]}>Date Due</Text>
                <Text style={[styles.listHeaderText1, {width: '30%'}]}>Status</Text>
                <View style={{width: 16}}/>
      
      </ContentContainerWhite>
      
      <FlatList
        numColumns={1}
        removeClippedSubviews
        data={shipments}
        keyExtractor={(item) => item.id}
        renderItem={({item}) => (<InvoicesListItem item={item}/>)}
        /> 
    </View>
  );
}
