import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container: {
        marginHorizontal: 12, 
        marginVertical: 6, 
        flexDirection: 'row',
    },
    bubble: {
        paddingVertical: 7, 
        paddingHorizontal: 12, 
        borderRadius: 18, 
        flexDirection: 'row', 
        alignItems:"stretch",
        flexWrap: 'wrap',
    },
    bubbleSize: {
        maxWidth: '65%', 
        flexDirection: 'column'
    },
    messageText: { 
        lineHeight: 22, 
        fontWeight: '400'
    },
    bubbleTime: {
        alignSelf: "stretch",
        flexGrow: 1, 
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    bubbleTimeText: {
        position: 'relative',
        lineHeight: 18,
        top: 2,
        fontSize: 9,
        paddingLeft: 8,
        fontWeight: '400'
    },
    input:{
        width: '80%',
        backgroundColor: 'yellow'
    },
    inputBar:{
        alignSelf: 'flex-start'
    }
});

export default styles;