import { combineReducers } from 'redux';
import {auth} from './auth';
import {shipments} from './shipments';
import {modal} from './modal';
import {company} from './company'
import {orderItems} from './items'
import { chat } from './chats';

const Reducers = combineReducers({
    auth,
    shipments,
    modal,
    company,
    orderItems,
    chat,
})

export default Reducers;