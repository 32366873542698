import React, { useEffect } from 'react'
import { View, Text } from 'react-native'
import { useDispatch, useSelector  } from 'react-redux'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { userAuthStateListener } from '../../redux/actions/auth'
import SignInScreen from '../../screens/SignIn'
import SignUpScreen from '../../screens/signUp'
import UserMGMTScreen from '../../screens/usermgmt'
import SetUpProfileScreen from '../../screens/setupProfile'

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import HomeScreens from '../home'


const firebaseConfig = {
    apiKey: "AIzaSyALQQm2QXJvN4Pt-y_dUgF0Hejv8j3o1To",
    authDomain: "gts-app-d2e95.firebaseapp.com",
    projectId: "gts-app-d2e95",
    storageBucket: "gts-app-d2e95.appspot.com",
    messagingSenderId: "773620863887",
    appId: "1:773620863887:web:c0f9bb5eff37b0210031fa"
  };



export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const Stack = createNativeStackNavigator()

export default function Route() {
    const currentUserObj = useSelector(state => state.auth)

    const dispatch = useDispatch();

    useEffect(() => {
       dispatch(userAuthStateListener());
    }, [])

    if(!currentUserObj.loaded){
        return (
            <View></View>
        )
    }

    const linking = {

        prefixes: [
          "exp://",
          "localhost:19000",
          "http://localhost:19000"
          
        ],
        config: {
            screens: {
                signin: "sign-in",
                signup: "sign-up",
                usermgmt: "usermgmt",
                setupProfile: "profile-setup",
                home: {
                    screens:{
                        dashboard: "dashboard",
                        shipments: "shipments",
                        shipment: "shipment/:id",
                        newshipment: "new-shipment",
                        invoices: "invoices",
                        profile: "profile",
                        checkout: "checkout/:id",
                        support: "support",
                        chatSingle: "support-chat",
                        newSupportCase: "new-support",
                    } 
                }
            }
        }
      };

    return (
        <NavigationContainer
            linking={linking}
        >
            <Stack.Navigator>
                {currentUserObj.currentUser == null ?
                <>
                    <Stack.Screen name='signin' component={SignInScreen} options={{headerShown: false}} />
                    <Stack.Screen name='signup' component={SignUpScreen} options={{headerShown: false}} />
                    <Stack.Screen name='usermgmt' component={UserMGMTScreen} options={{headerShown: false}} />
                </>
                    
                :
                auth.currentUser.emailVerified == false ?
                <>
                    <Stack.Screen name='usermgmt' component={UserMGMTScreen} options={{headerShown: false}} />
                </>
                :
                currentUserObj.currentUser.profileSetUpComplete == null ?
                <>
                    <Stack.Screen name='setupProfile' component={SetUpProfileScreen} options={{headerShown: false}} />
                    <Stack.Screen name='usermgmt' component={UserMGMTScreen} options={{headerShown: false}} />
                </>
                :
                <>
                    <Stack.Screen name='home' component={HomeScreens} options={{headerShown: false}} />
                    <Stack.Screen name='usermgmt' component={UserMGMTScreen} options={{headerShown: false}} />                  
                </>
                }      
            </Stack.Navigator>
        </NavigationContainer>
    )
}
