import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text:{
        color: 'black'
    },
    textInput:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: 15,
        borderColor: '#f5f2f2',
        borderWidth: 1,
    },
    signUpContainer:{
        flexDirection: 'row',
        marginBottom: 15,
    },
    smallSignupButton:{

    },
    smallSignupButtonText:{

    },
    buttonText:{
        color: '#fff',
    },
    errorMessageContainer:{
        borderRadius: 15,
        textAlign: 'center',
        fontSize: 16,
        paddingHorizontal: 12,
        position: 'absolute',
        bottom: 0
    },
    errorText:{
        color: 'red',
    },
    loadingText:{
        fontStyle: 'italic',
    },
    checkBoxContainer:{
        flexDirection: 'row',
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        marginBottom: '15px',
        marginTop: '7px'  
    },
    

});

export default styles;