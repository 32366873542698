import { View } from 'react-native';
import React, { useState } from 'react'
import styles from './styles'
import styled from 'styled-components/native';
import { SafeAreaView, Text, TextInput, TouchableOpacity } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { Button, Container, ContentContainer, ContentContainerWhite, NavBarWrapper, NavButton, NavButtonActive, NavTitle, NavTitleActive, PageTitle, Pagewrapper} from '../../components/layout';
import ProfileButtonComponent from '../../components/profileButton';
import { useDispatch, useSelector } from 'react-redux';
import ResetPasswordComponent from '../../components/usermgmt/resetPassword';
import { verifyEmail } from '../../redux/actions';
import VerifyEmailComponent from '../../components/usermgmt/verifyEmail';
import RecoverEmailComponent from '../../components/usermgmt/changeEmail';
import {SvgGTSSuccess} from '../../components/svg/success';



export default function UserMGMTScreen() {
    const dispatch = useDispatch()
    let url = window.location.href
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match
    while ((match = regex.exec(url))) {
        params[match[1]] = match[2]
        console.log(match[1], match[2])
    }
    const { mode, oobCode } = params
    const actionCode = oobCode;
    
    console.log("mode is :", mode)
    console.log("oobCode is :", actionCode)
    const handleReSend = () => {
        dispatch(verifyEmail()).then(() => {
            
        })
        .catch(() => {

        })
    }
    
    
    
    return (
        <Pagewrapper>
            <Container>
            {mode == 'resetPassword'? <ResetPasswordComponent actionCode={actionCode}/> 
            :mode == 'recoverEmail'?<RecoverEmailComponent actionCode={actionCode}/>
            :mode == 'verifyEmail'?<VerifyEmailComponent actionCode={actionCode}/>
            :
            <>
                <ContentContainerWhite>                
                    <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                            <SvgGTSSuccess/>
                        </View>
                        <View style={{padding: 20, width: '100%', alignItems: 'center'}}>
                            <Text style={styles.popupTitle}>Account Created</Text>
                            <Text style={styles.popupText}>We have sent an email with a link for you to verify your email (may be in spam folder). You may leave this page.</Text>
                            <TouchableOpacity
                            onPress={() => {
                                handleReSend()
                            }}
                            style={styles.submitButton}
                            >
                                <Text style={styles.submitButtonText}>Re-send verification email</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ContentContainerWhite>
                {/* <ContentContainerWhite>
                    <PageTitle>Verify Email</PageTitle>
                    <Text>A verification email has been sent to your email account, please verify your email </Text>    
                    <Button
                    onPress={() => handleReSend()}>
                        <Text>Re-send verification email</Text>
                    </Button>            
                </ContentContainerWhite>     */}
            </>  
            }
            </Container>
        </Pagewrapper>
    )
}

  
    
      
  


