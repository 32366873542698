import { StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-web';


const styles = StyleSheet.create({
    containerClosed:{
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: 20,
        borderWidth: 1,
        borderColor: 'black',
        paddingHorizontal: 10,
        paddingTop: 20,
        paddingBottom: 20,
        height: 200,
        width: 150,
        marginRight: 20,
        opacity: 0.5,
    },
    container: {
        position: 'relative',
        backgroundColor: 'white',
        borderRadius: 20,
        paddingHorizontal: 10,
        paddingTop: 20,
        paddingBottom: 20,
        height: 200,
        width: 150,
        marginRight: 20
        
    },
    notificationContainer:{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#d72323',
        width: 10,
        height: 10,
        borderRadius: 10,
        display: 'hidden',
    },
    lastMessageTime:{
        fontSize: 10
    },
    notificationText:{

    },

    shipmentNo:{
        fontSize: 16,
        fontWeight: 'bold'
    },
    supportTitle:{
        fontSize: 10,
        fontWeight: 'bold',
    },
    supportTopic:{
        fontSize: 12,
    },
    lastMessage:{
        borderTopColor: '#f5f2f2',
        marginTop: 10,
        paddingTop: 5,
        borderTopWidth: 1,
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    lastUpdate:{
        alignSelf: "flex-end",
        fontSize: 10
    },
});

export default styles;