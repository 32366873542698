import { ImageBackground, View} from 'react-native';
import React, { useState } from 'react'
import { ActivityIndicator, Text, TextInput, TouchableOpacity } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';

import bg001 from '../../../assets/bg-001.jpg'
import lcc01 from '../../../assets/LCC-Logo-sa.png'
import lcc02 from '../../../assets/LCC-Logo-sa-1200.png'

import styles from './styles'
import { login, resetPassword } from '../../redux/actions/auth';
import { Box, BoxRed, Button, ContentWrapper, Header, LoginContainer, Pagewrapper } from '../../components/layout';
import { SvgLogoWhite } from '../../components/svg/logoWhite';



export default function SignInScreen() {

const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [moduleState, setModuleState] = useState(0)
const [rPLoading, setRPLoading] = useState(false)
const [show, setShow] = useState(false)
const [success, setSuccess] = useState(false)

const navigation = useNavigation()
const dispatch = useDispatch()
const loginState = useSelector(state => state.auth.loginUser)

const handleLogin =() => {
    dispatch(login(email, password)).then(() => {
        console.log('login successful')
    })
    .catch((error) => {
        console.log('login unsuccessful')
        alert(error.message)
    })
}
const handleResetPassword =() =>{
  setRPLoading(true)
  resetPassword(email)
  .then(() => {
    setRPLoading(false)
    setSuccess(true)
  })
  .catch((e) => {
    const errorCode = e.code;
    const errorMessage = e.message;
    setRPLoading(false)
    alert(errorMessage, errorCode)
  })
}
  return (
    <Pagewrapper>
      <ImageBackground
        resizeMode="cover"
        style={{width: '100VW', height: '100VH'}}
        source={bg001}
      >
      <SvgLogoWhite
      style={{position: 'absolute', width: 300, left: 0, bottom: 20}}
      />
      <LoginContainer>
        <ContentWrapper>
          <BoxRed >
            {moduleState == 0?
            <>
              <Header>Log in to LCC </Header>
              <View style={styles.signUpContainer}>
                <Text style={styles.noAccountText}>Don't have an account? </Text>
                <TouchableOpacity 
                  style={styles.smallSignupButton}
                  onPress={() => navigation.navigate("signup")}
                >
                  <Text style={styles.smallSignupButtonText}>Sign up</Text>
                </TouchableOpacity>
              </View>
              
              <TextInput
                value={email}
                onChangeText={(text) => setEmail(text)}
                style={styles.textInput}
                placeholder='Email'
                autoCompleteType='email'
              />
              <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                <TextInput
                  onChangeText={(text) => setPassword(text)}
                  style={[styles.textInput, {width: '100%'}]}
                  placeholder='Password'
                  secureTextEntry={!show}
                />
                <TouchableOpacity onPress={() => setShow(!show)}>
                    <Feather name={show? 'eye':'eye-off'} size={20} color='black' style={{marginBottom: 15,marginLeft: -34,paddingVertical: 17}} />
                </TouchableOpacity>
              </View>
              
              <TouchableOpacity
                disabled={loginState.loading}
                onPress={() => setModuleState(1)}>
                <Text style={{color: '#d72323'}} >Forgot password</Text>
              </TouchableOpacity>
              <Button
                disabled={loginState.loading}
                onPress={() => handleLogin()}>
                <Text style={styles.buttonText} >Login</Text>
              </Button>
            </>
            :
            <>
              <TouchableOpacity onPress={() => setModuleState(0)}>
                <Feather name='chevron-left' size={30} color='black' />
              </TouchableOpacity>
              <Header>Reset Password</Header>
              {success?
                <>
                <Text style={[styles.noAccountText,{paddingTop: 20}]}>A reset password email has been sent to your inbox. Click the link in the email to continue with resetting your password.</Text>
                </>
                :
                <>
                <View style={styles.signUpContainer}>
                <Text style={styles.noAccountText}>Enter your email address</Text>
                </View>
                <TextInput
                  value={email}
                  onChangeText={(text) => setEmail(text)}
                  style={styles.textInput}
                  placeholder='Email'
                  autoCompleteType='email'
                />
                <Button
                  disabled={loginState.loading}
                  onPress={() => handleResetPassword()}>
                    {rPLoading?
                    <ActivityIndicator color={'#fff'}/>
                    :
                    <Text style={styles.buttonText} >Send</Text>
                    }  
                </Button>
                </>
              }
              
            </>
            }
          </BoxRed>
        <Box>
        
        </Box>
        </ContentWrapper>
      </LoginContainer>
      </ImageBackground>
    </Pagewrapper>

    
      
    
  );
}

