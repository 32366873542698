import { View, Text, TouchableOpcatiy } from 'react-native'
import React, { useState , useEffect} from 'react'
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux'
import { ActivityIndicator, TextInput, TouchableOpacity } from 'react-native-web'

import { auth, handleResetPassword1, handleResetPassword2, login } from '../../../redux/actions'
import { Button, ContentContainer, ContentContainerWhite, PageTitle } from '../../layout'
import { Feather } from '@expo/vector-icons';

import styles from './styles'

export default function ResetPasswordComponent({actionCode}) {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const [newPassword, setNewPassword] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)

    
    function verifyActionCode(){
        dispatch(handleResetPassword1(auth, actionCode))
        .then((email) => {
           setEmail(email)
           console.log('received email:', email)
           setLoading(false)
        })
        .catch((error) => {
            console.log('register unsuccessful', error)
            setError(error)
            setLoading(false)
            
        })
    }
    
    useEffect(() => {
        verifyActionCode()
    }, [])
    
    const setNewCredentials = () => {
        console.log('dispatched:', email)
        dispatch(handleResetPassword2(auth, actionCode, newPassword)) 
            .then((resp) => {
                console.log('response:',resp)
                dispatch(login(email, newPassword)).then(() => {
                console.log('login successful')
            })
            .catch((error) => {
                console.log('login unsuccessful')
                alert(error.message)
            })
        })
    }
  



  return (
    <View>
        <ContentContainer>
            <PageTitle>Reset Password</PageTitle>
        </ContentContainer>
        <ContentContainerWhite>
            {loading? 
            <ActivityIndicator/>
            :
            <>
            {!error? 
            <>  
                <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                    <TextInput
                    onChangeText={(text) => setNewPassword(text)}
                    style={styles.textInput}
                    placeholder='New Password'
                    underlineColorAndroid="transparent"
                    secureTextEntry={!show}
                    />
                    <TouchableOpacity onPress={() => setShow(!show)}>
                        <Feather name={show? 'eye':'eye-off'} size={20} color='black' style={{marginBottom: 15,marginLeft: -34,paddingVertical: 17}} />
                    </TouchableOpacity>
                </View>
                
                <Button
                disabled={newPassword.length == 0}
                onPress={() => setNewCredentials()}
                >
                    <Text style={{color: '#fff'}}>Save Password</Text>
                </Button>
            </>
                :
            <>
                <Text>{error.code == 'auth/expired-action-code'? 'This link has expired':'Unknown error'}</Text>
                <Button
                onPress={() => navigation.navigate("signin")}
                >
                    <Text style={{color: '#fff'}}>Back</Text>
                </Button>
            </>
            }
            </>
            }
            
        </ContentContainerWhite>
    </View>
  )
}