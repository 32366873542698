import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

export const SvgGTSFail = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <G transform="matrix(.32922 0 0 .32922 -8.248 -4.981)">
      <Circle
        style={{
          fill: "none",
          fillOpacity: 1,
          stroke: "#d72323",
          strokeWidth: 5.29167,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx={105.42}
        cy={95.498}
        r={77.721}
      />
      <Path
        style={{
          color: "#000",
          fill: "#df2121",
          strokeWidth: 1.85208,
          InkscapeStroke: "none",
        }}
        d="m74.621 64.7 61.599 61.598M136.22 64.7 74.62 126.298"
      />
      <Path
        style={{
          fill: "none",
          stroke: "#d72323",
          strokeWidth: 5.29167,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M74.621 126.298 136.22 64.7m0 61.598L74.62 64.7"
      />
    </G>
  </Svg>
)

export const SvgGTSFailWhite = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <G transform="matrix(.32922 0 0 .32922 -8.248 -4.981)">
      <Circle
        style={{
          fill: "none",
          fillOpacity: 1,
          stroke: "#d72323",
          strokeWidth: 5.29167,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx={105.42}
        cy={95.498}
        r={77.721}
      />
      <Path
        style={{
          color: "#000",
          fill: "#fff",
          strokeWidth: 1.85208,
          InkscapeStroke: "none",
        }}
        d="m74.621 64.7 61.599 61.598M136.22 64.7 74.62 126.298"
      />
      <Path
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 5.29167,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M74.621 126.298 136.22 64.7m0 61.598L74.62 64.7"
      />
    </G>
  </Svg>
)
