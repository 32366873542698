import {CURRENT_ORDER_ITEMS, CLOSE_INPUT, OPEN_INPUT, REFRESH_INIT, REFRESH} from '../constants'

const initialState = {
    currentOrderItems: [], 
    itemBox:{
        open: false
    },
    refresh:{
        refreshed: false
    }
}

export const orderItems = (state = initialState, action) => {
    switch(action.type){
        case CURRENT_ORDER_ITEMS:
            return{
                ...state, 
                currentOrderItems: action.currentOrderItems,
            }
        case OPEN_INPUT:
            return{...state, itemBox: { ...state.itemBox, open: true,}}
        case CLOSE_INPUT:
            return{...state, itemBox: { ...state.itemBox, open: false,}}
        case REFRESH:
            return{...state, refresh: { ...state.refresh, refreshed: true,}}
        case REFRESH_INIT:
            return{...state, refresh: { ...state.refresh, refreshed: false,}}
        default : 
            return state;
    }
}