import { View } from 'react-native';
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native';

import { Container, ContentContainer, ContentContainerWhite, NavBarWrapper, NavButton, NavButtonActive, NavTitle, NavTitleActive, PageTitle, Pagewrapper, ProfileButton } from '../../components/layout';
import ProfileButtonComponent from '../../components/profileButton';
import { useSelector } from 'react-redux';
import ShipmentsList from '../../components/shipmentsList';
import NavBar from '../../components/nav';



export default function ShipmentsScreen() {
    const user = useSelector(state => state.auth.currentUser)
    const navigation = useNavigation()
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)
    console.log(currentUserShipments)

  return (
    <Pagewrapper>
        <Container>
            <NavBar active={"shipments"}/>
            <ContentContainer>
                <PageTitle>Shipments</PageTitle>
            </ContentContainer>
            <ContentContainer>
                <View>
                    <ShipmentsList shipments={currentUserShipments}/>   
                </View>
            </ContentContainer>
         
        </Container>
    </Pagewrapper>

    
      
    
  );
}