import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flex: 1,
        paddingTop: 24,
        backgroundColor: 'white',
    },
    divider:{
        height: 1, 
        width: "100%", 
        backgroundColor: "#f5f2f2"
    },
    itemContainer:{
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        paddingVertical: 10,
    },
    symbolWrapper:{
        flexDirection: 'row', 
        alignItems: 'center'
    }

    

});

export default styles;