import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flex: 1,
        
    },
    messagesWrapper:{
        flex: 1,
        flexDirection: 'column',
    },
    inputBar:{
        flexDirection: 'row',
        alignContent: 'space-between',
        marginTop: 12,
        marginHorizontal: 12,
    },
    input:{
        paddingHorizontal: 5,
        paddingVertical: 10,
        borderRadius: 10,
        marginRight: 10,
        flex: 1
    },
    sendButton:{
        borderRadius: 10,
        backgroundColor: 'gray',
        color: 'white',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    messageContainer: {
        marginHorizontal: 12, 
        marginVertical: 6, 
        flexDirection: 'row',
    },
    bubble: {
        paddingVertical: 7, 
        paddingHorizontal: 12, 
        borderRadius: 18, 
        flexDirection: 'row', 
        alignItems:"stretch",
        flexWrap: 'wrap',
    },
    bubbleSize: {
        maxWidth: '65%', 
        flexDirection: 'column'
    },
    messageText: { 
        lineHeight: 22, 
        fontWeight: '400'
    },
    bubbleTime: {
        alignSelf: "stretch",
        flexGrow: 1, 
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    bubbleTimeText: {
        position: 'relative',
        lineHeight: 18,
        top: 2,
        fontSize: 9,
        paddingLeft: 8,
        fontWeight: '400'
    },
});

export default styles;