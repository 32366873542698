import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    profileImage:{
        width: 36,
        height: 36,
        borderRadius: 18,
        marginRight: 10,
        backgroundColor: 'white',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageLetter:{
        textTransform: 'capitalize',
        fontSize: 18,
    }
    

});

export default styles;