import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flex: 1,
        paddingTop: 24,
        backgroundColor: 'white',
    },
    topPrice:{
        fontSize: 36,
        fontWeight: 600,
        color: 'white'
    },
    boldTitle:{
        fontSeize: 14,
        fontWeight: 600,
        color: 'white',
        marginBottom: 16,
    },
    description:{
        
        fontSize: 11,
        fontWeight: 500,
        color: '#e5b1ab',
        marginBottom: 16,
    },
    offwhite:{
        fontSeize: 14,
        fontWeight: 500,
        color: '#e5b1ab',
        marginBottom: 16,
    },
    rowWithLine:{
        marginTop: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: 'hsla(0,0%,100%,.1)'
    },
    row:{
        marginTop: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    secondaryTitle:{
        fontSize: 16,
        fontWeight: 500,
        color: '#e5b1ab',
        
    },
    messageContainer:{
        paddingVertical: 40,
        alignItems: 'center',
        justifyContent: 'center',
    },
    message:{
        color: '#fff',
        fontSize: 16,
        marginTop: 15,

    }


});

export default styles;