import { ActivityIndicator, View } from 'react-native';
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native';

import { Container, ContentContainer, ContentContainerWhite, NavBarWrapper, NavButton, NavButtonActive, NavTitle, NavTitleActive, PageTitle, Pagewrapper, ProfileButton } from '../../components/layout';
import ProfileButtonComponent from '../../components/profileButton';
import { useSelector } from 'react-redux';
import ShipmentsList from '../../components/shipmentsList';
import InvoicesList from '../../components/invoicesList';
import NavBar from '../../components/nav';



export default function InvoicesScreen() {

  const navigation = useNavigation()
  const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)
  var invoices = null
    console.log(currentUserShipments)
    {currentUserShipments !== null?
        invoices = currentUserShipments.filter((shipment) => shipment.status == "D")
        :
        invoices = null
    }
    console.log(invoices)
  return (
    <Pagewrapper>
        <Container>
            <NavBar active={"invoices"}/>
            <ContentContainer>
                <PageTitle>Invoices</PageTitle>
            </ContentContainer>
            <ContentContainer>
                {!currentUserShipments?
                <View>
                    <ActivityIndicator 
                    size={'large'}
                    />
                </View>
                :
                <View>
                    <InvoicesList shipments={invoices}/>   
                </View>
                }
            </ContentContainer>
         
        </Container>
    </Pagewrapper>

    
      
    
  );
}