import React from 'react'
import { View, Text, Image } from 'react-native'

import styles from './styles'
import { Feather } from '@expo/vector-icons'; 
import { Pressable } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { REFRESH } from '../../redux/constants';


export default function FreightListItem({ item, index }) {
    const dispatch = useDispatch()
    const orderItems = useSelector(state => state.orderItems.currentOrderItems)
    const handleDeleteItem = () =>{
        dispatch({type: REFRESH})
        orderItems.splice(index, 1)        
    }
    return (
            <View style={[styles.container, {flexDirection: 'row'}]}> 
                <Text style={[styles.listText,{width: '9%'}]}>x{item.amount}</Text>
                <Text style={[styles.listText,{width: '20%'}]}>{item.name}</Text>
                <Text style={[styles.listText,{width: '30%'}]}>{item.description}</Text>
                <Text style={[styles.listText,{width: '9%'}]}>{item.length}</Text>
                <Text style={[styles.listText,{width: '9%'}]}>{item.width}</Text>
                <Text style={[styles.listText,{width: '9%'}]}>{item.height}</Text>
                <Text style={[styles.listText,{width: '9%'}]}>{item.weight}</Text>
                <Pressable
                style={styles.itemButton}
                onPress={() => handleDeleteItem()}>
                    <Feather name="trash-2" size={16} color="#757575" />
                </Pressable>
            </View>        
    )
}
