import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    valueTitle:{
        fontSize: 40,
        fontWeight: 'bold',
    },
    chartItem:{
        height: 12,
        width: 12,
        borderRadius: 6,
        marginRight: 7
    },
    whiteBoard:{
        width: '100%',
        height: "auto",
        alignSelf: "center",
        marginTop: 32,
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderRadius: 15,
        flex: 1,
        backgroundColor: "#fff"
    },
    

});

export default styles;