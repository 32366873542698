import { initializeApp } from "firebase/app";
import { sendPasswordResetEmail, confirmPasswordReset, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendEmailVerification, signInWithEmailAndPassword, signOut, verifyPasswordResetCode } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, orderBy, query, where, onSnapshot, serverTimestamp, updateDoc, arrayUnion } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
const Stripe = require("stripe")

import { loadStripe } from '@stripe/stripe-js'
// import * as pdf from 'html-pdf'

const firebaseConfig = {
    apiKey: "AIzaSyALQQm2QXJvN4Pt-y_dUgF0Hejv8j3o1To",
    authDomain: "gts-app-d2e95.firebaseapp.com",
    projectId: "gts-app-d2e95",
    storageBucket: "gts-app-d2e95.appspot.com",
    messagingSenderId: "773620863887",
    appId: "1:773620863887:web:c0f9bb5eff37b0210031fa"
  };



export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
const functions = getFunctions(app);



import {USER_STATE_CHANGE, VERIFY_START, VERIFY_SUCCESS, VERIFY_FAIL , LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_START, REGISTER_SUCCESS, REGISTER_FAIL, NS_SUCCESS, NS_FAIL, NS_START, CURRENT_USER_SHIPMENTS_UPDATE, SP_FAIL, SP_START, SP_SUCCESS, CURRENT_ORDER_ITEMS, CHECKOUT_SUCCESS, CHECKOUT_START, CHECKOUT_FAIL} from '../constants'

import { useDispatch, useSelector } from "react-redux";



export const userAuthStateListener = () => dispatch => {
    onAuthStateChanged(auth, (user) => {
        console.log('logged in user =', user)
        if(user){
            
            dispatch(getCurrentUserData())
            dispatch(getShipmentByUser(auth.currentUser.uid))
            
        }else{
            dispatch({type: USER_STATE_CHANGE, currentUser: null, loaded: true})
        }
    })
}

/// NEW TEST
export const handleDeleteItem2 = (index) => dispatch =>{

    const orderItems = useSelector(state => state.orderItems.currentOrderItems)
    
    const handleDeleteItem = () =>{
        const oldArray = orderItems.splice(index, 1)
        const newArray = [...oldArray]
        dispatch({type: CURRENT_ORDER_ITEMS , currentOrderItems: newArray})
    }
    handleDeleteItem()
}
export const getCurrentUserData = () => async dispatch =>{
    const docRef = doc(db, 'users', auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return dispatch({
            type: USER_STATE_CHANGE,
            currentUser: docSnap.data(),
            loaded: true,
        })
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    
}


export const login = (email, password) => (dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LOGIN_START})
    signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            resolve()
            dispatch({type: LOGIN_SUCCESS})
        })
        .catch((error) =>{
            reject(error)
            dispatch({type: LOGIN_FAIL, payload: error})
        })
})

export const register = (email, password, isCompany) => dispatch => new Promise((resolve, reject) => {
    dispatch({type: REGISTER_START})
    createUserWithEmailAndPassword(auth, email, password)
        .then(async() => {
            
            dispatch({type: REGISTER_SUCCESS})
            const docRef = doc(db, "users", auth.currentUser.uid);
            // var actionCodeSettings = {
            //     url: 'https://www.example.com/?email=' + firebase.auth().currentUser.email,
            //     handleCodeInApp: true,
            //     // When multiple custom dynamic link domains are defined, specify which
            //     // one to use.
            //     dynamicLinkDomain: 'example.page.link'
            //   };
            console.log(auth.currentUser.uid)
            await sendEmailVerification(auth.currentUser)
            setTimeout(async function(){
              await updateDoc(docRef, {  
                isCompany: isCompany,
              })
              dispatch(getCurrentUserData())
            }, 7000);
            
            resolve()                
        })
        .catch((error) =>{
          console.log('2 not work')
            dispatch({type: REGISTER_FAIL, error: error.message}),
            reject(error)
            
        })
})

export const signOutHandler = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
}

//Verify email actionTypes

export const verifyEmail = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch({type: VERIFY_START})
  sendEmailVerification(auth.currentUser)
  .then(() => {
      resolve()
      dispatch({type: VERIFY_SUCCESS})
  })
  .catch((error) =>{
      reject(error)
      dispatch({type: VERIFY_FAIL, payload: error.message})
  })
})

export const resetPassword = (email) => new Promise((resolve, reject) => {
  sendPasswordResetEmail(auth, email)
  .then(() => {
    resolve()
  })
  .catch((error) => {
    reject(error)
  });
});

export const setAccountType = ( resolve, reject, isCompany) => {

  const docRef = doc(db, "users", auth.currentUser.uid);
  const waitForElementToDisplay = (selector, item, callback, checkFrequencyInMs, timeoutInMs) => {

    
    async function upload(){
      await updateDoc(docRef, {
        isCompany: item,
      })
      console.log('weGotHere22')
    }

    
    var startTimeInMs = Date.now();
    (async function loopSearch() {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        callback();
        await upload()
        return resolve()

      }
      else {
        setTimeout(function () {
          if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
            reject(err='timeOut');
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }

  waitForElementToDisplay(docRef, isCompany ,function(){return},1000,21000)
}


export const setUpCompanyProfile = (company, firstName, lastName, jobTitle, contactPhone, contactEmail, isAuthorisedSelected, taxID, user ) => (dispatch) => new Promise(async (resolve, reject) => {
  dispatch({type: SP_START})
  const docRef = doc(db, "users", auth.currentUser.uid);
  const updateStripeTestCheckout = httpsCallable(functions, 'updateStripeCustomer')
  const stripeID = user.stripeID
  const isCompany = true
  const data = {company, taxID, stripeID, isCompany}
  await updateStripeTestCheckout(data).then(async() =>{
    console.log('setup stripe profile')
    await updateDoc(docRef, {
      profileSetUpComplete: true,
      name: company.title,
      company:{
        taxNumber: taxID,
        companyName: company.title,
        companyNumber: company.company_number,
        registeredAddress: company.address,
        companyDescription: company.description
      },
      accountAdmin:{
        firstName: firstName,
        lastName: lastName,
        jobTitle: jobTitle,
        contactPhone: contactPhone,
        contactEmail: contactEmail,
        isAuthorisedSelected: isAuthorisedSelected,
        setUpDate: serverTimestamp(),
      },
      
    }).then(() => {
      dispatch({type: SP_SUCCESS})
    }).catch((e) => {
      console.error("Error updating document: ", e);
      dispatch({type: SP_FAIL})
    })
  })
  
})

export const setUpIndividualProfile = (firstName, lastName, contactPhone, premises, street, addressLine2, region, postcode, locality, user ) => (dispatch) => new Promise(async (resolve, reject) => {
  dispatch({type: SP_START})
  const docRef = doc(db, "users", auth.currentUser.uid);
  const updateStripeTestCheckout = httpsCallable(functions, 'updateStripeCustomer')
  const stripeID = user.stripeID
  const isCompany = false
  const address = {
    street: street,
    postcode: postcode,
    locality: locality,
    name: `${firstName} ${lastName}`
  }
  const data = {address, stripeID, isCompany}
  await updateStripeTestCheckout(data).then(async() =>{
    await updateDoc(docRef, {
      profileSetUpComplete: true,
      profile: {
        setUpDate: serverTimestamp(),
        userDetails:{
          firstName: firstName,
          lastName: lastName,
          contactPhone: contactPhone,
          address:{
            premises: premises,
            street: street,
            addressLine2: addressLine2,
            region: region,
            postcode: postcode,
            locality: locality,
          },
        },
      },  
    }).then(() => {
      dispatch({type: SP_SUCCESS})
    }).catch((e) => {
      console.error("Error updating document: ", e);
      dispatch({type: SP_FAIL})
    })
  })
})


////////



export const handleResetPassword1 = (auth, actionCode) => dispatch => new Promise((resolve, reject) => {
  // Localize the UI to the selected language as determined by the lang
  // parameter.
  console.log('auth:',auth)
  console.log('ac:',actionCode)
  // Verify the password reset code is valid.
  verifyPasswordResetCode(auth, actionCode)
    .then((email) => {
    resolve(email)
    }).catch((error) => {
    reject(error)
    })
})


export const handleResetPassword2 = (auth, actionCode, newPassword) => dispatch => new Promise((resolve, reject) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.


    confirmPasswordReset(auth, actionCode, newPassword)
    .then((resp) => {
        resolve(resp)
      // Password reset has been confirmed and new password updated.

      // TODO: Display a link back to the app, or sign-in the user directly
      // if the page belongs to the same domain as the app:
      // auth.signInWithEmailAndPassword(accountEmail, newPassword);

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch((error) => {
        reject(error)
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
    });
})








export const addRequestQuoteToDB = (shipmentDetails, cargoDetails, user, type) => (dispatch) => new Promise(async (resolve, reject) => {
  dispatch({type: NS_START})
  
  function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
  }
  makeid()
  try {
    const docRef = await addDoc(collection(db, "shipments"), {
      uid: makeid(),
      type: type,
      creator: auth.currentUser.uid,
      creatorName: user.isCompany? user.company.companyName:`${user.profile.userDetails.firstName} ${user.profile.userDetails.lastName}`,
      creation: serverTimestamp(),
      status: 'TBQ',
      invoiceable: user.isBusiness,
      shipmentDetails: shipmentDetails,
      cargoDetails: cargoDetails,
      quoteInformation:{
        currency:"",
        price:"",
        tax:""
      },
      routeTime:{
        cat: null,
        cdt: null,
        dat: null,
        dct: null,
      },
      paymentInformation:{
        paymentStatus:"unpaid",
      }
    });
    console.log("Document written with ID: ", docRef.id);
    dispatch({type: NS_SUCCESS}),
    dispatch({type: CURRENT_ORDER_ITEMS, currentOrderItems: [] })
    resolve();
  } catch (e) {
    console.error("Error adding document: ", e);
    dispatch({type: NS_FAIL})
  }
})

export const getShipmentByUser = (uid = auth.currentUser.uid,) => dispatch => new Promise(async(resolve, reject) => {

    const q = query(collection(db, "shipments"), where("creator", "==", uid), orderBy("creation", "desc"));
    onSnapshot(q, (querySnapshot) => {
        let shipments = querySnapshot.docs.map(doc => {
            const data = doc.data()
            const id = doc.id
            return {id, ...data}
      });
      dispatch({type: CURRENT_USER_SHIPMENTS_UPDATE, currentUserShipments: shipments})
    });

})

export const issueCheckoutSession = async(orderId, usid, isCompany) => {
  const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout')
  const stripe = await loadStripe('pk_live_51KO3amGkpgA8sjrg8LEk1Z3OyALuhF5u0Cdp3RrxvS2BtygdP4t9wnOEDXtdn4wAvooEBfge1eFFztwGPhpvGnQ000rN4eI8Mx')
  var data={
    id: orderId,
    usid: usid,
    isCompany: isCompany,
  }
  createStripeCheckout(data)
  .then(response =>{
    if(response.data.code){
    }
    else{
      const sessionId = response.data.id
      stripe.redirectToCheckout({ sessionId: sessionId})
    }    
  })
}

// export const issueTestCheckoutSession = async(orderId, usid) => {
//   const createStripeTestCheckout = httpsCallable(functions, 'createTestStripeCheckout')
//   const testStripe = await loadStripe('pk_test_51KO3amGkpgA8sjrgcLg7DnKWMlP6h2emUweim06qKWrWiAyRzYH3gytI1mPRGupgY7MMLS8cOI6O6uMYxEVsKWxe00g9xfqfUx')
//   var data={
//     id: orderId,
//     usid: usid,
//   }
//   createStripeTestCheckout(data)
//     .then(response =>{
//       const sessionId = response.data.id
//       testStripe.redirectToCheckout({ sessionId: sessionId})
//     })
// }

export const issueInvoiceSession = (orderId, usid, isCompany) => dispatch => new Promise(async(resolve, reject) => {

  dispatch({type: CHECKOUT_START})
  const createInvoiceCheckout = httpsCallable(functions, 'createInvoicing')
  auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
    var data={
      id: orderId,
      usid: usid,
      idToken: idToken,
      isCompany: isCompany,
    }
    console.log(data)
    createInvoiceCheckout(data)
    .then(response =>{
      console.log(response.data)
      if(response.data.code === "200"){
        dispatch({type: CHECKOUT_SUCCESS})
        resolve()
      } else if(response.data.code === "418"){
        reject(response.data.message)
      } else if(response.data.code === "401"){
        reject(response.data.message)
      }
    })
  }).catch(function(error) {
    console.log(error)
  });
  
})

export const getPDF = (data) => new Promise(async(resolve, reject) => {
  const issuePDF = httpsCallable(functions, 'getpdf')
  issuePDF(data).then(response => {
    console.log(response)
    // console.log(response)
    // console.log(`data:application/pdf;base64,${response.data}`)
    // const file = new Blob([JSON.stringify(response.data)], {type: 'application/pdf'});
    

    // const encodedUri = window.URL.createObjectURL(file)
    // const link = document.createElement("a")
    // link.setAttribute("href", encodedUri)
    // link.setAttribute("download", 'test')
    resolve()
    const b64toBlob = (b64Data, contentType, sliceSize=512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }
  
    const file = b64toBlob(response.data, 'application/pdf');
    const getFile = file && window.URL.createObjectURL(file);
    window.open(getFile, "_blank");
  })


})

export const downloadPDF = (data) => new Promise(async(resolve, reject) => {
  const issuePDF = httpsCallable(functions, 'getpdf')
  issuePDF(data).then(response => {
  resolve()
  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `LCC_Invoice-${data.ShipmentDetails.id}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  downloadPDF(response.data)  
  })
})


export const getAddressPrediction = (data) => new Promise(async(resolve, reject) => {
  const getAddress = httpsCallable(functions, 'addressValidation')
  getAddress(data)
  .then(response => {
  resolve(response)
  })
  .catch(error =>{
  reject(error)
  })
})


