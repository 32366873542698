import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text:{
        color: 'black'
    },
    textInput:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: '15px',
        borderColor: '#f5f2f2',
        borderWidth: 1,
        marginTop: '7px'
    },
    textInputMultiline:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: '15px',
        borderColor: '#f5f2f2',
        borderWidth: 1,
        marginTop: '7px',
        height: '100px',
    },
    signUpContainer:{
        flexDirection: 'row',
        marginBottom: 15,
    },
    smallSignupButton:{

    },
    smallSignupButtonText:{

    },
    buttonText:{
        color: '#fff',
    },
    dateButton:{
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        borderRadius: 15,
        marginBottom: '15px',
        borderColor: '#f5f2f2',
        borderWidth: 1,
        marginTop: '7px'  
    },
    checkBoxContainer:{
        flexDirection: 'row',
        color: 'black',
        outline: 'none',
        backgroundColor: '#fff',
        textAlign: 'left',
        fontSize: '16px',
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        marginBottom: '15px',
        marginTop: '7px'  
    },
    submitButton:{
        paddingHorizontal: '0.5rem',
        paddingVertical: '1rem',
        backgroundColor: '#d72323',
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitButtonText:{
        color: '#fff',

    },
    popupTitle:{
        textAlign: 'center',
        fontSize: 32,
        fontWeight: '500',
        marginBottom: 10,
    },
    popupText:{
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 20,
        width: '200%',
        marginLeft: '-50%'
    },
    dropDownFlatlist:{
        top: -40,
        paddingTop: 40,
        paddingBottom: 10,
        paddingHorizontal: '0.5rem',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        backgroundColor: '#f5f2f2',
    }

    

});

export default styles;