import { View, Text } from 'react-native'
import React from 'react'
import { FlatList } from 'react-native-web';
import { Container, NavBarWrapper, NavTitle, Pagewrapper, NavButton, NavButtonActive, NavTitleActive, PageTitle, ContentContainerWhite, ContentContainer } from '../../../components/layout';
import ProfileButtonComponent from '../../../components/profileButton';
import ChatListItem from './item';
import { useSelector } from 'react-redux';
import { ActivityIndicator } from 'react-native-web';
import styles from './styles';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-web';
import { Feather } from '@expo/vector-icons'; 
import NavBar from '../../../components/nav';

export default function SupportScreen() {
    const user = useSelector(state => state.auth.currentUser)
    const chats = useSelector(state => state.chat.list)
    const navigation = useNavigation()
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)
    const renderItem = ({item}) => {
        return(<ChatListItem chat={item}/>)
    }
    return (
        <Pagewrapper>
            <Container>
                <NavBar active={"support"}/>
                <ContentContainer>
                    <PageTitle>Support</PageTitle>
    
                </ContentContainer>
                
                <ContentContainer>
                    {/* <NavTitle>Support chat feature currently in development.</NavTitle> 
                    <NavTitle style={{marginTop: 30}}>For support please call +44 7747 041660</NavTitle> */}
                    <View style={{marginBottom: 40}}>
                        <TouchableOpacity 
                            style={styles.newSupportCaseButton} 
                            onPress={() => navigation.navigate('newSupportCase')}
                            >
                            <NavTitle>New support case</NavTitle>
                            <Feather name='chevron-right' size={24} color='black' />
                        </TouchableOpacity>
                    </View>
                    {!currentUserShipments?
                    <View>
                        <ActivityIndicator 
                        size={'large'}
                        />
                    </View>
                    :
                    <View>
                        <View style={{marginBottom: 20}}>
                            <NavTitle>Open cases</NavTitle>
                        </View>
                        <FlatList 
                            
                            style={styles.container}
                            data={chats.filter((chat) => chat.status == "open")}
                            alwaysBounceHorizontal={true}
                            horizontal={true}
                            removeClippedSubviews
                            renderItem={renderItem}
                            keyExtractor={(item) => item.id}
                        />    
                        <View style={{marginVertical: 20}}>
                            <NavTitle>Closed cases</NavTitle>
                        </View>
                        <FlatList 
                            
                            style={styles.container}
                            data={chats.filter((chat) => chat.status == "closed")}
                            alwaysBounceHorizontal={true}
                            horizontal={true}
                            removeClippedSubviews
                            renderItem={renderItem}
                            keyExtractor={(item) => item.id}
                        />    
                    </View>
                    }
                   
                </ContentContainer>
            </Container>
        </Pagewrapper>
    );
}