import { View, Text } from 'react-native';
import React from 'react';
import { NavBarWrapper, NavButton, NavButtonActive, NavTitle, ProfileButton } from '../layout';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';

export default function Avatar() {
    const auth = useSelector(state => state.auth)
  return (
    <>
    {auth.currentUser.photoURL !== null ?
    <View style={styles.profileImage}>
    <Text style={styles.imageLetter}>{auth.currentUser.email.substring(0,1)}</Text>
    <Text style={styles.imageLetter}>{auth.currentUser.email.substring(0,1)}</Text>
    </View>
    :
    auth.currentUser.isCompany? 
    <View style={styles.profileImage}>
        <Text style={styles.imageLetter}>{auth.currentUser.accountAdmin.firstName.substring(0,1)}</Text>
        <Text style={styles.imageLetter}>{auth.currentUser.accountAdmin.lastName.substring(0,1)}</Text>
    </View>
    :
    <View style={styles.profileImage}>
        <Text style={styles.imageLetter}>{auth.currentUser.profile.userDetails.firstName.substring(0,1)}</Text>
        <Text style={styles.imageLetter}>{auth.currentUser.profile.userDetails.lastName.substring(0,1)}</Text>
    </View>
    }
    </>
    
  );
}
