import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { setChats } from "../redux/actions/chats";
import { chatsListener, messagesListener } from "../services/chats";

export const useMessages = (chatId) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.currentUser);
    const [messages, setMessages] = useState([])
    const handleMessagesChange = useCallback(
      (change) => {
        setMessages(change.docs.map(item => ({id: item.id, ...item.data()})))
      },
      [dispatch],
    )
    
    useEffect(() => {
        let listenerInstance;
        if(currentUser != null){
            listenerInstance = messagesListener(handleMessagesChange, chatId)
        }
        return () => {
            listenerInstance && listenerInstance()
        }
    }, [handleMessagesChange, currentUser, chatId])
    
    return { messages }
}