import { Pressable, View } from 'react-native';
import React, { useState } from 'react'
import styles from './styles'
import styled from 'styled-components/native';
import { SafeAreaView, Text, TextInput, TouchableOpacity } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons'; 

import { Button, Container, ContentContainer, ContentContainerWhite, NavTitleActive, Pagewrapper } from '../../components/layout';
import { signOutHandler,issueInvoiceSession } from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityIndicator } from 'react-native-web';
import { SvgGTSSuccessWhite } from '../../components/svg/success';
import SvgGTSFail, { SvgGTSFailWhite } from '../../components/svg/fail';
import { CHECKOUT_INIT } from '../../redux/constants';



export default function CheckoutScreen({ route }) {
    
    const dispatch = useDispatch()

    const { id } = route.params;
    const navigation = useNavigation()
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)
    const activeShipment = currentUserShipments != null? currentUserShipments.filter((shipment) => shipment.id === id)[0]: 0;
    const currentUserObj = useSelector(state => state.auth)
    const usid = currentUserObj.currentUser.stripeID
    const orderId = id
    const isCompany = currentUserObj.currentUser.isCompany

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const price = activeShipment.quoteInformation.price
    const tax = activeShipment.quoteInformation.tax
    
    const fullPrice = price.toFixed().toString().slice(0, -2)
    const fullTax = tax.toFixed().toString().slice(0, -2)
    const cents = price.toFixed().toString().slice(-2)
    const taxCents = tax.toFixed().toString().slice(-2)
    const calcFull = price + tax
    const fullTotal = calcFull.toFixed().toString().slice(0, -2)
    const fullCents = calcFull.toFixed().toString().slice(-2)

    const urgent = activeShipment.shipmentDetails.shipmentOptions.urgentCollection == true? "Urgent" : "";
    const t = activeShipment.shipmentDetails.shipmentOptions.urgentCollection == true? ' t' : "T";
    const dedicated = activeShipment.shipmentDetails.shipmentOptions.dedicatedVehicle == true? "dedicated" : "";
    const vehicle = activeShipment.allocatedVehicle == "sv"? 'Small Van': activeShipment.allocatedVehicle == "swb"? 'Short Wheel Base Van': activeShipment.allocatedVehicle == "mwb"? 'Medium Wheel Base Van': activeShipment.allocatedVehicle == "lwb"? 'Long Wheel Base Van': activeShipment.allocatedVehicle == "xlwb"? 'Extra Long Wheel Base Van': 'Box Van';


    function formatPostcodeString(posts) {
        const firstPostcode = posts[0].postCode;
        const lastPostcode = posts[posts.length - 1].postCode;
        const viaPostcodes = posts.slice(1, -1).map(post => post.postCode).join(', ');
      
        return `from ${firstPostcode} via ${viaPostcodes} to ${lastPostcode}`;
    }

    const route2 = !activeShipment.type || activeShipment.type == "standard" ?  `from ${activeShipment.shipmentDetails.collectionDetails.address.formatted} to ${activeShipment.shipmentDetails.deliveryDetails.address.formatted}` : formatPostcodeString(activeShipment.shipmentDetails.addresses)

    const itemText = `${urgent}${t}ransportation with ${dedicated} ${vehicle} ${route2}.`
    const handleInvoice = () => {
        dispatch(issueInvoiceSession(orderId, usid, isCompany))
        .then(() =>{
            setLoading(false)
            setError(false)
        }).catch((error) => {
            setError(error)
            setLoading(false)
        })
    }
  
    return (
        <Pagewrapper>
            <Container>
                <ContentContainer>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Pressable
                        disabled={loading}
                        onPress={() => navigation.goBack()}
                        >
                            <Feather name='chevron-left' size={24} color='black' />
                        </Pressable>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Checkout</Text>
                        <Pressable
                        disabled
                        style={{width: 24}}
                        >
                            
                        </Pressable>
                    </View>
            </ContentContainer>
                <ContentContainerWhite style={{backgroundColor: '#d72323', paddingHorizontal: 80, paddingVertical: 80}}>
                    <View>
                        
                        {error == null?
                        <>
                            <View>
                                <Text style={styles.secondaryTitle}>Pay by invoice LCC Business </Text>
                                <Text style={styles.topPrice}>£{fullTotal}.{fullCents}</Text>
                            </View>
                            <View style={[styles.row, {marginTop: 40}]}>
                                <Text style={[styles.boldTitle, {marginBottom: 5}]}>LCC Business, Same day national transport</Text>
                                <Text style={[styles.boldTitle, {marginBottom: 5}]}>£{fullPrice}.{cents}</Text>
                            </View>
                            <View>
                                <Text style={styles.description}>{itemText}</Text>
                            </View>
                            <View style={styles.rowWithLine}>
                                <Text style={styles.boldTitle}>Subtotal</Text>
                                <Text style={styles.boldTitle}>£{fullPrice}.{cents}</Text>
                            </View>
                            <View style={styles.rowWithLine}>
                                <Text style={styles.offwhite}>VAT (20%)</Text>
                                <Text style={styles.offwhite}>£{fullTax}.{taxCents}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.boldTitle}>Total due</Text>
                                <Text style={[styles.boldTitle,{fontsize: 16}]}>£{fullTotal}.{fullCents}</Text>
                            </View>
                        </>
                        :
                        error == false?
                        <>
                            <View style={styles.messageContainer}>
                                <SvgGTSSuccessWhite/>
                                <Text style={styles.message}>Checkout Successful</Text>
                            </View>
                            
                        </>
                        :
                        <>  
                            <View style={styles.messageContainer}>
                                <SvgGTSFailWhite/>
                                <Text style={styles.message}>{error}</Text>
                            </View>
                        </>
                        }
                    </View>
                    <View>
                        {error == null?
                        <Button 
                            style={{backgroundColor: 'white'}}
                            onPress={() => {handleInvoice() ,setLoading(true)}}
                            disabled={loading}
                        >
                        {    loading
                            ?<ActivityIndicator />
                            :<Text>Confirm</Text>
                        }
                        </Button>
                        :
                        error == false?
                        <Button 
                            style={{backgroundColor: 'white'}}
                            onPress={() => navigation.navigate('shipments')}
                            disabled={loading}
                        >
                            <Text>Return to shipments</Text>  
                        </Button>
                        :
                        <Button 
                            style={{backgroundColor: 'white'}}
                            onPress={() => navigation.navigate('shipments')}
                            disabled={loading}
                        >
                            <Text>Return</Text>
                        </Button>
                        }
                    </View>
                </ContentContainerWhite>
            </Container>
        </Pagewrapper>
    );
}