import { View, Text } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { auth, getCurrentUserData, handleApplyActionCode, translateType, verifyEmail } from '../../../redux/actions';
import { Button, ContentContainer, ContentContainerWhite, PageTitle } from '../../layout';
import { useNavigation } from '@react-navigation/native';
import {SvgGTSFail} from '../../svg/fail';
import {SvgGTSSuccess} from '../../svg/success';
import { applyActionCode } from 'firebase/auth';
import styles from './styles';
import { TouchableOpacity } from 'react-native-web';



export default function VerifyEmailComponent({actionCode}) {
    console.log("In this screen oobCode is :", actionCode)
    const [verified, setVerified] = useState('loading')

    const navigaten = useNavigation()
    const handleReSend = () => {
        dispatch(verifyEmail()).then(() => {
            
        })
        .catch(() => {

        })
    }
    
    useEffect(() => {
        applyActionCode(auth, actionCode).then((resp) => {
            setVerified(true);
            console.log(resp)
            // TODO: Display a confirmation message to the user.
            // You could also provide the user with a link back to the app.
    
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            
        }).catch((error) => {
            setVerified(error);
            console.log(error)
        });
    }, [])
    
    
    
    

  return (
    <View>
      <ContentContainer>
            <PageTitle>Verify Email</PageTitle>
        </ContentContainer>
        <ContentContainerWhite>
            {verified == 'loading'?
            <>
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSSuccess/>
                    </View>
                    <View style={{padding: 20, width: '100%', alignItems: 'center'}}>
                        <Text style={styles.popupTitle}>Verifying</Text>
                        <Text style={styles.popupText}>Please wait whilst we verify your account</Text>
                        <TouchableOpacity
                        disabled
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Loading</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </>
            :
            verified?
            <>
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSSuccess/>
                    </View>
                    <View style={{padding: 20, width: '100%', alignItems: 'center'}}>
                        <Text style={styles.popupTitle}>Email Verified</Text>
                        <Text style={styles.popupText}>Your email has been verified succesfully</Text>
                        <TouchableOpacity
                        onPress={() => {
                            auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
                                await auth.currentUser.reload()
                                navigation.navigate('setupProfile')
                              }).catch(function(error) {
                                // Handle error
                              });
                            
                        }}
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Continue</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </>
            :
            <>
                              
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSFail/>
                    </View>
                    <View style={{padding: 20, width: '100%', alignItems: 'center'}}>
                        <Text style={styles.popupTitle}>Error</Text>
                        <Text style={styles.popupText}>{verified.message}</Text>
                        <TouchableOpacity
                        onPress={() => {
                            handleReSend()
                        }}
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Re-send verification email</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                
            </>
            }
        </ContentContainerWhite>
    </View>
  )
}


