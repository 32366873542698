import { Pressable, View } from 'react-native';
import React from 'react'
import { Text } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons'; 

import { Container, ContentContainer, ContentContainerWhite, NavTitle, NavTitleActive, Pagewrapper } from '../../components/layout';
import { signOutHandler } from '../../redux/actions/auth';
import NavBar from '../../components/nav';
import styles from './styles';
import { useSelector } from 'react-redux';


export default function ProfileScreen() {
    
    function cS(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const navigation = useNavigation()
    const user = useSelector(state => state.auth.currentUser)
    console.log(user)
    return (
    <Pagewrapper>
        <Container>
            <NavBar active={""}/>
            <ContentContainer>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Pressable
                    onPress={() => navigation.navigate("dashboard")}
                    >
                        <Feather name='chevron-left' size={24} color='black' />
                    </Pressable>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>Edit Profile</Text>
                    <Pressable
                    disabled
                    style={{width: 24}}
                    >
                        
                    </Pressable>
                </View>
            </ContentContainer>
            {/* <ContentContainer style={{alignItems: "center"}}>
                <View style={{height: 100, width: 100, borderRadius: 80, backgroundColor: "#fff", justifyContent: "center", alignItems: "center"}}>
                    <Text>JD</Text>
                </View>
                <Text>{user.isCompany? `${cS(user.accountAdmin.firstName)} ${cS(user.accountAdmin.lastName)}`: `${cS(user.profile.userDetails.firstName)} ${cS(user.profile.userDetails.lastName)}`}</Text>
                {user.isCompany && <Text>{cS(user.accountAdmin.jobTitle)}</Text>}
            </ContentContainer> */}
            <ContentContainerWhite>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Account Type</NavTitle>
                    </View>
                    <NavTitle>{user.isBusiness? "LCC Business": "LCC Easy Send"}</NavTitle>
                </View>
                <View style={styles.divider}/>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Email</NavTitle>
                    </View>
                    <NavTitle>{user.email}</NavTitle>
                </View>
                <View style={styles.divider}/>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Name</NavTitle>
                    </View>
                    <NavTitle>{user.name}</NavTitle>
                </View>
          </ContentContainerWhite>
          {user.isCompany && <ContentContainerWhite>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Company Name</NavTitle>
                    </View>
                    <NavTitle>{user.company.companyName}</NavTitle>
                </View>
                <View style={styles.divider}/>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Company Number</NavTitle>
                    </View>
                    <NavTitle>{user.company.companyNumber}</NavTitle>
                </View>
                <View style={styles.divider}/>
                <View style={styles.itemContainer}>
                    <View style={styles.symbolWrapper}>
                        <NavTitle>Tax Number</NavTitle>
                    </View>
                    <NavTitle>{user.company.taxNumber}</NavTitle>
                </View>
          </ContentContainerWhite>}
          <ContentContainerWhite>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Pressable
                    onPress={() => signOutHandler()}
                    style={{flexDirection: 'row', alignItems: 'center'}}
                    >
                        <Feather style={{marginRight: 10}} name='log-out' size={18} color='#d72323' />
                        <NavTitleActive>Sign Out</NavTitleActive>
                    </Pressable>
                </View>
          </ContentContainerWhite>
      </Container>
    </Pagewrapper>
  );
}