import { View, Text } from 'react-native';
import React from 'react';
import { NavBarWrapper, NavButton, NavButtonActive, NavTitle, NavTitleActive} from '../layout';
import { useNavigation } from '@react-navigation/native';
import ProfileButtonComponent from '../profileButton';
import { useSelector } from 'react-redux';
import { Image, TouchableOpacity } from 'react-native-web';

import lcc01 from '../../../assets/LCC-Logo-sa.png'

export default function NavBar({active= ""}) {
    const navigation = useNavigation()
    const user = useSelector(state => state.auth.currentUser)
  return (
    <NavBarWrapper>
                <View>
                    <ProfileButtonComponent />
                    {active == "dashboard"?
                    <NavButtonActive
                    onPress={() => navigation.navigate('dashboard')}
                    >
                        <NavTitleActive>Home</NavTitleActive>
                    </NavButtonActive>
                    :
                    <NavButton
                    onPress={() => navigation.navigate('dashboard')}
                    >
                        <NavTitle>Home</NavTitle>
                    </NavButton>
                    }
                    {active == "newShipment"?
                    <NavButtonActive
                    onPress={() => navigation.navigate('newShipment')}
                    >
                        <NavTitleActive>New Shipment</NavTitleActive>
                    </NavButtonActive>
                    :
                    <NavButton
                    onPress={() => navigation.navigate('newShipment')}
                    >
                        <NavTitle>New Shipment</NavTitle>
                    </NavButton>
                    }
                    {active == "shipments"?
                    <NavButtonActive
                    onPress={() => navigation.navigate('shipments')}
                    >
                        <NavTitleActive>Shipments</NavTitleActive>
                    </NavButtonActive>
                    :
                    <NavButton
                    onPress={() => navigation.navigate('shipments')}
                    >
                        <NavTitle>Shipments</NavTitle>
                    </NavButton>
                    }
                    {user.isCompany && (active == "invoices"?
                    <NavButtonActive
                    onPress={() => navigation.navigate('invoices')}
                    >
                        <NavTitleActive>Invoices</NavTitleActive>
                    </NavButtonActive>
                    :
                    <NavButton
                    onPress={() => navigation.navigate('invoices')}
                    >
                        <NavTitle>Invoices</NavTitle>
                    </NavButton>
                    )}
                    {active == "support"?
                    <NavButtonActive
                    onPress={() => navigation.navigate('support')}
                    >
                        <NavTitleActive>Support</NavTitleActive>
                    </NavButtonActive>
                    :
                    <NavButton
                    onPress={() => navigation.navigate('support')}
                    >
                        <NavTitle>Support</NavTitle>
                    </NavButton>
                    }
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('about')} style={{position: "fixed", top: "100VH", flexDirection: "row", backgroundColor: "#fff", paddingVertical: 12, paddingHorizontal: 16, borderRadius: 15, marginTop: -100}}>
                    <Image resizeMode={"contain"} style={{width: 30, marginRight: 10}} source={lcc01}/>
                    <View>
                        <Text style={{fontWeight: "600", fontSize: 16}}>LCC {user.isBusiness? "Business" : "Easy Send"}</Text>
                        <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                            <Text style={{fontSize: 12, color: "light-gray"}}>Version:</Text>
                            <Text style={{fontSize: 12, color: "light-gray"}}>2.0.0</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </NavBarWrapper>
  );
}
