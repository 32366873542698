import { Touchable, View } from 'react-native';
import React, { useState } from 'react'
import styles from './styles'
import { ActivityIndicator, CheckBox, FlatList, Linking, Picker, Pressable, Text, TextInput, TouchableOpacity } from 'react-native-web';
import { useNavigation } from '@react-navigation/native';
import CalendarPicker from 'react-native-calendar-picker';
import { Feather } from '@expo/vector-icons';
import TimePicker from 'react-time-picker';

import { Button, Container, ContentContainer, ContentContainerWhite, NavTitle, OptionsBarWrapper, OptionsContainer, PageTitle, Pagewrapper} from '../../components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { addRequestQuoteToDB, getAddressPrediction, getCurrentUserData } from '../../redux/actions';
import moment, { parseZone } from 'moment';
import {SvgGTSFail} from '../../components/svg/fail';
import {SvgGTSSuccess} from '../../components/svg/success';
import { CLOSE_INPUT, NS_INIT, OPEN_INPUT, REFRESH_INIT, USER_UPDATE } from '../../redux/constants';
import NavBar from '../../components/nav';
import { removeTemplate, saveNewTempelate } from '../../services/shipments';

const momenttz = require('moment-timezone');



export default function NewShipmentScreen() {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const newShipmentState = useSelector(state => state.auth.ns)
    const openInput = useSelector(state => state.orderItems.itemBox)
    const currentOrderItems = useSelector(state => state.orderItems.currentOrderItems)
    const refreshed = useSelector(state => state.orderItems.refresh)
    const user = useSelector(state => state.auth.currentUser)
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)

    console.log("USER",user.templates)
    const [save, setSave] = useState(0)
    const [shipmentTemplates, setShipmentTemplates] = useState(true)
    const [clonePrevious, setClonePrevious] = useState(false)
    const [templateName, setTemplateName] = useState("")
    const [type, setType] = useState('standard')

    //Handles the multi waypoint address array__________________________________________________________________________________________
    

    const [addresses, setAddresses] = useState([{ name: "", phone:"", address: "", postCode: "",addressDescription:"",  notes: "", cargoChange: "", type: "Load", searchQuery: "", dropdown: false, error: false, sessionToken: makeid(), predictions: [] }]);

    const handleAddressChange = (value, index, key) => {
        const newAddresses = [...addresses];
        newAddresses[index][key] = value;
        setAddresses(newAddresses);
    };
  
    const handleTypeChange = (value, index) => {
        const newAddresses = [...addresses];
        newAddresses[index].type = value;
        setAddresses(newAddresses);
    };
  
    const handleAddAddress = () => {
         setAddresses([...addresses, { name: "", phone:"", address: "", postCode: "", addressDescription:"",  notes: "", cargoChange: "", type: "Unload", searchQuery: "", dropdown: false, error: false, sessionToken: makeid(), predictions: []}]);
    };
  
    const handleChangePosition = (index, direction) => {
        const newAddresses = [...addresses];
        const currentIndex = index;
        const newIndex = direction === "up" ? index - 1 : index + 1;
        [newAddresses[currentIndex], newAddresses[newIndex]] = [newAddresses[newIndex], newAddresses[currentIndex]];
        setAddresses(newAddresses);
    };
  
    const handleDeleteAddress = (index) => {
        const newAddresses = [...addresses];
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
    };

    const handleGetPlaceId = (value, sessionToken, index) => {
        const predictionFeedData = {searchQuery: value,sessionToken: sessionToken, searchType:'autocomplete'}
        getAddressPrediction(predictionFeedData)
        .then(response => {
            handleAddressChange(response.data.predictions, index, "predictions") 
        })
        .catch(error => {

        })

    }

    const handleGetAddress = (place_Id, index) => {
        const AddressFeedData = {placeId: place_Id,sessionToken: type == 1? collectionSessionToken : deliverySessionToken, searchType:'address'}
        getAddressPrediction(AddressFeedData)
        .then(response => {
            if(response.data.result.address_components[response.data.result.address_components.findIndex(x => x.types.includes('postal_code'))].long_name.length < 5){
                handleAddressChange(true , index , "error")
                handleAddressChange(true , index , "dropdown")
            }
            else{
                handleAddressChange(response.data.result, index, "address");
                handleAddressChange(response.data.result.formatted_address, index, "searchQuery");
                handleAddressChange(response.data.result.address_components[response.data.result.address_components.findIndex(x => x.types.includes('postal_code'))].long_name, index, "postCode");   
            }
        })
        .catch(error => {
        })
    }


    //-------------------------------------------TESTING SHIT HERE------------------------------------

    function splitPostCodes(postCodeArray) {
        const first = postCodeArray.slice(0, -1).map(obj => obj.postCode).join('|');
        const last = postCodeArray.slice(1).map(obj => obj.postCode).join('|');
        return {first, last};
      }
    const {first, last} = splitPostCodes(addresses)
    console.log(first,":", last)
    //------------------------------------------------------------------------------------------------
  
    const addressItemStyle = (index) => {
    return {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "75%",
      marginTop: 5,
      marginBottom: 15,
      backgroundColor: "#f5f2f2",
      borderRadius: 10,
      paddingHorizontal: 10,
      paddingVertical: 10,
      marginLeft: "25%",
      position: "relative",
      zIndex: 1,
    };
  };

  const dotStyle = () => {
    return {
      position: "absolute",
      left: "12.5%",
      marginLeft: -6,
      width: 15,
      height: 15,
      borderRadius: 50,
      backgroundColor: "#000",
      top: "50%",
      zIndex: 0,
    };
  };

    const routeBox ={
        position: "absolute",
        left: "0%",
        width: "25%",
        height: `${addresses.length > 1? ((100 / (2* addresses.length)) * ((2 * addresses.length)-2) ): 0}%`,
        top: `${100/ (addresses.length*2)}%`,
        marginTop:0,
        zIndex: 0,
    }
    const routeLineStyle = {
        position: "absolute",
        left: "50%",
        width: 3,
        height: "100%",
        backgroundColor: "#000",
        top: 0,
        marginTop: 0,
        zIndex: 0,
        borderRadius: 3
      };

    const routeStartStyle = {
        position: "absolute",
        Left: 0,
        width: "100%",
        marginTop:-50,
        zIndex: 0,
        textAlign: "center"
    };

    const routeEndStyle = {
        position: "absolute",
        bottom: 0,
        Left: 0,
        width: "100%",
        marginBottom:-70,
        zIndex: 0,
        textAlign: "center"
    };
    
    const checkFieldsPopulated = (objectsArray, fieldsArray) => {
        for (let i = 0; i < objectsArray.length; i++) {
            for (let j = 0; j < fieldsArray.length; j++) {
                const field = fieldsArray[j];
                if (!objectsArray[i][field] || Array.isArray(objectsArray[i][field]) && objectsArray[i][field].length === 0) {
                    switch (field) {
                        case "name":
                            alert(`Waypoint ${i+1} is missing Contact Person Name`)
                            break;
                        case "phone":
                            alert(`Waypoint ${i+1} is missing Contact Person Phone`)
                            break;
                        case "address":
                            alert(`Waypoint ${i+1} is missing a valid address`)
                            break;
                        case "postcode":
                            alert(`Waypoint ${i+1} has an invalid address with no accurate postcode`)
                            break;
                        case "cargoChange":
                            alert(`Waypoint ${i+1} is missing cargo specification`)
                            break;
                        default:
                            alert("Invalid field entered");
                    }
                    return true;
                }
            }
        }
    }

    //___________________________________________________________

    const [cloneWindowOpen, setCloneWindowOpen] = useState(false)
    const [collectionDropdown, setCollectionDropdown] = useState(false)
    const [deliveryDropdown, setDeliveryDropdown] = useState(false)
    const [collectionWarningShortAddress, setCollectionWarningShortAddress] = useState(false)
    const [deliveryWarningShortAddress, setDeliveryWarningShortAddress] = useState(false)

    const [isUrgent, setIsUrgent] = useState(0)

    const [isError, setIsError] = useState(0)
    const [isErrorMin, setIsErrorMin] = useState(0)

    const [isToday, setIsToday] = useState (2)

    const [detailedItems, setDetailedItems] = useState ([])
    
    const [detailedItem, setDetailedItem] = useState ({name: "", description:"", length: "", width: "", height: "" , weight: "", value: "", quantity: "1"})

    const handleFreightItemChange = (value, key) => {
        const newItem = {...detailedItem};
        newItem[key] = value;
        setDetailedItem(newItem);
    };
    const handleFreightItemDelete = (index) =>{
        const newItems = [...detailedItems];
        newItems.splice(index, 1);
        setDetailedItems(newItems);       
    }

    const [collectionSessionToken, setCollectionSessionToken] = useState(makeid())
    const [deliverySessionToken, setDeliverySessionToken] = useState(makeid())
    const [collectionSearchQuery, setCollectionSearchQuery] = useState('')
    const [deliverySearchQuery, setDeliverySearchQuery] = useState('')

    
    const [selectedVehicle, setSelectedVehicle] = useState();

    const [collectionContactName, setCollectionContactName] = useState('')
    const [collectionContactPhone, setCollectionContactPhone] = useState('')
    const [collectionAddress, setCollectionAddress] = useState('')
    const [collectionPostcode, setCollectionPostcode] = useState('')
    const [collectionAddressDescription, setCollectionAddressDescription] = useState('')
    
    const [deliveryContactName, setDeliveryContactName] = useState('')
    const [deliveryContactPhone, setDeliveryContactPhone] = useState('')
    const [deliveryAddress, setDeliveryAddress] = useState('')
    const [deliveryPostcode, setDeliveryPostcode] = useState('')
    const [deliveryAddressDescription, setDeliveryAddressDescription] = useState('')

    const [tab, setTab] = useState('standard')
    const [cargoDescription, setCargoDescription] = useState('')
    const [totalWeight, setTotalWeight] = useState('')
    const [longestItemLength, setLongestItemLength] = useState('')
    const [estimatedValue, setEstimatedValue] = useState('')

    const [calendarDatePick, setCalendarDatePick] = useState(0)
    const [selectStartDate, setSelectStartDate] = useState('')

    const [isDedicatedSelected, setDedicatedSelection] = useState(false);
    const [isTermsSelected, setTermsSelection] = useState(false);

    const startDate = selectStartDate ? selectStartDate.toString() : '';
    
    const [collectionHour, setCollectionHour] = useState('')
    const [collectionMinute, setCollectionMinute] = useState('')

    const [collectionPredictions, setCollectionPredictions] = useState([])
    const [deliveryPredictions, setDeliveryPredictions] = useState([])
    function makeid() {
        var text = "";
        var possible = "abcdefghijklmnopqrstuvxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    
        for (var i = 0; i < 32; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    
        return text;
    }

    if(refreshed == true){
        dispatch({type: REFRESH_INIT})
    }

    
    const getPlaceId = (type) => {
        const predictionFeedData = {searchQuery: type == 1? collectionSearchQuery: deliverySearchQuery,sessionToken: type == 1? collectionSessionToken: deliverySessionToken, searchType:'autocomplete'}
        getAddressPrediction(predictionFeedData)
        .then(response => {
            type == 1? setCollectionPredictions(response.data.predictions): setDeliveryPredictions(response.data.predictions);
        })
        .catch(error => {

        })

    }

    
    const getAddress = (place_Id, type) => {
        const AddressFeedData = {placeId: place_Id,sessionToken: type == 1? collectionSessionToken : deliverySessionToken, searchType:'address'}
        getAddressPrediction(AddressFeedData)
        .then(response => {
            if(response.data.result.address_components[response.data.result.address_components.findIndex(x => x.types.includes('postal_code'))].long_name.length < 5){
                if(type == 1){
                    setCollectionDropdown(true);
                    setCollectionWarningShortAddress(true);
                }
                if(type == 2){
                    setDeliveryDropdown(true);
                    setDeliveryWarningShortAddress(true);
                }
            }
            else{
                if(type == 1){
                    setCollectionAddress(response.data.result);
                    setCollectionSearchQuery(response.data.result.formatted_address)
                    setCollectionPostcode(response.data.result.address_components[response.data.result.address_components.findIndex(x => x.types.includes('postal_code'))].long_name)
                }
                if(type == 2){
                    setDeliveryAddress(response.data.result);
                    setDeliverySearchQuery(response.data.result.formatted_address)
                    setDeliveryPostcode(response.data.result.address_components[response.data.result.address_components.findIndex(x => x.types.includes('postal_code'))].long_name)
                }
                
            }
            
        })
        .catch(error => {

        })

    }
    const handleAddFreightItem = () => {
        const requiredFields = ['name', 'description', 'length', 'width', 'height', 'weight', 'value'];
        const missingFields = [];

        requiredFields.forEach(field => {
        if (!detailedItem.hasOwnProperty(field)) {
            missingFields.push(field);
        }
        });
        if (missingFields.length >= 1) {
        alert(`Some fields are missing: ${missingFields.join(', ')}`);
        }
        setDetailedItems([...detailedItems, detailedItem])
        dispatch({type: CLOSE_INPUT})
        setDetailedItem({name: "", description:"", length: "", width: "", height: "" , weight: "", value: "", quantity: "1"})
        
    }  

    const getMonth = (e) => {
        if(e.substring(4,7) == 'Jan'){return 1}
        if(e.substring(4,7) == 'Feb'){return 2}
        if(e.substring(4,7) == 'Mar'){return 3}
        if(e.substring(4,7) == 'Apr'){return 4}
        if(e.substring(4,7) == 'May'){return 5}
        if(e.substring(4,7) == 'Jun'){return 6}
        if(e.substring(4,7) == 'Jul'){return 7}
        if(e.substring(4,7) == 'Aug'){return 8}
        if(e.substring(4,7) == 'Sep'){return 9}
        if(e.substring(4,7) == 'Oct'){return 10}
        if(e.substring(4,7) == 'Nov'){return 11}
        if(e.substring(4,7) == 'Dec'){return 12}
    }
    const month = getMonth(startDate)
    const CollectionDateAndTime = new Date (startDate.substring(11,15),month -1,startDate.substring(8,10),collectionHour,collectionMinute)

    const calcCountdownDate = () => {
        if(startDate || collectionHour !=='' || !collectionMinute !==''){
            var a = moment.tz(`${startDate.substring(11,15)}-${month}-${startDate.substring(8,10)} ${collectionHour}:${collectionMinute}`, "Europe/London");
            let newCollectionHour
            (a.format().substring(19,20)) == '+'? newCollectionHour = collectionHour - 1 : newCollectionHour = collectionHour;
            return new Date (Date.UTC(startDate.substring(11,15),month -1,startDate.substring(8,10),newCollectionHour,collectionMinute))
        }
    }
    const calcTimeZoneForDate = () => {
        if(startDate || collectionHour !=='' || !collectionMinute !==''){
            var a = moment.tz(`${startDate.substring(11,15)}-${month}-${startDate.substring(8,10)} ${collectionHour}:${collectionMinute}`, "Europe/London");
            let utcChanger
            let finalDate
            (a.format().substring(19,20)) == '+'? utcChanger = 'GMT+0100' : utcChanger = 'GMT+0000';
            return finalDate = startDate.substring(0,16) + collectionHour + ':' + collectionMinute + ':00 ' + utcChanger
        }
    }
    const date = calcTimeZoneForDate()
    const getCountdownDate = calcCountdownDate()
    const checkTextInput = () => {
        if (type == "standard") {
            if (!collectionContactName.trim()) {
                alert('Please enter the name for the collection contact person');
                return;
            }
            if (!collectionContactPhone.trim()) {
                alert('Please enter the phone number of the collection contact person');
                return;
            }
            if (!collectionAddress.formatted_address.trim()) {
                alert('Please enter a collection address');
                return;
            }
            if (!deliveryContactName.trim()) {
                alert('Please enter building No. of the delivery address');
                return;
            }
            if (!deliveryContactPhone.trim()) {
                alert('Please enter street of the delivery address');
                return;
            }
            if (!deliveryAddress.formatted_address.trim()) {
                alert('Please enter a delivery address');
                return;
            }
        }
        if(type !== "standard") {
            var fail = checkFieldsPopulated(addresses,["name","phone","address","postCode"])
            if(fail == true){
                return;
            }
        }
        
        
        if(tab == 'standard'){
            // standard check
            if (!cargoDescription.trim()) {
                alert('Please enter description of the cargo');
                return;
            }
            if (!totalWeight.trim()) {
                alert('Please enter the total weight of the cargo');
                return;
            }
            if (!longestItemLength.trim()) {
                alert('Please enter the length of the longest item');
                return;
            }
            if (!estimatedValue.trim()) {
                alert('Please enter the estimated value');
                return;
            }
        }else if(tab == 'moreSpecific'){
            // more specific check
            if (detailedItems.length == 0) {
                alert('Please add in item in the cargo section');
                return;
            }
        }else{
            // vehicle check
            if (selectedVehicle == 0) {
                alert('Please select a vehicle for transportation')
                return;
            }
        }
        if (!startDate.trim()) {
            alert('Please select a date for collection');
            return;
        }
        if (!collectionHour.trim() || !collectionMinute.trim()) {
            alert('Please select a collection time');
            return;
        }

        if (isTermsSelected == false) {
        alert('Please accept the terms of service by clicking the checkbox');
        return;
        }
        var urgent = false
        if(isUrgent == 1){
            urgent = true
        }
        var dedicated = false
        if(isDedicatedSelected == true || tab == 'vehicle'){
            dedicated = true
        }
        //Checked Successfully
        var shipmentDetails={}
        type == "standard"?
        shipmentDetails={
            collectionDetails:{
                contactPerson: collectionContactName,
                contactNumber: collectionContactPhone,
                address:{
                    collectionAddress,
                    description: collectionAddressDescription,
                    formatted: collectionAddress.formatted_address,
                    postcode: collectionPostcode,
                }
            },
            deliveryDetails:{
                contactPerson: deliveryContactName,
                contactNumber: deliveryContactPhone,
                address:{
                    deliveryAddress,
                    description: deliveryAddressDescription,
                    formatted: deliveryAddress.formatted_address,
                    postcode: deliveryPostcode,
                }
            }, 
            dateAndTime:{
                date: date,
                timeH: collectionHour,
                timeM: collectionMinute,
                countdown: getCountdownDate,
            },
            shipmentOptions:{
                urgentCollection: urgent,
                dedicatedVehicle: dedicated,
            }

        }
        :
        shipmentDetails={
            addresses: addresses,
            dateAndTime:{
                date: date,
                timeH: collectionHour,
                timeM: collectionMinute,
                countdown: getCountdownDate,
            },
            shipmentOptions:{
                urgentCollection: urgent,
                dedicatedVehicle: dedicated,
            }

        }
        var cargoDetails={}
        {tab == 'standard'?
            cargoDetails={
                type: 'standard',
                description: cargoDescription,
                totalWeight: totalWeight,
                longestLength: longestItemLength,
                value: estimatedValue,
            }
            :
            tab == 'moreSpecific'?
            cargoDetails={
                type: 'moreSpecific',
                items: detailedItems
            }
            :
            cargoDetails={
                type: 'vehicle', 
                description: cargoDescription,
                vehicle: selectedVehicle
            }
        }
        handleSubmitNewShipment(shipmentDetails, cargoDetails, type);
      };
    
    const handleSubmitNewShipment = (shipmentDetails, cargoDetails, type) => {

        dispatch(addRequestQuoteToDB(shipmentDetails, cargoDetails, user, type)).then(() => {
            setSelectStartDate('')
            setCollectionHour('')
            setCollectionMinute('')
            setDedicatedSelection(false)
            setTermsSelection(false)
            setIsUrgent(0)

        })
        .catch((error) => {
            alert(error.message)
        })
    }
    
    const monthTranslate = (date) => new Promise(async(resolve, reject) => {
        const Date = date.toString()
        const getMonth = () => {
            if(Date.substring(4,7) == 'Jan'){return 0}
            if(Date.substring(4,7) == 'Feb'){return 1}
            if(Date.substring(4,7) == 'Mar'){return 2}
            if(Date.substring(4,7) == 'Apr'){return 3}
            if(Date.substring(4,7) == 'May'){return 4}
            if(Date.substring(4,7) == 'Jun'){return 5}
            if(Date.substring(4,7) == 'Jul'){return 6}
            if(Date.substring(4,7) == 'Aug'){return 7}
            if(Date.substring(4,7) == 'Sep'){return 8}
            if(Date.substring(4,7) == 'Oct'){return 9}
            if(Date.substring(4,7) == 'Nov'){return 10}
            if(Date.substring(4,7) == 'Dec'){return 11}
        }
        const day = (Date.substring(8,10))
        const year = (Date.substring(11,15))
        const month = await getMonth()

        if(day == momenttz().tz('Europe/London').date() && month == momenttz().tz('Europe/London').month() && year == momenttz().tz('Europe/London').year()){
            setIsToday(1) 
        } else {
            setIsToday(2)
        }
            resolve()
    })

    const dateChanger = async(date) => {
        
        await monthTranslate(date).then(() =>{
            setSelectStartDate(date) 
            setCalendarDatePick(0)  
        })
    }

    const handleClone = ({item}) => {
        console.log(item)
        setType(item.type)
        setCollectionHour(item.shipmentDetails.dateAndTime.timeH)
        setCollectionMinute(item.shipmentDetails.dateAndTime.timeM)
        setDedicatedSelection(item.shipmentDetails.shipmentOptions.dedicatedVehicle)

        const setCargoDetails = () => {
            if(item.cargoDetails.type === "standard"){
                setTab(item.cargoDetails.type)
                setCargoDescription(item.cargoDetails.description)
                setTotalWeight(item.cargoDetails.totalWeight)
                setLongestItemLength(item.cargoDetails.longestLength)
                setEstimatedValue(item.cargoDetails.value)
                
                
            }
            if(item.cargoDetails.type === "moreSpecific"){
                setTab(item.cargoDetails.type)
                setDetailedItems(item.cargoDetails.items)
            }
            if(item.cargoDetails.type === "vehicle"){
                setTab(item.cargoDetails.type)
                setCargoDescription(item.cargoDetails.description)
                setSelectedVehicle(item.cargoDetails.vehicle)
            }
        }
        if(!item.type || item.type === "standard"){
            let cD = item.shipmentDetails.collectionDetails
            let dD = item.shipmentDetails.deliveryDetails
            setCollectionSearchQuery(cD.address.formatted)
            setDeliverySearchQuery(dD.address.formatted)
            setCollectionContactName(cD.contactPerson)
            setCollectionContactPhone(cD.contactNumber)
            setCollectionAddress(cD.address.collectionAddress)
            setCollectionPostcode(cD.address.postcode)
            setCollectionAddressDescription(cD.address.description)
            setDeliveryContactName(dD.contactPerson)
            setDeliveryContactPhone(dD.contactNumber)
            setDeliveryAddress(dD.address.deliveryAddress)
            setDeliveryPostcode(dD.address.postcode)
            setDeliveryAddressDescription(dD.address.description)
            setCargoDetails()
        };
        if(item.type === "multi"){
            setAddresses(item.shipmentDetails.addresses)
            setCargoDetails()
        }

    }
    const handleNewTemplate = () =>{
        var dedicated = false
        if(isDedicatedSelected == true || tab == 'vehicle'){
            dedicated = true
        }
        var shipmentDetails={}
        type == "standard"?
        shipmentDetails={
            collectionDetails:{
                contactPerson: collectionContactName,
                contactNumber: collectionContactPhone,
                address:{
                    collectionAddress,
                    description: collectionAddressDescription,
                    formatted: collectionAddress?.formatted_address == undefined ? "" : collectionAddress.formatted_address ,
                    postcode: collectionPostcode,
                }
            },
            deliveryDetails:{
                contactPerson: deliveryContactName,
                contactNumber: deliveryContactPhone,
                address:{
                    deliveryAddress,
                    description: deliveryAddressDescription,
                    formatted: deliveryAddress?.formatted_address == undefined ? "" : deliveryAddress.formatted_address ,
                    postcode: deliveryPostcode,
                }
            }, 
            dateAndTime:{
                timeH: collectionHour,
                timeM: collectionMinute,
            },
            shipmentOptions:{
                dedicatedVehicle: dedicated,
            }

        }
        :
        shipmentDetails={
            addresses: addresses,
            dateAndTime:{
                timeH: collectionHour,
                timeM: collectionMinute,
            },
            shipmentOptions:{
                dedicatedVehicle: dedicated,
            }

        }
        var cargoDetails={}
        {tab == 'standard'?
            cargoDetails={
                type: 'standard',
                description: cargoDescription,
                totalWeight: totalWeight,
                longestLength: longestItemLength,
                value: estimatedValue,
            }
            :
            tab == 'moreSpecific'?
            cargoDetails={
                type: 'moreSpecific',
                items: detailedItems
            }
            :
            cargoDetails={
                type: 'vehicle', 
                description: cargoDescription,
                vehicle: selectedVehicle
            }
        }
        let data = {
            name: templateName,
            id: makeid(),
            type,
            cargoDetails,
            shipmentDetails,

        }
        saveNewTempelate(data).then(() => {
            const updatedTemplates = [...user.templates, data];
            const updatedUser = {
                ...user,
                templates: updatedTemplates
              };
            dispatch({
                type: USER_UPDATE,
                currentUser: updatedUser,
            })
            setSave(0)
            setTemplateName("")
        })
        
    }

    const handleDelete = (templateId) => {
        const updatedTemplates =  user.templates.filter((item) => item.id !== templateId);
        removeTemplate(updatedTemplates).then(() => {
            const updatedUser = {
                ...user,
                templates: updatedTemplates
              };
            dispatch({
                type: USER_UPDATE,
                currentUser: updatedUser,
            })
            console.log("updatedTemplates",updatedUser)
           // setTimeout(dispatch(getCurrentUserData()), 3000)
        })
    }
     
    return (
    <Pagewrapper>
        <Container>
            <NavBar active={"newShipment"}/>
            {newShipmentState.loading == false && newShipmentState.error == null &&<OptionsBarWrapper>
                <OptionsContainer>
                    <View style={styles.optionsWrapper}>
                        <TouchableOpacity style={[styles.buttonWithDropdown, {marginBottom: cloneWindowOpen? 20:0}]} onPress={() => setCloneWindowOpen(!cloneWindowOpen)}>
                            <Text style={{fontWeight: "500", fontSize: 16}}>Load Template</Text>
                            <Feather name={cloneWindowOpen? "chevron-up":"chevron-down"} size={24}/>
                        </TouchableOpacity>
                        {
                            cloneWindowOpen?
                            <>
                            <TouchableOpacity style={styles.buttonWithDropdown} onPress={() => setShipmentTemplates(!shipmentTemplates)}>
                                <Text>Shipment Templates</Text>
                                <Feather name={shipmentTemplates? "chevron-up":"chevron-down"} size={16}/>
                            </TouchableOpacity>
                            {shipmentTemplates &&
                                <FlatList
                                style={[styles.loadItems, {marginBottom: 10}]}
                                data={user.templates}
                                keyExtractor={(item) => item.id}
                                renderItem={({item, index}) => (
                                    <TouchableOpacity style={styles.templateItem} onPress={() => handleClone({item})}>
                                        <Text>{item.name}</Text>
                                        <TouchableOpacity onPress={() => handleDelete(item.id)}>
                                            <Feather name='trash-2' size={16}/>
                                        </TouchableOpacity>
                                    </TouchableOpacity>
                                )}
                                />
                            }
                            <TouchableOpacity style={[styles.buttonWithDropdown,{marginTop: 10}]} onPress={() => setClonePrevious(!clonePrevious)}>
                                <Text>Previous Shipment</Text>
                                <Feather name={clonePrevious? "chevron-up":"chevron-down"} size={16}/>
                            </TouchableOpacity>
                            {clonePrevious &&
                                <FlatList
                                    style={styles.loadItems}
                                    data={currentUserShipments}
                                    keyExtractor={(item) => item.id}
                                    renderItem={({item}) => (
                                        <TouchableOpacity 
                                        style={styles.templateItem}
                                        onPress={() => handleClone({item})}
                                        >
                                            <Text>{item.uid}</Text>
                                        </TouchableOpacity>
                                    )}
                                />

                            }
                            </>
                        :
                        <></>
                    }
                    </View>
                    <View style={{width: "100%", marginTop: 10, paddingHorizontal: 24, paddingVertical: 10, borderRadius: 15, backgroundColor:"#fff"}}>
                        {save == 1? 
                        <View style={{flexDirection: "column", width: "100%"}}>
                            <View>
                                <TextInput 
                                style={{flex:1, marginBottom: 5, paddingHorizontal: 5, paddingVertical: 5, borderRadius: 8, borderColor: '#f5f2f2', borderWidth: 1,}}
                                onChangeText={(value) => setTemplateName(value)}
                                value={templateName}
                                placeholder="Enter template name"
                                />
                            </View>
                            <View style={{justifyContent: "space-between", flex: 1, flexDirection: "row"}}>
                                <TouchableOpacity style={{backgroundColor: "#d72323", paddingVertical: 3, paddingHorizontal: 10, borderRadius: 30}} disabled={templateName == ""} onPress={() => handleNewTemplate()}>
                                    <Text style={{color: "#fff"}}>Save</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{}} onPress={() => setSave(0)}>
                                    <Text>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        :
                        <>
                        <TouchableOpacity onPress={()=> setSave(1)}>
                            <Text>Save as new template</Text>
                        </TouchableOpacity>
                        </>
                        }
                    </View>
                </OptionsContainer>
            </OptionsBarWrapper>
            }

            <ContentContainer>
                <PageTitle>New Shipment</PageTitle>
            </ContentContainer>
            {newShipmentState.loading ?
            <>
            <ContentContainerWhite>                
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '30%'}}>
                        <ActivityIndicator
                        color='#d72323'
                        size='large'
                        />
                    </View>
                    <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Loading...</Text>
                        <View style={{height: 40}}></View>
                        <Text style={styles.popupText}></Text>
                        <TouchableOpacity
                        disabled
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Please wait...</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ContentContainerWhite>
            </>
            :
            newShipmentState.error == null?
            <>
            <ContentContainerWhite>                
                <View>
                    <NavTitle>Shipment Type</NavTitle>
                    
                    <View style={{width: '100%', marginTop: 10}}>
                        <View style={{flexDirection: 'row',backgroundColor: '#f5f2f2', borderRadius: 15,}}>
                            <TouchableOpacity
                            style={[styles.tabNav,{width: "50%"}, type == 'standard'? {backgroundColor: '#d72323'}:{}]}
                            onPress={() => setType('standard')}>
                                <Text style={type == 'standard'? {color: 'white'}:{}}>Standard</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                            style={[styles.tabNav,{width: "50%"}, type == 'multi'? {backgroundColor: '#d72323'}:{}]}
                            onPress={() => setType('multi')}>
                                <Text style={type == 'multi'? {color: 'white'}:{}}>Multi drop/collections</Text>
                            </TouchableOpacity>
                        </View>
                    </View>                    
                </View>
            </ContentContainerWhite>

            {type == 'standard'?    //Standard type collection >> delivery
            <>
                <ContentContainerWhite>                
                    <View>
                        <NavTitle>Collection Address</NavTitle>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <View style={{width: '47.5%', marginRight: '5%'}}>
                                
                                <Text>Contact Person Name</Text>
                                <TextInput
                                onChangeText={(text) => setCollectionContactName(text)}
                                style={styles.textInput}
                                placeholder='Enter Full Name'
                                value={collectionContactName}
                                />
                            </View>
                            <View style={{width: '47.5%',}}>
                                <Text>Contact Person Phone</Text>
                                <TextInput
                                onChangeText={(text) => setCollectionContactPhone(text)}
                                style={styles.textInput}
                                placeholder='Enter Phone'
                                value={collectionContactPhone}
                                />
                            </View>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <View style={{width: '100%', marginRight: '0%'}}>
                                
                                <Text>Select Address</Text>
                                <TextInput
                                autoCompleteType="no"
                                onChangeText={(text) => {setCollectionSearchQuery(text), getPlaceId(1), setCollectionDropdown(text.length > 0? true : false)}}
                                style={[styles.textInput, {zIndex: 50} ]}
                                placeholder='Enter full address'
                                value={collectionSearchQuery}
                                />
                                    {collectionDropdown == false?
                                    <></>
                                    :    
                                    <View style={styles.dropDownFlatlist}>
                                        {collectionWarningShortAddress?
                                        <View style={styles.warningWrapper}>
                                            <Text style={styles.warningText}>Warning, this is not a complete address, please add a building number to the full address</Text>
                                        </View>
                                        :
                                        <></>
                                    }                        
                                        <FlatList
                                        numColumns={1}
                                        removeClippedSubviews
                                        data={collectionPredictions}
                                        keyExtractor={(item) => item.title}
                                        renderItem={({item}) => (
                                            <View style={{paddingVertical: 5, borderBottomWidth: 1, borderBottomColor: 'white'}}>
                                                <TouchableOpacity
                                                onPress={() => {getAddress(item.place_id, 1), setCollectionDropdown(false)}}>
                                                    <Text>{item.description}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        )}
                                        />
                                    </View>
                                    }
                            </View>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{width: '100%', marginRight: 20}}>
                                <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                    <Text>Address Description   </Text><Text style={styles.optionalText}>Optional</Text>
                                </View>
                                <TextInput
                                onChangeText={(text) => setCollectionAddressDescription(text)}
                                style={styles.textInput}
                                placeholder='Enter Address Description'
                                value={collectionAddressDescription}
                                />
                            </View>
                        </View>
                    </View>
                </ContentContainerWhite>  
                
                <ContentContainerWhite>                
                    <View>
                        <NavTitle>Delivery Address</NavTitle>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <View style={{width: '47.5%', marginRight: '5%'}}>
                                
                                <Text>Contact Person Name</Text>
                                <TextInput
                                onChangeText={(text) => setDeliveryContactName(text)}
                                style={styles.textInput}
                                placeholder='Enter Name'
                                value={deliveryContactName}
                                />
                            </View>
                            <View style={{width: '47.5%',}}>
                                <Text>Contact Person Phone</Text>
                                <TextInput
                                onChangeText={(text) => setDeliveryContactPhone(text)}
                                style={styles.textInput}
                                placeholder='Enter Phone'
                                value={deliveryContactPhone}
                                />
                            </View>
                        </View>
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <View style={{width: '100%', marginRight: '0%'}}>
                                
                                <Text>Address</Text>
                                <TextInput
                                autoCompleteType="no"
                                onChangeText={(text) => {setDeliverySearchQuery(text), getPlaceId(2), setDeliveryDropdown(text.length > 0? true : false)}}
                                style={[styles.textInput, {zIndex: 50} ]}
                                placeholder='Enter full address'
                                value={deliverySearchQuery}
                                />
                                {deliveryDropdown == false?
                                <></>
                                :    
                                <View style={styles.dropDownFlatlist}> 
                                    {deliveryWarningShortAddress?
                                    <View style={styles.warningWrapper}>
                                        <Text style={styles.warningText}>Warning, this is not a complete address, please add a building number to the full address</Text>
                                    </View>
                                    :
                                    <></>
                                }                                
                                    <FlatList
                                    numColumns={1}
                                    removeClippedSubviews
                                    data={deliveryPredictions}
                                    keyExtractor={(item) => item.title}
                                    renderItem={({item}) => (
                                        <View style={{paddingVertical: 5, borderBottomWidth: 1, borderBottomColor: 'white'}}>
                                            <TouchableOpacity
                                            onPress={() => {getAddress(item.place_id, 2), setDeliveryDropdown(false)}}>
                                                <Text>{item.description}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                    />
                                </View>
                                }
                            </View>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{width: '100%', marginRight: 20}}>
                                <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                    <Text>Address Description   </Text><Text style={styles.optionalText}>Optional</Text>
                                </View>
                                <TextInput
                                onChangeText={(text) => setDeliveryAddressDescription(text)}
                                style={styles.textInput}
                                placeholder='Enter Address Description'
                                value={deliveryAddressDescription}
                                />
                            </View>
                        </View>
                    </View>
                </ContentContainerWhite>
            </>
            :
            <>
                <ContentContainerWhite>                
                    <View style={{marginBottom: 40}}>
                        <NavTitle>Route</NavTitle>
                        <View style={styles.containerNew}>
                            <View style={routeBox}>
                                <View style={routeLineStyle}/>
                                <View style={{position: "relative", height: "100%"}}>
                                    <Text style={[routeStartStyle, styles.routeText]}>Start</Text>
                                    <Text style={[routeEndStyle, styles.routeText]}>End</Text>
                                    
                                </View>
                            </View>
                            
                            {addresses.map((address, index) => (
                                <>
                                    <View key={index} style={styles.addressContainer}>
                                        <View style={dotStyle(index)} />
                                        <View style={addressItemStyle(index)}>
                                            <View style={{width: '100%', flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                                                <NavTitle>Waypoint {index + 1}</NavTitle>   
                                                <Picker
                                                    selectedValue={address.type}
                                                    onValueChange={(value) => handleTypeChange(value, index)}
                                                    style={styles.picker}
                                                >
                                                    <Picker.Item label="Load" value="Load" />
                                                    <Picker.Item label="Unload" value="Unload" />
                                                    <Picker.Item label="Load & Unload" value="loadAndUnload" />
                                                </Picker>
                                            </View>
                                            <Text style={{marginTop: 10}}>Contact Person Name</Text>
                                            <TextInput
                                                style={[styles.textInput,{width: "100%"}]}
                                                onChangeText={(value) => handleAddressChange(value, index, "name")}
                                                value={address.name}
                                                placeholder='Enter Full Name'
                                            />
                                            <Text>Contact Person Phone</Text>
                                            <TextInput
                                                style={[styles.textInput,{width: "100%"}]}
                                                onChangeText={(value) => handleAddressChange(value, index, "phone")}
                                                value={address.phone}
                                                placeholder='Enter Phone'
                                            />
                                            <View style={{width: '100%',flexDirection: 'row', marginTop: 10}}>
                                                <View style={{width: '100%', marginRight: '0%'}}> 
                                                    <Text>Select Address</Text>
                                                    <TextInput
                                                    autoCompleteType="no"
                                                    onChangeText={(value) => {
                                                        handleAddressChange(value, index, "searchQuery")
                                                        handleGetPlaceId(value, address.sessionToken, index)
                                                        handleAddressChange(value.length > 0? true : false, index, "dropdown")
                                                        handleAddressChange([], index, "address")
                                                        handleAddressChange(false, index, "error")
                                                    }}
                                                    style={[styles.textInput, {zIndex: 50, width: "100%"} ]}
                                                    placeholder='Enter Full Address'
                                                    value={address.searchQuery}
                                                    />
                                                    <View style={{position: "relative"}}>
                                                    {address.dropdown == false?
                                                    <></>
                                                    :    
                                                    <View style={[styles.dropDownFlatlistAbsolute,{backgroundColor: "#fff", borderWidth: 2, borderColor: "#000"}]}>
                                                        {address.error?
                                                        <View style={styles.warningWrapper}>
                                                            <Text style={styles.warningText}>Warning, this is not a complete address, please add a building number to the full address</Text>
                                                        </View>
                                                        :
                                                        <></>
                                                    }                        
                                                        <FlatList
                                                        numColumns={1}
                                                        removeClippedSubviews
                                                        data={address.predictions}
                                                        keyExtractor={(item) => item.title}
                                                        renderItem={({item}) => (
                                                            <View style={{paddingVertical: 5, borderBottomWidth: 1, borderBottomColor: 'white'}}>
                                                                <TouchableOpacity onPress={() => {handleGetAddress(item.place_id, index), handleAddressChange(false,index,"dropdown"), handleAddressChange(makeid(),index,"sessionToken")}}>
                                                                    <Text>{item.description}</Text>
                                                                </TouchableOpacity>
                                                            </View>
                                                        )}
                                                        />
                                                    </View>
                                                    }
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{flexDirection: 'row', alignItems: 'flex-end', zIndex: -1}}>
                                                <Text>Address Description  </Text><Text style={styles.optionalText}>Optional</Text>
                                            </View>
                                            <TextInput
                                                onChangeText={(text) => handleAddressChange(text, index, "addressDescription")}
                                                style={[styles.textInput,{width: "100%"}]}
                                                placeholder='Enter Address Description'
                                                value={address.addressDescription}
                                            />
                                            
                                            <Text>Specify {address.type === "loadAndUnload"? "which cargo is unloaded and loaded": address.type === "Load" ? "cargo being loaded":"cargo being unloaded"}</Text>
                                            <TextInput
                                                style={[styles.textInput,{width: "100%"}]}
                                                onChangeText={(value) => handleAddressChange(value, index, "cargoChange")}
                                                value={address.cargoChange}
                                                placeholder='Enter Cargo Identifier'
                                            />
                                            
                                            
                                            <View style={styles.positionButtons}>
                                                <TouchableOpacity onPress={() => handleChangePosition(index, "up")} disabled={index === 0}>
                                                    <Text style={[styles.positionButtonText, index === 0 && styles.positionButtonDisabled]}>Up</Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={() => handleChangePosition(index, "down")} disabled={index === addresses.length - 1}>
                                                    <Text style={[styles.positionButtonText, index === addresses.length - 1 && styles.positionButtonDisabled]}>Down</Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity style={{}} onPress={() => handleDeleteAddress(index)} disabled={addresses.length == 1}>
                                                    <Text style={[styles.positionButtonText, addresses.length == 1 && styles.positionButtonDisabled]}>Delete</Text>
                                                </TouchableOpacity>
                                            </View>
                                            
                                        </View>
                                    </View>
                                     
                                </>
                            ))}
                        </View>
                        <TouchableOpacity style={{left: "25%"}} onPress={() => handleAddAddress()} disabled={addresses.length > 5}>
                            <Text style={styles.addButton}>{addresses.length > 5 ? "" : "+ Add Waypoint"}</Text>
                        </TouchableOpacity> 
                    </View>
                </ContentContainerWhite>
            </>
            }
            <ContentContainerWhite>                
                <View>
                    <NavTitle>Cargo Details</NavTitle>
                    
                    <View style={{width: '100%', marginTop: 10}}>
                        <View style={{flexDirection: 'row',backgroundColor: '#f5f2f2', borderRadius: 15,}}>
                        <TouchableOpacity
                        style={[styles.tabNav, tab == 'standard'? {backgroundColor: '#d72323'}:{}]}
                        onPress={() => setTab('standard')}>
                            <Text style={tab == 'standard'? {color: 'white'}:{}}>Standard</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                        style={[styles.tabNav, tab == 'moreSpecific'? {backgroundColor: '#d72323'}:{}]}
                        onPress={() => setTab('moreSpecific')}>
                            <Text style={tab == 'moreSpecific'? {color: 'white'}:{}}>More Specific</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                        style={[styles.tabNav, tab == 'vehicle'? {backgroundColor: '#d72323'}:{}]}
                        onPress={() => setTab('vehicle')}>
                            <Text style={tab == 'vehicle'? {color: 'white'}:{}}>Vehicle Selection</Text>
                        </TouchableOpacity>
                        </View>
                    </View>

                    {tab == 'standard'?    //Standard Cargo Details Input 
                    <>
                    
                        <View style={{width: '100%', marginTop: 10}}>
                            <Text style={styles.infoText}>Standard requires less information on the goods, this however may increase processing time of your order and may result in further clarifications needed. </Text>
                            <Text>Description of Cargo</Text>
                            <TextInput
                            maxLength={150}
                            multiline={true}
                            onChangeText={(text) => setCargoDescription(text)}
                            style={styles.textInputMultiline}
                            placeholder='Enter Description...'
                            value={cargoDescription}
                            />
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{width: '100%', marginRight: 20}}>
                                
                                <Text>Total Weight in Kg</Text>
                                <TextInput
                                maxLength={4}
                                onChangeText={(text) => setTotalWeight(text.replace(/[^0-9]/g, ''))}
                                style={styles.textInput}
                                placeholder='Enter Weight'
                                value={totalWeight}
                                />
                            </View>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{width: '47.5%', marginRight: '5%'}}>
                                
                                <Text>Length of longest item in cm </Text>
                                <TextInput
                                onChangeText={(text) => setLongestItemLength(text.replace(/[^0-9]/g, ''))}
                                style={styles.textInput}
                                placeholder='Enter Length'
                                value={longestItemLength}
                                />
                            </View>
                            <View style={{width: '47.5%',}}>
                                <Text>Estimated Value in £</Text>
                                <TextInput
                                onChangeText={(text) => setEstimatedValue(text.replace(/[^0-9]/g, ''))}
                                style={styles.textInput}
                                placeholder='Enter Value'
                                value={estimatedValue}
                                />
                            </View>
                        </View>
                    </>
                    :
                    tab == 'moreSpecific'?
                    <>  
                        <Text style={[styles.infoText, {marginTop: 10,}]}>More specific details about the goods allow us to process your order faster as well as minimizing the chance that there will be space issues in the van.</Text>
                        <View style={{flexDirection: 'row', marginTop: 10, marginBottom: 5, paddingHorizontal: '0.5REM', alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={[styles.titleText, {width: '9%'}]}>Qty</Text>
                            <Text style={[styles.titleText, {width: '20%'}]}>Item Name</Text>
                            <Text style={[styles.titleText, {width: '30%'}]}>Item Description</Text>
                            <Text style={[styles.titleText, {width: '9%'}]}>L</Text>
                            <Text style={[styles.titleText, {width: '9%'}]}>W</Text>
                            <Text style={[styles.titleText, {width: '9%'}]}>H</Text>
                            <Text style={[styles.titleText, {width: '9%'}]}>W</Text>
                            <Text style={[styles.titleText, {width: '9%'}]}></Text>
                        </View>
                        <View>
                            <FlatList 
                            style={{borderRadius: 15, borderColor: '#f5f2f2', borderWidth: 1, paddingHorizontal: '0.5REM', paddingVertical: '1REM'}}
                            numColumns={1}
                            removeClippedSubviews
                            data={detailedItems}
                            extraData={detailedItems}
                            keyExtractor={(item) => item.name}
                            renderItem={({item, index}) => (
                                <View style={[styles.freightItemContainer, {flexDirection: 'row'}]}> 
                                    <Text style={[styles.freightItemListText,{width: '9%'}]}>x{item.quantity}</Text>
                                    <Text style={[styles.freightItemListText,{width: '20%'}]}>{item.name}</Text>
                                    <Text style={[styles.freightItemListText,{width: '30%'}]}>{item.description}</Text>
                                    <Text style={[styles.freightItemListText,{width: '9%'}]}>{item.length}</Text>
                                    <Text style={[styles.freightItemListText,{width: '9%'}]}>{item.width}</Text>
                                    <Text style={[styles.freightItemListText,{width: '9%'}]}>{item.height}</Text>
                                    <Text style={[styles.freightItemListText,{width: '9%'}]}>{item.weight}</Text>
                                    <TouchableOpacity
                                        style={styles.freightItemItemButton}
                                        onPress={() => handleFreightItemDelete(index)}>
                                        <Feather name="trash-2" size={16} color="#757575" />
                                    </TouchableOpacity>
                                </View>   
                                )}
                            ListEmptyComponent={(
                                <View style={{flexDirection: 'row', width: '100%', paddingVertical: 5, backgroundColor: 'lightGray',}}>
                                    <Text>No items!  </Text>
                                    <TouchableOpacity
                                    onPress={() => dispatch({type: OPEN_INPUT})}
                                    >
                                        <Text>Click here to add an item.</Text>
                                    </TouchableOpacity>
                                </View>)}
                            />
                        </View>
                        <View>
                            {openInput.open == true?
                            <>
                            <TouchableOpacity
                            style={{borderRadius: 15, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: '#d72323', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 15, opacity: '50%'}}
                            onPress={() => dispatch({type: CLOSE_INPUT})}>
                                <Feather name="chevron-up" size={16} color="#fff" />
                            </TouchableOpacity>
                            </>
                            :
                            <>
                            <TouchableOpacity
                            style={{borderRadius: 15, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: '#d72323', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 15}}
                            onPress={() => dispatch({type: OPEN_INPUT})}>
                                <Text style={{color: '#fff'}}>Add </Text>
                                <Feather name="plus" size={16} color="#fff" />
                            </TouchableOpacity>
                            </>
                            }
                        </View>
                        {openInput.open == true ?
                        <>
                            <View style={{flexDirection: 'row', marginTop: 30}}>
                                <View style={{width: '30%', marginTop: 10, marginRight: '5%'}}>
                                    <Text>Item Name</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value, "name")}
                                    value={detailedItem.name}
                                    style={styles.textInput}
                                    placeholder='Enter Item Name'
                                    />
                                </View>
                                <View style={{width: '65%', marginTop: 10}}>
                                    <Text>Item Description</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value, "description")}
                                    value={detailedItem.description}
                                    style={styles.textInput}
                                    placeholder='Enter Item Description'
                                    />
                                </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{width: '16%', marginRight: '5%'}}>
                                    <Text>Length cm</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value.replace(/[^0-9]/g, ''), "length")}
                                    value={detailedItem.length}
                                    style={styles.textInput}
                                    placeholder='Length'
                                    />
                                </View>
                                <View style={{width: '16%', marginRight: '5%'}}>
                                    
                                    <Text>Width cm</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value.replace(/[^0-9]/g, ''), "width")}
                                    value={detailedItem.width}
                                    style={styles.textInput}
                                    placeholder='Width'
                                    />
                                </View>
                                <View style={{width: '16%', marginRight: '5%'}}>
                                    
                                    <Text>Height cm</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value.replace(/[^0-9]/g, ''), "height")}
                                    value={detailedItem.height}
                                    style={styles.textInput}
                                    placeholder='Height'
                                    />
                                </View>
                                <View style={{width: '16%', marginRight: '5%'}}>
                                    
                                    <Text>Weigth kg</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value.replace(/[^0-9]/g, ''), "weight")}
                                    value={detailedItem.weight}
                                    style={styles.textInput}
                                    placeholder='Weight'
                                    />
                                </View>
                                <View style={{width: '16%', marginRight: '5%'}}>
                                    
                                    <Text>Value £</Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value.replace(/[^0-9]/g, ''), "value")}
                                    value={detailedItem.value}
                                    style={styles.textInput}
                                    placeholder='Value'
                                    />
                                </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{width: '65%', marginRight: '5%'}}>
                                    
                                    <Text>Quantity of this Item </Text>
                                    <TextInput
                                    onChangeText={(value) => handleFreightItemChange(value.replace(/[^0-9]/g, ''), "quantity")}
                                    value={detailedItem.quantity}
                                    style={styles.textInput}
                                    placeholder='1'
                                    />
                                </View>
                                <View style={{width: '30%',}}>
                                    <Button
                                    onPress={() => handleAddFreightItem()}>
                                        <Text style={{color: 'white'}}>{detailedItem.quantity <= 1 || detailedItem.quantity == ''? 'Add Item' : 'Add Items'}</Text>
                                    </Button>
                                </View>
                            </View>
                        </>
                        :
                        <>
                        </>
                        }   
                    </>
                    :
                    <>
                        <View style={{width: '100%', marginTop: 10}}>
                            <Text style={styles.infoText}>This mode allows those who know exactly which vehicle is required to select a vehicle. Booking a vehicle this way will automatically enable dedicated vehicle. Unless this is coupled with urgent collection, this mode allows for instant payment once submitted.</Text>
                            <Text>Description of Cargo</Text>
                            <TextInput
                            maxLength={150}
                            multiline={true}
                            onChangeText={(text) => setCargoDescription(text)}
                            style={styles.textInputMultiline}
                            placeholder='Enter cargo description...'
                            value={cargoDescription}
                            />
                            <Picker
                                style={{paddingVertical: '1REM', paddingHorizontal: '0.5REM', borderRadius: 15, fontSize: 16, borderColor: '#f5f2f2'}}
                                selectedValue={selectedVehicle}
                                onValueChange={(itemValue, itemIndex) =>
                                setSelectedVehicle(itemValue)
                                }>
                                <Picker.Item label="Select Vehicle" value="none" />
                                <Picker.Item label="Small Van" value="sv" />
                                <Picker.Item label="Short Wheel Base Van" value="swb" />
                                <Picker.Item label="Medium Wheel Base Van" value="mwb" />
                                <Picker.Item label="Long Wheel Base Van" value="lwb" />
                                <Picker.Item label="Extra Long Wheel Base Van" value="xlwb" />
                                <Picker.Item label="Box Van / Luton" value="bv" />
                            </Picker>
                        </View>
                    </>
                    }
                    
                </View>
            </ContentContainerWhite>
            <ContentContainerWhite>                
                <View>
                    <NavTitle>Shipment Details </NavTitle>
                    
                    <Text>Collection Date and Time</Text>
                    <View style={{marginTop: 10, flexDirection: 'row'}}>
                           
                        
                        {calendarDatePick == 1 ?
                        <View style={{alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 50}}>
                        <CalendarPicker
                            monthTitleStyle={{fontSize: '16px'}}
                            yearTitleStyle={{fontSize: '16px'}}
                            width={'500'}
                            headerWrapperStyle={{width:'100%'}}
                            restrictMonthNavigation={true}
                            minDate={momenttz().tz('Europe/London')}
                            selectedDayColor={'#d72323'}
                            selectedDayTextColor={'#fff'}
                            selectedDayStyle={{borderRadius: 10, backgroundColor:'#d72323'}}
                            dayLabelsWrapper={{borderRadius: 12, backgroundColor:'#f5f2f2', borderTopColor: '#fff', borderBottomColor: '#fff'}}
                            firstDay={1}
                            nextTitle={'Later date'}
                            onDateChange={(date) => {dateChanger(date)}}
                            />
                        </View>
                        :
                        <View style={{flexDirection: 'row', alignItems: 'flex-start', position: 'relative'}}>
                            <Pressable 
                            style={styles.dateButton}
                            onPress={() => calendarDatePick == 0 ? setCalendarDatePick(1): setCalendarDatePick(0)}
                            >
                                {selectStartDate == ''? 
                                <Text style={{fontSize: 16, color: '#757575'}}>Select Date</Text> 
                                : 
                                <Text style={{fontSize: 16}}>{startDate.substring(0,15)}</Text>
                                }
                            </Pressable>
                            <View style={{width: '7%'}}>
                                <TextInput 
                                maxLength={2}
                                style={styles.textInput}
                                placeholder={isToday == 1? ("00" + momenttz().tz('Europe/London').hours()).slice(-2) : '00'}
                                onChangeText={(text) => {
                                    if(isToday == 1){
                                        if(parseInt(text.replace(/[^0-9]/g, '')) < momenttz().tz('Europe/London').hours()){
                                            setCollectionHour(text.replace(/[^0-9]/g, ''))
                                            setIsUrgent(0)
                                            setIsError(1)
                                        }else if(parseInt(text.replace(/[^0-9]/g, '')) == momenttz().tz('Europe/London').hours()){
                                            setCollectionHour(text.replace(/[^0-9]/g, ''))
                                            setIsUrgent(1)
                                            setIsError(0)
                                        }else if(parseInt(text.replace(/[^0-9]/g, '')) >= 24){
                                            setCollectionHour(momenttz().tz('Europe/London').hours())  
                                            setIsUrgent(0)
                                            setIsError(0)
                                        }else{
                                            setCollectionHour(text.replace(/[^0-9]/g, ''))  
                                            setIsUrgent(0)
                                            setIsError(0)
                                        }
                                    }else{
                                        if(parseInt(text.replace(/[^0-9]/g, '')) >= 24){
                                            setCollectionHour('00')  
                                            setIsUrgent(0)
                                            setIsError(0)
                                        }else{
                                            setCollectionHour(text.replace(/[^0-9]/g, '')) 
                                            setIsUrgent(0)
                                            setIsError(0)
                                        }
                                    }
                                }}
                                value={collectionHour}
                                />
                            </View> 
                            <View style={{width: '3%', paddingVertical: '1REM', marginTop: '7px',alignItems: 'center'}}>
                                <Text style={{fontSize: 16}}>:</Text>
                            </View>
                            <View style={{width: '7%', marginRight: '5%',}}>
                                <TextInput 
                                maxLength={2}
                                style={styles.textInput}
                                placeholder={isToday == 1? ('00' + momenttz().tz('Europe/London').minutes()).slice(-2) : '00'}
                                onChangeText={(text) => {
                                    if(isToday == 1){
                                        if(parseInt(text.replace(/[^0-9]/g, '')) <= momenttz().tz('Europe/London').minutes() && collectionHour == momenttz().tz('Europe/London').hours()){
                                            setCollectionMinute(text.replace(/[^0-9]/g, ''))
                                            setIsUrgent(0)
                                            setIsErrorMin(1)
                                        }else if(parseInt(text.replace(/[^0-9]/g, '')) >= momenttz().tz('Europe/London').minutes() && collectionHour == momenttz().tz('Europe/London').hours() && (text.replace(/[^0-9]/g, '')) <= 59){
                                            setCollectionMinute(text.replace(/[^0-9]/g, ''))
                                            setIsUrgent(1)
                                            setIsErrorMin(0)
                                        }else if(parseInt(text.replace(/[^0-9]/g, '')) < momenttz().tz('Europe/London').minutes() && parseInt(collectionHour) == momenttz().tz('Europe/London').hours()+1){
                                            setCollectionMinute(text.replace(/[^0-9]/g, ''))
                                            setIsUrgent(1)
                                            setIsErrorMin(0)
                                        }else if(parseInt(text.replace(/[^0-9]/g, '')) >= 60){
                                            setCollectionMinute(momenttz().tz('Europe/London').minutes())  
                                            if(parseInt(collectionHour) == momenttz().tz('Europe/London').hours()+1 || collectionHour == momenttz().tz('Europe/London').hours()){
                                            setIsUrgent(1)  
                                            }
                                            setIsUrgent(1)
                                            setIsErrorMin(0)
                                        }else{
                                            setCollectionMinute(text.replace(/[^0-9]/g, ''))  
                                            setIsUrgent(0)
                                            setIsErrorMin(0)
                                        }
                                    }else{
                                        if(parseInt(text.replace(/[^0-9]/g, '')) >= 60){
                                            setCollectionMinute('00')  
                                            setIsUrgent(0)
                                            setIsErrorMin(0)
                                        }else{
                                            setCollectionMinute(text.replace(/[^0-9]/g, ''))
                                            setIsUrgent(0)
                                            setIsErrorMin(0)
                                        }
                                    }
                                }}
                                value={collectionMinute}
                                />
                            </View>
                            <View style={{width: '36%', position: 'absolute', right: '7%'}}>
                                {isError == 1 || isErrorMin == 1? 
                                <View style={{width: '100%', paddingVertical: '1REM', paddingHorizontal: '0.5REM', marginTop: '7px', borderRadius: 15, borderWidth: 1, borderColor: 'red',}}>
                                    <Text>Error</Text>
                                </View>
                                :
                                <View style={{width: '100%'}}>
                                    <Text></Text>
                                </View>
                                }
                                {isUrgent == 1? 
                                <View style={{width: '100%', paddingVertical: '1REM', paddingHorizontal: '0.5REM', marginTop: '7px', borderRadius: 15, borderWidth: 1, borderColor: 'orange', backgroundColor: 'orange', flexDirection: 'row'}}>
                                    <Text style={{color: '#fff'}}>Urgent collection </Text>
                                    <TouchableOpacity
                                    onPress={() => { Linking.openURL('https://www.dictionary.com/browse/urgently')}}>
                                        <Text style={{color: '#fff', textDecorationLine: 'underline'}}>Learn more</Text>
                                    </TouchableOpacity>
                                    
                                </View>
                                :
                                <View style={{width: '100%'}}>
                                    <Text></Text>
                                </View>
                                }
                            </View>
                        </View>
                        }
                            
                        
                        
                    </View>
                    <Text>Vehicle Mode</Text>
                    {tab !== 'vehicle'?
                    <View style={styles.checkBoxContainer}>
                        <CheckBox
                            value={isDedicatedSelected}
                            onValueChange={setDedicatedSelection}
                            style={[styles.checkbox, {alignSelf: 'center', marginRight: 10}]}
                            color={'#d72323'}
                            />
                        <Text>Dedicated Vehicle</Text>
                    </View>
                    :
                    <View style={styles.checkBoxContainer}>
                        <CheckBox
                            disabled
                            value={true}
                            onValueChange={setDedicatedSelection}
                            style={[styles.checkbox, {alignSelf: 'center', marginRight: 10}]}
                            color={'#d72323'}
                            />
                        <Text>Dedicated Vehicle is Automatic since vehicle is selected.</Text>
                    </View>
                    }
                    <Text>Terms and Conditions</Text>
                    <View style={styles.checkBoxContainer}>
                        <CheckBox
                            value={isTermsSelected}
                            onValueChange={setTermsSelection}
                            style={[styles.checkbox, {alignSelf: 'center', marginRight: 10}]}
                            color={'#d72323'}
                            />
                        <Text>I accept the Terms of Service</Text>
                    </View>
                </View>
                
                <TouchableOpacity
                onPress={() => checkTextInput()}
                style={styles.submitButton}
                >
                    <Text style={styles.submitButtonText}>Submit for Quotation</Text>
                </TouchableOpacity>
            </ContentContainerWhite>
            </>
            :
            !newShipmentState.error ?
            <>
            <ContentContainerWhite>                
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSSuccess/>
                    </View>
                    <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Success</Text>
                        <Text style={styles.popupText}>Your shipment has been created and is pending quotation.</Text>
                        <TouchableOpacity
                        onPress={() => {
                            dispatch({type: NS_INIT})
                            navigation.navigate('newShipment')
                        }}
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Continue</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ContentContainerWhite>
            </>
            :
            <>
            <ContentContainerWhite>                
                <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                        <SvgGTSFail/>
                    </View>
                    <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Error</Text>
                        <Text style={styles.popupText}>There was an error processing your order</Text>
                        <TouchableOpacity
                        onPress={() => {
                            dispatch({type: NS_INIT})
                        }}
                        style={styles.submitButton}
                        >
                            <Text style={styles.submitButtonText}>Go back</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ContentContainerWhite>
            </>
            }
        
            
            <View style={{marginBottom: 250}}>

            </View>
                
        </Container>
    </Pagewrapper>

    
      
    
  );
}