import { View, Text } from 'react-native'
import React, { useState } from 'react'
import { Container, ContentContainer, ContentContainerWhite, NavTitle, PageTitle, Pagewrapper } from '../../layout/'
import { ActivityIndicator, CheckBox, FlatList, TextInput, TouchableOpacity } from 'react-native-web'
import styles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationContainer, useNavigation } from '@react-navigation/native'
import {SvgGTSSuccess} from '../../svg/success'
import {SvgGTSFail} from '../../svg/fail'
import { getCurrentUserData, setUpIndividualProfile } from '../../../redux/actions'



export default function IndividualProfileSetUpComponent() {
  
    const dispatch = useDispatch() 

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [contactPhone, setContactPhone] = useState('')

    const [premises, setPremises] = useState('')
    const [street, setStreet] = useState('')
    const [addressLine2, setAddressLine2] = useState('n/a')
    const [region, setRegion] = useState('n/a')
    const [postcode, setPostcode] = useState('')
    const [locality, setLocality] = useState('')

    const profileSetupState = useSelector(state => state.auth.sp)
    const user = useSelector(state => state.auth.currentUser)
    
    const checkTextInput = () => {
        if (!firstName.trim()) {
            alert('Please enter your first name');
            return;
        }
        if (!lastName.trim()) {
            alert('Please enter your last name');
            return;
        }
        if (!contactPhone.trim()) {
          alert('Please enter your phone number');
          return;
        }
        if (!premises.trim()) {
          alert('Please enter your building no.');
          return;
        }
        if (!street.trim()) {
          alert('Please enter your phone number');
          return;
        }        
        if (!postcode.trim()) {
          alert('Please enter your post code');
          return;
        }
        if (!locality.trim()) {
            alert('Please enter your city');
            return;
          }
        //Checked Successfully
        handleSetUpIndividualProfile();
      };
      const navigation = useNavigation()
      const handleSetUpIndividualProfile =() => {
        
        dispatch(setUpIndividualProfile(firstName, lastName, contactPhone, premises, street, addressLine2, region, postcode, locality, user ))
        .then(() => {
            console.log('login successful')
        }).then(() => {navigation.navigate('dashboard')})
        .catch((error) => {
            console.log('login unsuccessful')
            alert(error.message)
        })
    }

  return ( 
    <>
        {profileSetupState.loading ?
            <>
                <ContentContainerWhite>                
                    <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <View style={{position: 'absolute', alignSelf: 'center', top: '30%'}}>
                            <ActivityIndicator
                            color='#d72323'
                            size='large'
                            />
                        </View>
                        <View style={{padding: 20, width: '50%'}}>
                        <Text style={styles.popupTitle}>Loading...</Text>
                        <View style={{height: 40}}></View>
                        <Text style={styles.popupText}></Text>
                            <TouchableOpacity
                            disabled
                            style={styles.submitButton}
                            >
                                <Text style={styles.submitButtonText}>Please wait...</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ContentContainerWhite>
            </>
        :
            profileSetupState.error == null?
                <>
                    <ContentContainerWhite>                
                        <View>
                            <NavTitle>Personal Details</NavTitle>
                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <View style={{width: '47.5%', marginRight: '5%'}}>
                                    
                                    <Text>First Name</Text>
                                    <TextInput
                                    onChangeText={(text) => setFirstName(text)}
                                    style={styles.textInput}
                                    placeholder='Enter first name'
                                    />
                                </View>
                                <View style={{width: '47.5%',}}>
                                    <Text>Last Name</Text>
                                    <TextInput
                                    onChangeText={(text) => setLastName(text)}
                                    style={styles.textInput}
                                    placeholder='Enter last name'
                                    />
                                </View>
                            </View>
                            <View style={{width: '100%', marginRight: 20}}>
                                
                                <Text>Contact Phone No.</Text>
                                <TextInput
                                onChangeText={(text) => setContactPhone(text)}
                                style={styles.textInput}
                                placeholder='Enter your Phone No.'
                                />
                            </View>
                        </View>
                    </ContentContainerWhite> 
                    <ContentContainerWhite>                
                        <View>
                            <NavTitle>Address</NavTitle>
                            <View style={{flexDirection: 'row', marginTop: 10}}>
                                <View style={{width: '45%', marginRight: '5%'}}>
                                    <Text>Premises</Text>
                                    <TextInput
                                    onChangeText={(text) => setPremises(text)}
                                    style={styles.textInput}
                                    placeholder='Enter No.'
                                    />
                                </View>
                                <View style={{width: '50%',}}>
                                    <Text>Street</Text>
                                    <TextInput
                                    onChangeText={(text) => setStreet(text)}
                                    style={styles.textInput}
                                    placeholder='Street name'
                                    />
                                </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{width: '50%', marginRight: '5%'}}>
                                    <Text>Address Line 2</Text>
                                    <TextInput
                                    onChangeText={(text) => setAddressLine2(text)}
                                    style={styles.textInput}
                                    placeholder='Address line 2'
                                    />
                                </View>
                                <View style={{width: '40%',}}>
                                    <Text>City</Text>
                                    <TextInput
                                    onChangeText={(text) => setLocality(text)}
                                    style={styles.textInput}
                                    placeholder='City'
                                    />
                                </View>
                            </View>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{width: '20%', marginRight: '5%'}}>
                                    <Text>Postcode</Text>
                                    <TextInput
                                    onChangeText={(text) => setPostcode(text)}
                                    style={styles.textInput}
                                    placeholder='Postcode'
                                    />
                                </View>
                                <View style={{width: '40%', marginRight: '5%'}}>
                                    <Text>Region</Text>
                                    <TextInput
                                    onChangeText={(text) => setRegion(text)}
                                    style={styles.textInput}
                                    placeholder='Region'
                                    />
                                </View>
                                <View style={{width: '30%',}}>
                                    <Text>Country</Text>
                                    <TextInput
                                    disabled
                                    style={styles.textInput}
                                    placeholder='United Kingdom'
                                    />
                                </View>
                            </View>
                            <TouchableOpacity
                            onPress={() => checkTextInput()}
                            style={styles.submitButton}
                            >
                                <Text style={styles.submitButtonText}>Complete</Text>
                            </TouchableOpacity>
                        </View>
                    </ContentContainerWhite>
                </>
            :
                !profileSetupState.error ?
                    <>
                        <ContentContainerWhite>                
                            <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                                    <SvgGTSSuccess/>
                                </View>
                                <View style={{padding: 20, width: '50%'}}>
                                    <Text style={styles.popupTitle}>Success</Text>
                                    <Text style={styles.popupText}>Your profile is setup</Text>
                                    <TouchableOpacity
                                    onPress={() => dispatch(getCurrentUserData())}
                                    style={styles.submitButton}
                                    >
                                        <Text style={styles.submitButtonText}>Continue</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </ContentContainerWhite>
                    </>
                :
                    <>
                        <ContentContainerWhite>                
                            <View style={{height: 500, position: 'relative', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <View style={{position: 'absolute', alignSelf: 'center', top: '16%'}}>
                                    <SvgGTSFail/>
                                </View>
                                <View style={{padding: 20, width: '50%'}}>
                                    <Text style={styles.popupTitle}>Error</Text>
                                    <Text style={styles.popupText}>There was an error processing your data</Text>
                                    <TouchableOpacity
                                    onPress={() => {
                                        dispatch({type: SP_INIT})
                                    }}
                                    style={styles.submitButton}
                                    >
                                        <Text style={styles.submitButtonText}>Try again</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </ContentContainerWhite>
                    </>
            }
        </>
  )
}