
import React from 'react'
import { FlatList, Text } from 'react-native-web';
import { Container, ContentContainer, ContentContainerWhite, PageTitle, Pagewrapper} from '../../components/layout';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import { PieChart } from 'react-native-svg-charts'
import styles from './styles';
import NavBar from '../../components/nav';
import ShipmentsListItem from '../../components/shipmentsList/item';
import DashboardShipmentItem from '../../components/dashboard/shipmentsItemPP';
import { useNavigation } from '@react-navigation/native';
import DashboardShipmentItemPC from '../../components/dashboard/shipmentsItemPC';
import DashboardShipmentItemPP from '../../components/dashboard/shipmentsItemPP';


export default function DashboardScreen() {
    const navigation = useNavigation()
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)
    const PP = currentUserShipments?.filter((shipment) => shipment.status == "PP")
    const PC = currentUserShipments?.filter((shipment) => shipment.status == "PC")
    const EN = currentUserShipments?.filter((shipment) => shipment.status == "EN")
    const totalDistance = currentUserShipments?.reduce((accumulator, shipment) => {
        return accumulator + parseInt(shipment.status == "D"? shipment.type == "standard" ? shipment.route.distance : (shipment.route.distance/ 1609.344).toFixed(2) :"0");
      }, 0).toLocaleString("en-GB");
    const completedShipments = currentUserShipments?.filter((shipment) => shipment.status == "D").length
    const user = useSelector(state => state.auth.currentUser)
    let today = new Date();
    let hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
    const greet = hours < 6 ? 'early morning' : hours < 12? 'morning' : hours < 18? 'afternoon': 'evening' 
    const userName = user.isCompany? user.accountAdmin.firstName : user.profile.userDetails.firstName
    const message = `Good ${greet}, ${userName}`;
    //const data = [PC?.length, PP?.length, EN?.length,]
    const data = [PC?.length, PP?.length, EN?.length,]
    const colors = ['#F1F1F1', '#E8A7A7', '#D9D9D9',]
    const pieData = data
        .filter((value) => value > 0)
        .map((value, index) => ({
            value,
            svg: {
                fill: colors[index],
                onClick: () => console.log('press', index),
            },
            key: `pie-${index}`,
        }))

    return (
    <Pagewrapper>
        <Container>
            <NavBar active={"dashboard"}/>
            <ContentContainer>
                <PageTitle>{message}</PageTitle>

            </ContentContainer>
            
            <ContentContainerWhite>
                <View style={{flexDirection: 'row', paddingVertical: 8}}>
                    <View style={{flex:1, flexDirection: 'row'}}>
                        <View style={{width: 150, marginRight: 24}}>
                            {PP == 0 && PC == 0 && EN == 0?
                                <View style={{height: 150, width: 150, backgroundColor: "#a7c957", borderRadius: 75, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#fff"}}>{currentUserShipments?.length == 0 ?"No Shipments yet" :"All shipments OK"}</Text>
                                </View>
                                :
                                <PieChart padAngle={0} style={{ height: 150 }} data={pieData} />
                            }
                        </View>
                        <View style={{alignSelf: "flex-end"}}>
                            <View style={{flexDirection: 'row', alignItems: "center"}}>
                                <View style={[styles.chartItem,{backgroundColor: '#E8A7A7'}]}></View>
                                <Text>Pending Payment</Text>
                            </View>
                            <View style={{flexDirection: 'row', alignItems: "center"}}>
                                <View style={[styles.chartItem,{backgroundColor: '#D9D9D9'}]}></View>
                                <Text>Pending Collection</Text>
                            </View>
                            <View style={{flexDirection: 'row', alignItems: "center"}}>
                                <View style={[styles.chartItem,{backgroundColor: '#F1F1F1'}]}></View>
                                <Text>Enroute</Text>
                            </View>
                        </View>
                        
                    </View>
                    <View style={{justifyContent: 'space-between',alignItems: 'flex-end'}}>
                        <View style={{alignItems: 'flex-end', marginBottom: 10}}>
                            <Text style={styles.valueTitle}>{completedShipments}</Text>
                            <Text>Delivered Shipments</Text>
                        </View>
                        <View style={{alignItems: 'flex-end'}}>
                            <Text style={styles.valueTitle}>{totalDistance}mi</Text>
                            <Text>Total Distance</Text>
                        </View>
                    </View>
                </View>
            </ContentContainerWhite>
            
            {/* <ContentContainerWhite>
                    <View>
                        <View>
                            <Text>Pending Payment</Text>
                        </View>
                        <View>
                        <FlatList
                            numColumns={1}
                            removeClippedSubviews
                            data={PP}
                            keyExtractor={(item) => item.id}
                            renderItem={({item}) => (<DashboardShipmentItemPP item={item}/>)}
                            /> 
                        </View> 
                    </View>             
            </ContentContainerWhite>
            <ContentContainerWhite>
                    <View>
                        <View>
                            <Text>Pending Collection</Text>
                        </View>
                        <View>
                        <FlatList
                            numColumns={1}
                            removeClippedSubviews
                            data={PC}
                            keyExtractor={(item) => item.id}
                            renderItem={({item}) => (<DashboardShipmentItemPC item={item}/>)}
                            /> 
                        </View> 
                    </View>             
            </ContentContainerWhite> */}
        </Container>
    </Pagewrapper>
    );
}
  