import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"

export const SvgGTSSuccess = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <G transform="matrix(.32922 0 0 .32922 -84.601 10.67)">
      <Circle
        style={{
          fill: "none",
          fillOpacity: 1,
          stroke: "#d72323",
          strokeWidth: 5.29167,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx={337.344}
        cy={47.956}
        r={77.721}
      />
      <Path
        style={{
          fill: "none",
          stroke: "#d72323",
          strokeWidth: 5.29167,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M368.082 16.97 332.06 78.566l-25.576-27.48"
      />
    </G>
  </Svg>
)

export const SvgGTSSuccessWhite = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
    {...props}
  >
    <G transform="matrix(.32922 0 0 .32922 -84.601 10.67)">
      <Circle
        style={{
          fill: "none",
          fillOpacity: 1,
          stroke: "#fff",
          strokeWidth: 5.29167,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx={337.344}
        cy={47.956}
        r={77.721}
      />
      <Path
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 5.29167,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M368.082 16.97 332.06 78.566l-25.576-27.48"
      />
    </G>
  </Svg>
)

