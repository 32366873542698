import { View, Text } from 'react-native'
import React from 'react'
import { TouchableOpacity } from 'react-native-web'
import styles from './styles'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

const ChatListItem = ({ chat }) => {
    const navigation = useNavigation()
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments)
    console.log(chat)
    const closed = chat.status == 'closed'? true : false;
    var shipmentData = ''
    // currentUserShipments.filter((shipment) => shipment.id == chat.shipmentId)[0]
    {chat.shipmentId == false ?
        shipmentData = false
        :
        shipmentData = currentUserShipments.filter((shipment) => shipment.id == chat.shipmentId)[0]        
    }
    console.log(chat.id, chat.shipmentId, '=', shipmentData)
  return (
    <TouchableOpacity onPress={() => navigation.navigate('chatSingle', { chatId: chat.id, closed: closed })} style={closed? styles.containerClosed: styles.container}>
        <View style={closed?null:styles.notificationContainer}>
        </View>
        
        <View style={{flex: 1}}>
            <Text style={styles.supportTitle}>{chat.category}</Text>
                {shipmentData !== false?
                <>
                    <Text style={styles.shipmentNo}>{shipmentData?.uid}</Text>
                </>
            :
                <>
                    <Text style={styles.shipmentNo}></Text>
                </>
            }  
            <Text style={styles.supportTopic}>{chat.subCategory}</Text>
            <Text style={styles.lastMessage}>{chat.lastMessage}</Text>
            <Text style={styles.lastMessageTime}>{chat.lastUpdate? new Date(chat.lastUpdate.seconds * 1000).toLocaleTimeString('en-GB').slice(0,5) : 'Now'}</Text>
        </View>    
        <Text style={styles.lastUpdate}>{chat.lastUpdate? new Date( chat.lastUpdate.seconds * 1000).toISOString().slice(0, 10) : 'Now' }</Text>

    </TouchableOpacity>
  )
}

export default ChatListItem