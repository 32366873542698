import {SELECTED_COMPANY, SELECTED_COMPANY_RESET, SELECTED_COMPANY_OPEN} from '../constants'

const initialState = {
    selectedCompany: null, 
    set: false,
    open: false,
}

export const company = (state = initialState, action) => {
    switch(action.type){
        case SELECTED_COMPANY:
            return{
                ...state, 
                selectedCompany: action.selectedCompany, set: true, open: false
            }
        case SELECTED_COMPANY_RESET:
            return{
                ...state, 
                selectedCompany: action.selectedCompany, set: false, open: false
                }
        case SELECTED_COMPANY_OPEN:
            return{
                ...state, 
                selectedCompany: action.selectedCompany, set: false, open: true
                }
        default : 
            return state;
    }
}

