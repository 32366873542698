export const USER_STATE_CHANGE = 'USER_STATE_CHANGE'
export const USER_UPDATE = 'USER_UPDATE'
export const CURRENT_USER_SHIPMENTS_UPDATE = 'CURRENT_USER_SHIPMENTS_UPDATE'
export const MODAL_OPEN_COMMENT_SECTION = 'MODAL_OPEN_COMMENT_SECTION'
export const CLEAR_MODAL = 'CLEAR_MODAL'
export const VERIFY_START = 'VERIFY_START'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAIL = 'VERIFY_FAIL'
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const REGISTER_START = 'REGISTER_START'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const NAV_HOME = 'NAV_HOME'
export const NAV_SHIPMENTS = 'NAV_SHIPMENTS'
export const NAV_NEWSHIPMENT = 'NAV_NEWSHIPMENT'
export const NS_START = 'NS_START'
export const NS_SUCCESS = 'NS_SUCCESS'
export const NS_FAIL = 'NS_FAIL'
export const NS_INIT = 'NS_INIT'
export const SELECTED_COMPANY = 'SELECTED_COMPANY'
export const SELECTED_COMPANY_RESET = 'SELECTED_COMPANY_RESET'
export const SELECTED_COMPANY_OPEN = 'SELECTED_COMPANY_OPEN'
export const SP_START = 'SP_START'
export const SP_SUCCESS = 'SP_SUCCESS'
export const SP_FAIL = 'SP_FAIL'
export const SP_INIT = 'SP_INIT'
export const CURRENT_ORDER_ITEMS = 'CURRENT_ORDER_ITEMS'
export const CLOSE_INPUT = 'CLOSE_INPUT'
export const OPEN_INPUT = 'OPEN_INPUT'
export const REFRESH = 'REFRESH'
export const REFRESH_INIT = 'REFRESH_INIT'
export const SETCHECKOUTID = 'SETCHECKOUTID'
export const CHECKOUT_INIT = 'CHECKOUT_INIT'
export const CHECKOUT_START = 'CHECKOUT_START'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAIL = 'CHECKOUT_FAIL'
export const CHATS_SET = 'CHATS_SET'



