import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    idText:{
        fontSize: 14,
        fontWeight: "400",
    },
    idDescription:{
        fontSize: 8,
        fontWeight: "400",
    }
    

});

export default styles;