import React from 'react'
import { View, Text, Image, FlatList } from 'react-native'
import { Feather, FontAwesome5 } from '@expo/vector-icons'; 
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone'
import { ActivityIndicator, Pressable } from 'react-native-web';

import styles from './styles'
import { issueCheckoutSession } from '../../../redux/actions';
import FreightListItem from '../../newShipmentItem';
import {  useNavigation } from '@react-navigation/native';
import { CHECKOUT_INIT } from '../../../redux/constants';
import { createChat } from '../../../services/chats';



export default function ShipmentsListItem({item}) {
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const currentUserObj = useSelector(state => state.auth)
    const chats = useSelector(state => state.chat.list)

    const [loadingCheckout, setLoadingCheckout] = useState(false)
    const [requested, setRequested] = useState(false)

    const usid = currentUserObj.currentUser.stripeID
    const orderId = item.id
    const isCompany = currentUserObj.currentUser.isCompany
    const itemStatusLong = item.status == "TBQ"? "Pending Quotation" : item.status == "PP"? "Pending Payment" : item.status == "PC"? "Awaiting Collection" : item.status == "EN"? "Enroute" : item.status == "D"? "Delivered" : item.status == "CO"? "Complete" : item.status == "CA"? "Cancelled" : item.status == "Expired"? "Expired" :"Error";
    const isActive = chats.filter((item) => item.shipmentId === orderId && item.status === 'open').length !== 0? chats.filter((item) => item.shipmentId === orderId && item.status === 'open')[0].id : false;
    
    const price = (item?.quoteInformation?.price/100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
    const tax = (item?.quoteInformation?.tax/100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
    const totalPrice = ((item?.quoteInformation?.price + item?.quoteInformation?.tax)/100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
    
    const handleValidityExtension = () => {
        isActive? navigation.navigate('chatSingle', { chatId: isActive }) : createChat(item.id, "Quote Expired", "Extend Validity").then((res) => navigation.navigate('chatSingle', { chatId: res.id }));
    }
    const handleCheckout = () => {
        setLoadingCheckout(true)
        dispatch(issueCheckoutSession(orderId, usid, isCompany))
    }
    const handleInvoice = () => {
        dispatch({type: CHECKOUT_INIT})
        navigation.navigate({name: 'checkout', params: { id: item.id, uid: item.uid } })
    }
    
   

    return (
        <>
            {!requested? 
            <Pressable 
                style={styles.container}
                onPress={() => setRequested(true)}
            >
                {item.status === "TBQ" ?
                
                <View style={[styles.bottomLine1, {width: '0%'}]}/>
                :
                item.status === "PP" || item.status === "Expired" ?
                <View style={[styles.bottomLine1, {width: '25%'}]}/>
                :
                item.status === "PC" ?
                <View style={[styles.bottomLine1, {width: '50%'}]}/>
                :
                item.status === "EN" ?
                <View style={[styles.bottomLine1, {width: '75%'}]}/>
                :
                <View style={[styles.bottomLine1, {width: '100%'}]}/>
                }    
                <Text style={[styles.listText, {width: '19%'}]}>{item.uid}</Text>
                <Text style={[styles.listText, {width: '36%'}]}>{!item.type || item.type === "standard"? `${item.shipmentDetails.collectionDetails.address.postcode} > ${item.shipmentDetails.deliveryDetails.address.postcode}` : `${item.shipmentDetails.addresses?.length} Waypoint Route`}</Text>
                <Text style={[styles.listText, {width: '27%', fontSize: 10}]}>{item.shipmentDetails.dateAndTime.date.substring(4,15)} {item.shipmentDetails.dateAndTime.timeH}:{item.shipmentDetails.dateAndTime.timeM}</Text>
                <Text style={[styles.listText1,{fontSize: 12, fontWeight: 'bold'}]}>{itemStatusLong}</Text>
                <Pressable
                style={styles.itemButton}
                onPress={() => setRequested(true)}>
                    <Feather name="maximize-2" size={14} color="#000" />
                </Pressable>
            </Pressable>
            :
            <View style={[styles.container, {alignItems: 'flex-start', flexDirection: 'column', paddingHorizontal: 0, paddingTop: 0, paddingBottom: 50}]}>
                {item.status === "TBQ" ?
                    <View style={styles.statusContainer}>
                        <View style={styles.statusTextWrapper}>
                            <Text style={styles.statusActiveText}>To be Quoted</Text>
                            <View style={styles.separatorLineOpen}/>
                            <Text style={styles.statusOpenText}>Payment</Text>
                            <View style={styles.separatorLineOpen}/>
                            <Text style={styles.statusOpenText}>Collection</Text>
                            <View style={styles.separatorLineOpen}/>
                            <Text style={styles.statusOpenText}>Delivery</Text>
                            <View style={styles.separatorLineOpen}/>
                        </View>
                        <View style={[styles.bottomLine, {width: '0%'}]}/>
                        <View style={[styles.bottomLine1, {width: '100%', opacity: '20%'}]}/>
                    </View>
                :
                item.status === "PP" || item.status === "Expired" ?
                    <View style={styles.statusContainer}>
                        <View style={styles.statusTextWrapper}>
                            <Text style={styles.statusCompletedText}>Quoted</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusActiveText}>{item.status === "PP"? "Pending Payment":"Expired"}</Text>
                            <View style={styles.separatorLineOpen}/>
                            <Text style={styles.statusOpenText}>{item.status === "PP"? "Collection":""}</Text>
                            <View style={styles.separatorLineOpen}/>
                            <Text style={styles.statusOpenText}>{item.status === "PP"? "Delivery":""}</Text>
                            <View style={styles.separatorLineOpen}/>
                        </View>
                        <View style={[styles.bottomLine, {width: '25%'}]}/>
                        <View style={[styles.bottomLine1, {width: '100%', opacity: '20%'}]}/>
                    </View>
                :
                item.status === "PC" ?
                    <View style={styles.statusContainer}>
                        <View style={styles.statusTextWrapper}>
                            <Text style={styles.statusCompletedText}>Quoted</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusCompletedText}>Paid</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusActiveText}>Pending Collection</Text>
                            <View style={styles.separatorLineOpen}/>
                            <Text style={styles.statusOpenText}>Delivery</Text>
                            <View style={styles.separatorLineOpen}/>
                        </View>
                        <View style={[styles.bottomLine, {width: '50%'}]}/>
                        <View style={[styles.bottomLine1, {width: '100%', opacity: '20%'}]}/>
                    </View>
                :
                item.status === "EN" ?
                    <View style={styles.statusContainer}>
                        <View style={styles.statusTextWrapper}>
                            <Text style={styles.statusCompletedText}>Quoted</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusCompletedText}>Paid</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusCompletedText}>Collected</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusActiveText}>En Route</Text>
                            <View style={styles.separatorLineOpen}/>
                        </View>
                        <View style={[styles.bottomLine, {width: '75%'}]}/>
                        <View style={[styles.bottomLine1, {width: '100%', opacity: '20%'}]}/>
                    </View>
                :
                    <View style={styles.statusContainer}>
                        <View style={styles.statusTextWrapper}>
                            <Text style={styles.statusCompletedText}>Quoted</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusCompletedText}>Paid</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusCompletedText}>Collected</Text>
                            <View style={styles.separatorLine}/>
                            <Text style={styles.statusCompletedText}>Delivered</Text>
                            
                        </View>
                        <View style={[styles.bottomLine, {width: '100%'}]}/>
                    </View>
                }
                <Pressable 
                    style={{flexDirection: 'row', width: '100%', alignItems: 'center', paddingVertical:10,paddingHorizontal: 15, minHeight: 52}}
                    onPress={() => setRequested(false)}
                >
                    <Text style={[styles.listText, {width: '19%'}]}>{item.uid}</Text>
                    <Text style={[styles.listText, {width: '36%'}]}>{!item.type || item.type === "standard"? `${item.shipmentDetails.collectionDetails.address.postcode} > ${item.shipmentDetails.deliveryDetails.address.postcode}` : `${item.shipmentDetails.addresses?.length} Waypoint Route`}</Text>
                    <Text style={[styles.listText, {width: '27%', fontSize: 10}]}>{item.shipmentDetails.dateAndTime.date.substring(4,15)} {item.shipmentDetails.dateAndTime.timeH}:{item.shipmentDetails.dateAndTime.timeM}</Text>
                    <Text style={[styles.listText1,{fontSize: 12, fontWeight: 'bold'}]}>{itemStatusLong}</Text>
                    <Pressable
                    style={styles.itemButton}
                    onPress={() => setRequested(false)}
                    >
                        <Feather name="minimize-2" size={14} color="#000" />
                    </Pressable>
                </Pressable>
                <View style={{width: '100%',flex: 1,flexDirection: 'column', paddingHorizontal: 15}}>
                    {item.status === 'Expired'?
                    <>
                        <Text style={styles.divTitle}>Quote Details</Text>
                        <View style={styles.itemWrapper}>
                            <View style={styles.lineDiv}>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Quote Issue Date</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{moment(parseInt(item.quoteInformation.issueDate, 10)).tz('Europe/London').format('DD.MM.YYYY HH:mm:ss')}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Quote Expiry Date</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text style={{fontWeight: 'bold'}}>{moment(parseInt(item.quoteInformation.expiryDate, 10)).tz('Europe/London').format('DD.MM.YYYY HH:mm:ss')}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection: 'row', alignItems: 'flex-end', maxHeight: 40, marginTop: 0}}>
                                    <Pressable
                                        style={styles.payButton}
                                        onPress={() => handleValidityExtension()}
                                    >
                                        <Text style={{color:'#fff', marginRight: 5}}>Extend Quote Validity</Text>
                                        {loadingCheckout
                                        ? <ActivityIndicator size="small" color="white" animating={loadingCheckout} />
                                        : <Feather name="clock" size={16} color="#fff" />
                                        }
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </>
                    :
                    <>
                    </>
                    }
                    {item.status !== 'TBQ' && item.status !== 'Expired' ?
                    isCompany?
                    item.status == 'PP'?
                    item.invoiceable?
                    // Invoiceable, Status = Pending Payment, and is company
                    <>
                        <Text style={styles.divTitle}>Quote Details</Text>
                        <View style={styles.itemWrapper}>
                            <View style={styles.lineDiv}>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Quote Issue Date</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{moment(parseInt(item.quoteInformation.issueDate, 10)).tz('Europe/London').format('DD.MM.YYYY HH:mm:ss')}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Quote Expiry Date</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text style={{fontWeight: 'bold'}}>{moment(parseInt(item.quoteInformation.expiryDate, 10)).tz('Europe/London').format('DD.MM.YYYY HH:mm:ss')}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.lineDiv}>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Quote (ex. VAT)</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{price}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>VAT (20%)</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{tax}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Total</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text style={{marginRight: 5, fontWeight: 'bold'}}>{totalPrice}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection: 'row', alignItems: 'flex-end', maxHeight: 40, marginTop: 0}}>
                                    <Pressable
                                        style={[styles.payButton, {backgroundColor: 'gray', marginRight: 5}]}
                                        onPress={() => handleInvoice()}
                                    >
                                        <Text style={{color:'#fff', marginRight: 5}}>Invoice</Text>
                                        <FontAwesome5 name="file-invoice-dollar" size={16} color="#fff" />   
                                    </Pressable>
                                    <Pressable
                                        style={styles.payButton}
                                        onPress={() => handleCheckout()}
                                    >
                                        
                                        <Text style={{color:'#fff', marginRight: 5}}>Pay Now</Text>
                                        {loadingCheckout
                                        ? <ActivityIndicator size="small" color="white" animating={loadingCheckout} />
                                        : <Feather name="credit-card" size={16} color="#fff" />
                                        }
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </>
                    :// not invoiceable, Status = Pending Payment, and is company
                    <>
                        <Text style={styles.divTitle}>Quote Details</Text>
                        <View style={styles.itemWrapper}>
                            <View style={styles.lineDiv}>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Quote (ex. VAT)</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{price}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>VAT (20%)</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{tax}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Total</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text style={{marginRight: 5, fontWeight: 'bold'}}>{totalPrice}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={{flexDirection: 'row', alignItems: 'flex-end', maxHeight: 40, marginTop: 0}}>
                                    <Pressable
                                        style={styles.payButton}
                                        onPress={() => handleCheckout()}
                                    >
                                        <Text style={{color:'#fff', marginRight: 5}}>Pay Now</Text>
                                        {loadingCheckout
                                        ? <ActivityIndicator size="small" color="white" animating={loadingCheckout} />
                                        : <Feather name="credit-card" size={16} color="#fff" />
                                        }
                                    </Pressable>
                                </View>
                                    
                            </View>
                        </View>
                    </>
                    :// Status = !Pending Payment && !TBQ, and is company
                    <>
                        <Text style={styles.divTitle}>Price Details</Text>
                        <View style={styles.itemWrapper}>
                            <View style={styles.lineDiv}>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Price (ex. VAT)</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{price}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>VAT (20%)</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text>{tax}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Total</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                        <Text style={{marginRight: 5, fontWeight: 'bold'}}>{totalPrice}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}> {item.quoteInformation.currency}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Payment Method</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}>{item.paymentInformation.paymentMethod}</Text>
                                </View>
                                <View style={styles.lineItemDiv}>
                                    <Text style={styles.itemTitle}>Payment Status</Text>
                                    <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}>{item.paymentInformation.paymentStatus}</Text>
                                </View>                                    
                            </View>
                        </View>
                    </>
                    : // Status = !Pending Payment && !TBQ, and is not company
                    <>
                        <Text style={styles.divTitle}>Price Details</Text>
                        <View style={styles.itemWrapper}>
                            <View style={{flexDirection: 'row', marginVertical: 10, alignItems: 'spacebetween'}}>
                                <View style={{flexDirection: 'row',width: '80%', alignItems: 'flex-end'}}>
                                    <Text style={{marginRight: 5, color: '#000', fontSize: 14,width: '30%'}}>{item.status == 'PP' ? 'Quoted Price': 'Price'}</Text>
                                    <View style={{flexDirection: 'row', width: '30%', alignItems: 'flex-end'}}>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 16, fontWeight: 'bold'}}>{totalPrice}</Text>
                                        <Text style={{marginRight: 5, color: '#000', fontSize: 14, fontWeight: 'bold'}}>{item.quoteInformation.currency}</Text>
                                    </View>
                                    <Text style={{marginRight: 5, color: '#000', fontSize: 10,width: '30%'}}>{item.status == 'PP' ? 'Total amount VAT included': ''}</Text>
                                </View>
                                {item.status == 'PP' ?
                                    <Pressable
                                    style={styles.payButton}
                                    onPress={() => handleCheckout()}
                                    >
                                        <Text style={{color:'#fff', marginRight: 5}}>Pay Here</Text>
                                        {loadingCheckout
                                        ? <ActivityIndicator size="small" color="white" animating={loadingCheckout} />
                                        : <Feather name="credit-card" size={16} color="#fff" />
                                        }
                                    </Pressable>
                                :
                                <Text style={{marginRight: 5, color: '#000', fontSize: 14,width: '30%', fontWeight: 'bold'}}>{item.paymentInformation.paymentStatus}</Text>
                                }
                            </View>
                        </View>
                    </>
                    : //Status = TBQ
                    <></>
                    }
                    {item.status == 'D'?
                    <View style={{flexDirection: 'row', marginVertical: 10, alignItems: 'flex-end', width: '100%'}}>
                        <View style={styles.itemWrapper}>
                        <Text>Proof of delivery</Text>
                        <Image 
                        style={{width: "100%", height: 200, resizeMode: 'contain'}}
                        source={{uri: item.proofOfDeliveryUrl}}
                        />
                        </View>
                    </View>
                    :
                    <></>
                    }
                    <View style={{width: '100%' }}>
                        <Text style={styles.divTitle}>Cargo Details</Text>
                        <View style={styles.itemWrapper}>
                            
                            <View style={{flexDirection: 'row'}}>
                                {item.cargoDetails.type == "moreSpecific" ?
                                <View style={{width: '100%'}}>
                                    <View style={{flexDirection: 'row', marginTop: 10, marginBottom: 5, paddingHorizontal: '0.5REM', alignItems: 'center', justifyContent: 'center'}}>
                                        <Text style={[styles.titleText, {width: '9%'}]}>Qty</Text>
                                        <Text style={[styles.titleText, {width: '20%'}]}>Item Name</Text>
                                        <Text style={[styles.titleText, {width: '30%'}]}>Item Description</Text>
                                        <Text style={[styles.titleText, {width: '9%'}]}>L</Text>
                                        <Text style={[styles.titleText, {width: '9%'}]}>W</Text>
                                        <Text style={[styles.titleText, {width: '9%'}]}>H</Text>
                                        <Text style={[styles.titleText, {width: '9%'}]}>W</Text>
                                        <Text style={[styles.titleText, {width: '9%'}]}></Text>
                                    </View>
                                    <View>
                                        <FlatList
                                        style={{borderRadius: 15, borderColor: '#f5f2f2', borderWidth: 1, paddingHorizontal: '0.5REM', paddingVertical: '1REM'}}
                                        numColumns={1}
                                        removeClippedSubviews
                                        data={item.cargoDetails.items}
                                        extraData={item.cargoDetails.items}
                                        keyExtractor={(item) => item.name}
                                        renderItem={({item, index}) => (<FreightListItem item={item} index={index}/>)}
                                        />
                                    </View>
                                </View>
                                :
                                <>
                                    {item.cargoDetails.type == "standard" ?
                                    <View style={{width: '100%'}}>
                                        <View style={styles.lineDiv}>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Mode</Text>
                                                <Text>{item.cargoDetails.type}</Text>
                                            </View>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Description</Text>
                                                <Text>{item.cargoDetails.description}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.lineDiv}>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Max Length</Text>
                                                <Text>{item.cargoDetails.longestLength} cm</Text>
                                            </View>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Total Weight</Text>
                                                <Text>{item.cargoDetails.totalWeight} Kg</Text>
                                            </View>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Value</Text>
                                                <Text>{item.cargoDetails.value} GBP</Text>
                                            </View>
                                        </View>
                                    </View>
                                    : 
                                    <>
                                    <View style={{width: '100%'}}>
                                        <View style={styles.lineDiv}>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Mode</Text>
                                                <Text>{item.cargoDetails.type}</Text>
                                            </View>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Description</Text>
                                                <Text>{item.cargoDetails.description}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.lineDiv}>
                                            <View style={styles.lineItemDiv}>
                                                <Text style={styles.itemTitle}>Selected Vehicle</Text>
                                                <Text>{ item.cargoDetails.vehicle == 'sv' ? 'Small Van' :
                                                        item.cargoDetails.vehicle == 'swb' ? 'Short Wheel Base Van' :
                                                        item.cargoDetails.vehicle == 'mwb' ? 'Medium Wheel Base Van' :
                                                        item.cargoDetails.vehicle == 'lwb' ? 'Long Wheel Base Van' :
                                                        item.cargoDetails.vehicle == 'xlwb' ? 'Extra Long Wheel Base Van' :'Box Van' 
                                                }</Text>
                                            </View>
                                        </View>
                                    </View>
                                    </>
                                    }
                                </>
                                }
                            </View>
                        </View>
                        {!item.type || item.type === "standard"?
                        <>
                            <Text style={styles.divTitle}>Collection Address</Text>
                            <View style={styles.itemWrapper}>
                                <View style={styles.lineDiv}>
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Contact Person</Text>
                                        <Text>{item.shipmentDetails.collectionDetails.contactPerson}</Text>
                                    </View>
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Contact Phone</Text>
                                        <Text>{item.shipmentDetails.collectionDetails.contactNumber}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineDiv}>
                                    {item.shipmentDetails.collectionDetails.address.description?
                                    <View style={styles.lineDiv}>
                                        <View style={styles.lineItemDiv}>
                                            <Text style={styles.itemTitle}>Address Description</Text>
                                            <Text>{item.shipmentDetails.collectionDetails.address.description}</Text>
                                        </View>
                                    </View>
                                    :
                                    <>
                                    </>
                                    }
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Address</Text>
                                        <Text>{item.shipmentDetails.collectionDetails.address.formatted}</Text>
                                    </View>
                                </View>
                                
                            </View>
                            <Text style={styles.divTitle}>Delivery Address</Text>
                            <View style={styles.itemWrapper}>
                                <View style={styles.lineDiv}>
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Contact Person</Text>
                                        <Text>{item.shipmentDetails.deliveryDetails.contactPerson}</Text>
                                    </View>
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Contact Phone</Text>
                                        <Text>{item.shipmentDetails.deliveryDetails.contactNumber}</Text>
                                    </View>
                                </View>
                                <View style={styles.lineDiv}>
                                    {item.shipmentDetails.deliveryDetails.address.description?
                                    <View style={styles.lineDiv}>
                                        <View style={styles.lineItemDiv}>
                                            <Text style={styles.itemTitle}>Address Description</Text>
                                            <Text>{item.shipmentDetails.deliveryDetails.address.description}</Text>
                                        </View>
                                    </View>
                                    :
                                    <>
                                    </>
                                    }
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Address</Text>
                                        <Text>{item.shipmentDetails.deliveryDetails.address.formatted}</Text>
                                    </View>
                                </View>
                            </View>
                        </>
                        :
                        <>
                        <Text style={styles.divTitle}>Route</Text>
                        {item.shipmentDetails.addresses.map((address, index, array) => (
                        <View style={{flexDirection: "row"}}>
                            <View style={{width: "20%", flex: 1, alignItems: "center"}}>
                                <View style={{position:"absolute",width: 3, height: index == 0 || index === array.length - 1? "50%": "100%", backgroundColor: "#000", top: index == 0? "50%": 0, left: "40%"}}/>
                                <View style={{position:"absolute", width: 15, height: 15, borderRadius: 15, top: "50%", backgroundColor: "#000", marginTop: -7.5, marginLeft: -16.5}}/>
                            </View>
                            <View style={[styles.itemWrapper, {width: "80%"}]}>
                                <Text style={{fontWeight: "500", marginBottom: 10}}>Waypoint {index+1}</Text>
                                <View style={styles.lineDiv}>
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Contact Person</Text>
                                        <Text>{address.name}</Text>
                                    </View>
                                    <View style={styles.lineItemDiv}>
                                        <Text style={styles.itemTitle}>Contact Phone</Text>
                                        <Text>{address.phone}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                                    {address.addressDescription?
                                    <View style={[styles.lineItemDiv,{ flex: 1 }]}>
                                        <Text style={styles.itemTitle}>Address Description</Text>
                                        <Text>{address.addressDescription}</Text>
                                    </View>
                                    :
                                    <></>
                                    }
                                    <View style={[styles.lineItemDiv,{ flex: 1 }]}>
                                        <Text style={styles.itemTitle}>Address</Text>
                                        <Text numberOfLines={2} ellipsizeMode='tail'>{address.address.formatted_address}</Text>
                                    </View>
                                </View>
                            </View>
                        
                        </View>
                        ))}
                        </>
                        }
                    </View>
                </View>
                </View>
            }
        </>  
    )
}
