import { View, Text } from 'react-native'
import React, { useState } from 'react'
import { Container, ContentContainer, ContentContainerWhite, NavTitle, PageTitle, Pagewrapper } from '../../components/layout'
import { FlatList, TextInput, TouchableOpacity } from 'react-native-web'
import IndividualProfileSetUpComponent from '../../components/setupProfile/individualProfile'
import CompanyProfileSetUpComponent from '../../components/setupProfile/companyProfile'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserData } from '../../redux/actions'


export default function SetUpProfileScreen() {
    const dispatch = useDispatch()
    dispatch(getCurrentUserData)
    const currentUserObj = useSelector(state => state.auth)
    console.log(currentUserObj.currentUser.isCompany)
  return (
    <Pagewrapper>
        <Container>
            <ContentContainer>
                <PageTitle>Profile Setup</PageTitle>
            </ContentContainer>
            {currentUserObj.currentUser.isCompany == true?
            <CompanyProfileSetUpComponent/>
            :
            <IndividualProfileSetUpComponent/>
            }      
        </Container>
    </Pagewrapper>
  )
}