import { View, Text, Pressable } from 'react-native'
import React, { useState } from 'react'
import { useMessages } from '../../../hooks/useMessages'
import { TextInput } from 'react-native'
import styles from './styles';
import { TouchableOpacity } from 'react-native-web'
import { sendMessage } from '../../../services/chats'
import { Container, ContentContainer, ContentContainerWhite, Pagewrapper } from '../../../components/layout'
import { FlatList } from 'react-native-web'
import { Feather } from '@expo/vector-icons'; 
import { useNavigation } from '@react-navigation/native'
import ChatSingleItem from './item';
import { useSelector } from 'react-redux';
import NavBar from '../../../components/nav';

const ChatSingleScreen = ({route}) => {
    const navigation = useNavigation()
    const { chatId, closed } = route.params
    const { messages } = useMessages(chatId)
    const user = useSelector(state => state.auth.currentUser)

    const [message, setMessage] = useState('')

    const handleMessageSend = () => {
        if (message.length == 0) {
            return
        }
        setMessage('')
        sendMessage(chatId, message )
    }
    const renderItem = ({ item }) => {
        return <ChatSingleItem item={item} />
    }
    console.log(messages)
    console.log(messages.length !== 0 && messages[0].creator !== user.uid && messages[0].status == 'unread')
    
    return (  
        <Pagewrapper>
            <Container>
                <NavBar active={"support"}/>
                <ContentContainer>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Pressable
                    onPress={() => navigation.navigate('support')}
                    >
                        <Feather name='chevron-left' size={24} color='black' />
                    </Pressable>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>Support Chat</Text>
                    <Pressable
                    disabled
                    style={{width: 24}}
                    >
                        
                    </Pressable>
                </View>
                </ContentContainer>
                
                <ContentContainerWhite style={{height: '75vh'}}>
                    
                        <View style={styles.messagesWrapper}>
                            <View style={[styles.container, { justifyContent: 'flex-start'}]}>
                                <View style={styles.bubbleSize}>
                                    <View style={[styles.bubble, { backgroundColor: '#f5f2f2'}]}>
                                        <View style={{ flexShrink: 1 }}>
                                            <Text style={[styles.messageText, { color: '#262626' }]}>
                                                Welcome to LCC Support
                                                A member of our team will join you shortly.
                                                In the meantime you may write your meassage.
                                        
                                            </Text>
                                        </View>
                                        <View style={styles.bubbleTime}>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <FlatList 
                                data={messages}
                                renderItem={renderItem}
                                keyExtractor={(item) => item.id}
                                inverted={true}
                            />
                        </View>
                            
                        <View style={styles.inputBar}>
                        {closed?
                        <>
                            <TextInput
                            disabled
                            placeholder={'This chat has been closed'}
                            style={styles.input}
                            multiline={false}

                            />
                        </>
                        :
                        <>
                            <TextInput
                            value={message}
                            onChangeText={setMessage}
                            placeholder={'Type a message...'}
                            style={styles.input}
                            multiline={false}
                            onKeyPress={(e) => e.key === 'Enter' && message.length > 0 && handleMessageSend()}

                            />
                            <TouchableOpacity 
                                
                                onPress={()=> handleMessageSend()}
                                style={styles.sendButton}
                                >
                                <Text style={{color: 'white'}}>Send</Text>
                            </TouchableOpacity>
                        </>
                        }
                        </View>

                    
                    
                   
                </ContentContainerWhite>
            </Container>
        </Pagewrapper>
    );
}

export default ChatSingleScreen