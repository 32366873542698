import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flex: 1,
        
    },
    wrapper:{
        alignContent: "space-between",
        justifyContent: "space-between"
    },
    newSupportCaseButton:{
        flexDirection: 'row',
        borderRadius: 20,
        marginHorizontal: -20,
        padding: 20,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between',
    },  
    categoryTitle:{
        flexDirection: 'row',
        borderRadius: 20,
        marginHorizontal: -20,
        padding: 20,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start', 
    },
    subCategoryButton:{
        flexDirection: 'row',
        borderRadius: 20,
        marginHorizontal: -20,
        padding: 20,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    subCategoryIntermediaryButton:{
        flexDirection: 'row',
        borderRadius: 0,
        marginHorizontal: 0,
        paddingVertical: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#f5f2f2',
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
   
});

export default styles;