import { View, Text } from 'react-native'
import React, { useState } from 'react'
import { Container, NavBarWrapper, NavTitle, Pagewrapper, NavButton, NavButtonActive, NavTitleActive, PageTitle, ContentContainerWhite, ContentContainer } from '../../../components/layout';
import ProfileButtonComponent from '../../../components/profileButton';

import { useSelector } from 'react-redux';
import styles from './styles';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-web';
import { Feather } from '@expo/vector-icons'; 
import { FlatList } from 'react-native-web';
import SubCategoryListItem from './subCategoryItem';
import ShipmentHelpListItem from './shipmentItem';
import NavBar from '../../../components/nav';

export default function NewSupportCaseScreen() {

    const [menu, setMenu] = useState('0')
    console.log(menu)
    const AccountHelpData = [
        {
            id: '01',
            helpTitle: 'I want to update my phone number, address or other account information.',
            helpText: 'Navigate to the profile screen by pressing the profile button on the top left, containing your image or initals and your name and email. Once on the profile screen you may edit your account information by clicking on the text.',
            category: 'Account',
            subCategory: 'Update account information',
        },
        {
            id: '02',
            helpTitle: 'I want to change my email.',
            helpText: 'Sadly it is currently not possible to change your account email. We are working to include this in the future.',
            category: 'Account',
            subCategory: 'Change email',
        },
        {
            id: '03',
            helpTitle: 'I want to change my password.',
            helpText: 'Navigate to the profile screen by pressing the profile button on the top left, containing your image or initals and your name and email. Once on the profile screen you may click the "change password" option, after filling in your new password you will receive a confirmation email, open it and follow the steps given there.',
            category: 'Account',
            subCategory: 'Change password',
        },
        {
            id: '04',
            helpTitle: 'I have a question about how LCC is keeping my personal data.',
            helpText: 'You may visit LCC\'s privacy policy at https://lccouriers.com/privacy-policy-app cointaining all the information about how we store your personal data.',
            category: 'Account',
            subCategory: 'Data storage',
        },
        {
            id: '05',
            helpTitle: 'I want to close my account',
            helpText: 'Navigate to the profile screen by pressing the profile button on the top left, containing your image or initals and your name and email. Once on the profile screen you may click the "close account" option. This is only open to accounts that have no ongoing shipments or outstanding invoices.',
            category: 'Account',
            subCategory: 'Account Closure',
        },
        {
            id: '06',
            helpTitle: 'I have an issue that is not listed here.',
            helpText: '',
            category: 'Account',
            subCategory: 'Other',
        },
    ]
    const NewShipmentHelpData = [
        {
            id: '01',
            helpTitle: 'I don\'t know which cargo option to select',
            helpText: 'Standard allows a not too detailed description of your cargo items to be provided, focusing on total weight and length, allowing less input time for you. We may however contact you for further clarification in case the given information is too vague.\n\nMore Specific allows all details of your cargo to be submitted, this may take a bit longer for you but guarantees that all items listed will fit in the allocated vehicle.\n\nVehicle Selection allows you to select a van for your order if you know exactly what you need, this will automatically make the shipment dedicated, allowing only your items to be transported with the selected vehicle.',
            category: 'New Shipment',
            subCategory: 'Which service to select',
        },
        {
            id: '02',
            helpTitle: 'System does not have my address.',
            helpText: 'Navigate to the profile screen by pressing the profile button on the top left, containing your image or initals and your name and email. Once on the profile screen you may edit your account information by clicking on the text.',
            category: 'Account',
            subCategory: 'Change email',
        },
        {
            id: '03',
            helpTitle: 'I have an issue that is not listed here.',
            helpText: '',
            category: 'New Shipment',
            subCategory: 'Other',
        },
    ]
    const PaymentBillingHelpData = [
        {
            id: '01',
            helpTitle: 'My Credit Card is not accepted',
            helpText: 'LCC uses Stripe to handle and process all card payments. If there is an issue it will be with your card provider or stripe, we recommend getting in touch with Stripe\'s support.',
            category: 'Payment',
            subCategory: 'Card issue',
        },
        {
            id: '02',
            helpTitle: 'I have an issue that is not listed here.',
            helpText: '',
            category: 'Payment',
            subCategory: 'Other',
        },
    ]
    const lccBusinessCredit = [
        {
            id: '01',
            helpTitle: 'I have no credit left',
            helpText: 'With LCC Business it is possible to book shipments with credit (invoice option), if you have insufficient remaining credit to book a new shipment, you may need to pay an invoice of an already booked shipment. Please navigate to the invoice page and pay any over due invoice. Paid invoices add the invoiced amount back to your credit, available at your next order.',
            category: 'LCC Business Credit',
            subCategory: 'No credit left',
        },
        {
            id: '02',
            helpTitle: 'I would like to increase my credit',
            helpText: '',
            category: 'LCC Business Credit',
            subCategory: 'Increase Credit',
        },
        {
            id: '03',
            helpTitle: 'I have an issue that is not listed here.',
            helpText: '',
            category: 'LCC Business Credit',
            subCategory: 'Other',
        },
    ]

    const navigation = useNavigation();
    const user = useSelector(state => state.auth.currentUser);
    const currentUserShipments = useSelector(state => state.shipments.currentUserShipments);
    const currentUser = useSelector(state => state.auth.currentUser);
    const renderItem = ({item}) => {
        return(<SubCategoryListItem item={item}/>);
    }
    const renderShipmentItem = ({item}) => {
        return(<ShipmentHelpListItem item={item}/>);
    }
    return (
        <Pagewrapper>
            <Container>
                <NavBar active={"support"}/>
                <ContentContainer>
                    <PageTitle>How may we help?</PageTitle>
                </ContentContainer>
                
                <ContentContainerWhite>
                    {menu == '0'?
                    <>
                        <View style={{marginBottom: 40}}>
                            <TouchableOpacity style={styles.newSupportCaseButton} onPress={() => setMenu('1')} >
                                <NavTitle>Account</NavTitle>
                                <Feather name='chevron-right' size={24} color='black' />
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.newSupportCaseButton} onPress={() => setMenu('2')}>
                                <NavTitle>Shipment</NavTitle>
                                <Feather name='chevron-right' size={24} color='black' />
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.newSupportCaseButton} onPress={() => setMenu('4')}>
                                <NavTitle>Payment</NavTitle>
                                <Feather name='chevron-right' size={24} color='black' />
                            </TouchableOpacity>
                            {currentUser.isBusiness && (
                            <TouchableOpacity style={styles.newSupportCaseButton} onPress={() => setMenu('5')}>
                                <NavTitle>Credit</NavTitle>
                                <Feather name='chevron-right' size={24} color='black' />
                            </TouchableOpacity>
                            )
                            }
                        </View>
                    </>
                    :
                    menu === '1'?
                    <>
                        <View style={{marginBottom: 40}}>
                            <TouchableOpacity style={styles.categoryTitle} onPress={() => setMenu('0')} >
                                <Feather name='chevron-left' size={24} color='black' />
                                <NavTitle>Account</NavTitle>
                            </TouchableOpacity>
                            <FlatList
                                style={styles.container}
                                data={AccountHelpData}
                                renderItem={renderItem}
                                keyExtractor={(item) => item.id}
                            />
                            {currentUser.isCompany && !currentUser.isBusiness && (
                            <TouchableOpacity style={styles.subCategoryButton} onPress={() => setMenu('2')}>
                                <Text>I want to apply for LCC Business</Text>
                                <Feather name='chevron-right' size={24} color='black' />
                            </TouchableOpacity>
                            )}
                        </View>
                    </>
                    :
                    menu === '2'?
                    <>
                        <View style={{marginBottom: 40}}>
                            <TouchableOpacity style={styles.categoryTitle} onPress={() => setMenu('0')} >
                                <Feather name='chevron-left' size={24} color='black' />
                                <NavTitle>Shipment</NavTitle>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.subCategoryIntermediaryButton} onPress={() => setMenu('3')}>
                                <Text>I have a question about ordering a shipment.</Text>
                                <Feather name='chevron-right' size={24} color='black' />
                            </TouchableOpacity>
                            <Text style={{marginBottom: 15, marginTop: 20}}>Please select the shipment requiring assistance.</Text>
                            <FlatList
                                style={styles.container}
                                data={currentUserShipments}
                                removeClippedSubviews
                                renderItem={renderShipmentItem}
                                keyExtractor={(item) => item.id}
                            /> 
                        </View>
                    </>
                    :
                    menu === '3'?
                    <>
                        <View style={{marginBottom: 40}}>
                            <TouchableOpacity style={styles.categoryTitle} onPress={() => setMenu('2')} >
                                <Feather name='chevron-left' size={24} color='black' />
                                <NavTitle>Shipment Order</NavTitle>
                            </TouchableOpacity>
                            <FlatList
                                style={styles.container}
                                data={NewShipmentHelpData}
                                renderItem={renderItem}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    </>
                    :
                    menu === '4'?
                    <>
                        <View style={{marginBottom: 40}}>
                            <TouchableOpacity style={styles.categoryTitle} onPress={() => setMenu('0')} >
                                <Feather name='chevron-left' size={24} color='black' />
                                <NavTitle>Payment</NavTitle>
                            </TouchableOpacity>
                            <FlatList
                                style={styles.container}
                                data={PaymentBillingHelpData}
                                renderItem={renderItem}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    </>
                    :
                    <>
                        <View style={{marginBottom: 40}}>
                            <TouchableOpacity style={styles.categoryTitle} onPress={() => setMenu('0')} >
                                <Feather name='chevron-left' size={24} color='black' />
                                <NavTitle>LCC Business Credit</NavTitle>
                            </TouchableOpacity>
                            <FlatList
                                style={styles.container}
                                data={lccBusinessCredit}
                                renderItem={renderItem}
                                keyExtractor={(item) => item.id}
                            />
                        </View>
                    </>
                    }
                    
                    
                   
                </ContentContainerWhite>
            </Container>
        </Pagewrapper>
    );
}