import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flex: 1,
        marginTop: 10,
    },
    listHeader:{
        flex: 1,
        paddingHorizontal:15,
        paddingVertical:15,
        borderRadius: 12,
        backgroundColor: '#d72323',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 3,
        width: '100%',

    },
    listHeaderText:{
        color: '#fff',
        fontSize: 12,
        fontWeight: '600',
        width: '19%',
        marginRight: 5,
    },
    listHeaderText1:{
        color: '#fff',
        fontSize: 12,
        fontWeight: '600',
        textAlign: 'right',
        width: '20%',
        marginRight: 5,
    }
    

});

export default styles;