import { View, Text } from 'react-native'
import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native-web'
import styles from './styles'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { Feather } from '@expo/vector-icons'; 
import { createChat } from '../../../../services/chats'

const SubCategoryListItem = ({ item }) => {
    const navigation = useNavigation()
    const [dropdown, setDropdown] = useState(false)
    const category = item.category
    const subCategory = item.subCategory
    const shipmentId = false
    console.log(category, subCategory)
    const handleCreateChat = () => {
        console.log(item)
        createChat(shipmentId, category, subCategory).then((res) => navigation.navigate('chatSingle', { chatId: res.id }))
    }
  return (
    <View style={styles.container}>
        <TouchableOpacity style={styles.subCategoryButton} onPress={() => setDropdown(!dropdown)}>
            <Text>{item.helpTitle}</Text>
            <Feather name={dropdown?'chevron-up':'chevron-down'} size={24} color='black' />
        </TouchableOpacity>
        <View style={{display: dropdown? 'flex':'none', paddingHorizontal: 24, marginRight: 24}}>
            <Text style={styles.helpText}>{item.helpText}</Text>
            <View style={{flex:1, alignItems: 'center', justifyContent: 'center', paddingVertical: 40}}>
                <TouchableOpacity style={styles.chatButton} onPress={() => handleCreateChat(item)}>
                <Feather name="message-circle" size={24} color="white" />
                </TouchableOpacity>
                <Text>Chat with us</Text>
            </View>
        </View>
    </View>
    
  )
}

export default SubCategoryListItem
