import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        
    },
    wrapper:{
        alignContent: "space-between",
        justifyContent: "space-between"
    },
    newSupportCaseButton:{
        flexDirection: 'row',
        borderRadius: 20,
        padding: 20,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between'

    },  
   
});

export default styles;