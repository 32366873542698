import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        backgroundColor: '#fff',  
        borderRadius: 12,
        marginVertical: 3,
        overflow: 'hidden',
    },
    listText:{
        fontSize: 16,
        color: '#000',
        marginRight: 5,
    },
    itemButton:{
        marginLeft: 5, 
        marginTop: 4,
        width: '5%'
    }
});

export default styles;