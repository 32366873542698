import React from 'react'
import { View, Text } from 'react-native'
import { Feather } from '@expo/vector-icons'; 
import { ActivityIndicator, Pressable } from 'react-native-web';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AntDesign } from '@expo/vector-icons'; 
import { TouchableOpacity } from 'react-native-web';

import styles from './styles'
import { downloadPDF, getPDF } from '../../../redux/actions';


export default function InvoicesListItem({item}) {
    const currentUserObj = useSelector(state => state.auth)
    const [requested, setRequested] = useState(false) 
    const [pdfLoading, setPdfLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)

    const price = (item?.quoteInformation?.price/100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
    const tax = (item?.quoteInformation?.tax/100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
    const totalPrice = ((item?.quoteInformation?.price + item?.quoteInformation?.tax)/100).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
    
    const actionDate = new Date(item.shipmentDetails.dateAndTime.date)
    const issueDate = actionDate.toString().slice(4, 15)
    const newDueDate = new Date(actionDate.setDate(actionDate.getDate() + parseInt(currentUserObj.currentUser.paymentTerms))).toString().slice(4, 15);
    const urgent = item.shipmentDetails.shipmentOptions.urgentCollection == true? "Urgent" : "";
    const t = item.shipmentDetails.shipmentOptions.urgentCollection == true? "t" : "T";
    const dedicated = item.shipmentDetails.shipmentOptions.dedicatedVehicle == true? "dedicated" : "";
    const vehicle = item.allocatedVehicle == "sv"? 'Small Van': item.allocatedVehicle == "swb"? 'Short Wheel Base Van': item.allocatedVehicle == "mwb"? 'Medium Wheel Base Van': item.allocatedVehicle == "lwb"? 'Long Wheel Base Van': item.allocatedVehicle == "xlwb"? 'Extra Long Wheel Base Van': 'Box Van';


    
    function formatPostcodeString(posts) {
        const firstPostcode = posts[0].address.formatted_address;
        const lastPostcode = posts[posts.length - 1].address.formatted_address;
        const viaPostcodes = posts.slice(1, -1).map(post => post.address.formatted_address).join(', ');
      
        return `from ${firstPostcode} via ${viaPostcodes} to ${lastPostcode}`;
    }

    const route = !item.type || item.type == "standard" ?  `from ${item.shipmentDetails.collectionDetails.address.formatted} to ${item.shipmentDetails.deliveryDetails.address.formatted}` : formatPostcodeString(item.shipmentDetails.addresses)
    const itemText = `${item.uid}. ${urgent} ${t}ransportation with ${dedicated} ${vehicle} ${route}.`
    const co = currentUserObj.currentUser.company

    const data ={
        companyDetails:{
            name: co.companyName,
            number: co.companyNumber,
            addressLine: `${co.registeredAddress.premises} ${co.registeredAddress.address_line_1}`,
            localityAndPostcode: `${co.registeredAddress.postal_code} ${co.registeredAddress.locality}`,
            country: "United Kingdom",
            vatId: co.taxNumber,  
        },
        ShipmentDetails:{
            id: item.uid,
            invoiceNumber: item.invoiceNumber,
            amount: `£${price}`,
            tax: `£${tax}`,
            total: `£${totalPrice}`,
            issueDate: `${issueDate}`,
            dueDate: `${newDueDate}`,
            itemDescription: itemText,
        }
    }
    const handleGetPDF = () =>{
        setPdfLoading(true)
        getPDF(data).then(response => {
            setPdfLoading(false)
        })
    }
    const handleDownloadPDF = () => {
        setDownloadLoading(true)
        downloadPDF(data).then(response => {
            setDownloadLoading(false)
        })
    }

    return (
        
        <>
            {!requested? //Has the invoice been pressed to be opened
            <Pressable style={styles.container} onPress={() => setRequested(true)}>  
                <Text style={[styles.listText, {width: '20%'}]}>INV-{item.invoiceNumber}</Text>
                <Text style={[styles.listText, {width: '20%'}]}>{totalPrice} {item.quoteInformation.currency}</Text>
                <Text style={[styles.listText, {width: '30%'}]}>{newDueDate}</Text>
                <Text style={[styles.listText1, {width: '30%'}]}>{item.paymentInformation.paymentStatus}</Text>
                <Pressable
                style={styles.itemButton}
                onPress={() => setRequested(true)}>
                    <Feather name="maximize-2" size={14} color="#000" />
                </Pressable>
            </Pressable>
            :
            <View style={[styles.container, {alignItems: 'flex-start', flexDirection: 'column',paddingTop: 11, paddingBottom: 50}]}>
                <Pressable style={{flexDirection: 'row', width: '100%'}} onPress={() => setRequested(false)}>
                    <Text style={[styles.listText, {width: '20%'}]}>INV-{item.invoiceNumber}</Text>
                    <Text style={[styles.listText, {width: '20%'}]}>{totalPrice} {item.quoteInformation.currency}</Text>
                    <Text style={[styles.listText, {width: '30%'}]}>{newDueDate}</Text>
                    <Text style={[styles.listText1, {width: '30%'}]}>{item.paymentInformation.paymentStatus}</Text>
                    <Pressable
                    style={styles.itemButton}
                    onPress={() => setRequested(false)}
                    >
                        <Feather name="minimize-2" size={14} color="#000" />
                    </Pressable>
                </Pressable>
                <Text style={styles.divTitle}>Invoice Details</Text>
                <View style={styles.itemWrapper}>
                    <View style={styles.lineDiv}>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Quote (ex. VAT)</Text>
                            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                <Text>{price}</Text>
                                <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                            </View>
                        </View>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>VAT (20%)</Text>
                            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                <Text>{tax}</Text>
                                <Text style={{marginRight: 5, color: '#000', fontSize: 12}}> {item.quoteInformation.currency}</Text>
                            </View>
                        </View>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Total</Text>
                            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                <Text style={{marginRight: 5, fontWeight: 'bold'}}>{totalPrice}</Text>
                                <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}> {item.quoteInformation.currency}</Text>
                            </View>
                        </View>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Payment Method</Text>
                                <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}>{item.paymentInformation.paymentMethod}</Text>
                        </View>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Payment Status</Text>
                            <Text style={{marginRight: 5, color: '#000', fontSize: 12, fontWeight: 'bold'}}>{item.paymentInformation.paymentStatus}</Text>
                        </View>                                    
                    </View>
                    <View style={styles.lineDiv}>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Issue Date</Text>
                            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                <Text>{issueDate}</Text>
                            </View>
                        </View>
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Due Date</Text>
                            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                <Text>{newDueDate}</Text>
                            </View>
                        </View>   
                        <View style={styles.lineItemDiv}>
                            <Text style={styles.itemTitle}>Reference</Text>
                            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                                <Text>{item.uid}</Text>
                            </View>
                        </View>                                 
                    </View>
                </View>
                <View style={{flexDirection: 'row', marginTop: 10}}>
                    <TouchableOpacity 
                        style={{padding: 5, borderRadius: 10, marginRight: 10}}
                        onPress={() => handleGetPDF()}
                        >
                        {pdfLoading?
                        <ActivityIndicator
                        animating={pdfLoading} size="small" color="#000"/>
                        :
                        <AntDesign name="pdffile1" size={20} color="black" />     
                        }
                        
                    </TouchableOpacity>
                    <TouchableOpacity 
                        style={{padding: 5, borderRadius: 10, marginRight: 10}}
                        onPress={() => handleDownloadPDF()}
                        >
                        {downloadLoading?
                        <ActivityIndicator
                        animating={downloadLoading} size="small" color="#000"/>
                        :
                        <AntDesign name="download" size={20} color="black" />     
                        }
                    </TouchableOpacity>
                </View>
            </View>
            
            }
        </>  
        
    )
}
