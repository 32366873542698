import { ImageBackground, View} from 'react-native';
import React, { useState } from 'react'
import { ActivityIndicator, CheckBox, Text, TextInput, TouchableOpacity } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';

import bg001 from '../../../assets/bg-001.jpg'

import styles from './styles'
import { register } from '../../redux/actions/auth';
import { Box, BoxRed, Button, ContentWrapper, Header, LoginContainer } from '../../components/layout';
import { SvgLogoWhite } from '../../components/svg/logoWhite';


export default function SignUpScreen() {

const [isCompany, setIsCompany] = useState(Boolean)
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [show, setShow] = useState(false)

const navigation = useNavigation()
const dispatch = useDispatch()
const registerState = useSelector(state => state.auth.registerUser)


const handleSignUp =() => {
    dispatch(register(email, password, isCompany)).then(() => {
      console.log('register successful')
    })
    .catch((error) => {
        console.log('register unsuccessful')
        console.log(error)
        
    })
}
  return (
    <>
    <View style={{backgroundColor: 'none'}}>
      <ImageBackground
        resizeMode="cover"
        style={{width: '100VW', height: '100VH'}}
        source={bg001}
      >
      <SvgLogoWhite
      style={{position: 'absolute', width: 300, left: 0, bottom: 20}}
      />
      <LoginContainer>
        <ContentWrapper>
          <BoxRed>
              <Header>Sign up to LCC</Header>
              <View style={styles.signUpContainer}>
                <Text style={styles.noAccountText}>Already have an account? </Text>
                <TouchableOpacity 
                  style={styles.smallSignupButton}
                  onPress={() => navigation.navigate("signin")}
                >
                  <Text style={styles.smallSignupButtonText}>Sign in</Text>
                </TouchableOpacity>
              </View>
              <View style={{height: 180}}>
                {registerState.loading?
                
                  <View style={{alignItems: 'center', justifyContent: 'center'}}>
                    <ActivityIndicator 
                    size="large"
                    style={{paddingVertical:  20}}
                    />
                    <Text style={styles.loadingText}>{registerState.generating? "Creating Account...":"Loading..."}</Text>
                  </View>
                
                :
                <>
                  <Text>I am:</Text>
                  <View style={{flexDirection: 'row'}}>
                    <View style={styles.checkBoxContainer}>
                      <CheckBox
                          value={isCompany == false}
                          onValueChange={() => setIsCompany(false)}
                          style={[styles.checkbox, {alignSelf: 'center', marginRight: 10}]}
                          color={'#d72323'}
                      />
                      <Text>an Individual</Text>
                    </View>
                    <View style={styles.checkBoxContainer}>
                      <CheckBox
                          value={isCompany}
                          onValueChange={() => setIsCompany(true)}
                          style={[styles.checkbox, {alignSelf: 'center', marginRight: 10}]}
                          color={'#d72323'}
                      />
                      <Text>a Company</Text>
                    </View>
                  </View>
                  <TextInput
                    onChangeText={(text) => setEmail(text)}
                    style={styles.textInput}
                    placeholder={isCompany?'Business Email':'Email'}
                    autoCompleteType='email'
                    underlineColorAndroid="transparent"
                    >

                  </TextInput>
                  <View style={{flexDirection: 'row', flex: 1, alignItems: 'center', marginTop: 35}}>
                    <TextInput
                      onChangeText={(text) => setPassword(text)}
                      style={[styles.textInput, {width: '100%'}]}
                      placeholder='Password'
                      secureTextEntry={!show}
                    />
                    <TouchableOpacity onPress={() => setShow(!show)}>
                        <Feather name={show? 'eye':'eye-off'} size={20} color='black' style={{marginBottom: 15,marginLeft: -34,paddingVertical: 17}} />
                    </TouchableOpacity>
                  </View>
                </>
                }
              </View>
              
              
              <View style={{position: 'relative', paddingVertical: 30, marginTop: 30}}>
                <Button style={{margin: 0}}
                  disabled={registerState.loading}
                  onPress={() => handleSignUp()}>
                  <Text style={styles.buttonText} >Sign Up</Text>
                </Button>
                {registerState.loading == false && registerState.error !== null?
                <View style={styles.errorMessageContainer}>
                  <Text style={styles.errorText}>{registerState.error.substr(10)}</Text>
                </View>
                :
                <></>
                }
              </View>
              

              
          </BoxRed>
          {/* <View style={{minWidth: "30%", paddingHorizontal: 50, paddingVertical: 80, backgroundColor: "#ffffff45"}}>
            <Text style={{color: "white"}}>Signup aide</Text>
          </View> */}
        <Box>
        
        </Box>
        </ContentWrapper>
      </LoginContainer>
      </ImageBackground>
    </View>

    </>
    
      
    
  );
}

