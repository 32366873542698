import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { auth, db, getCurrentUserData } from "../redux/actions";

export const saveNewTempelate = (data) => new Promise(async(resolve, reject) => {
    const userUid = auth.currentUser.uid;
    const docRef = doc(db, "users", userUid);
    console.log(data)
    await updateDoc(docRef, {
        templates: arrayUnion(data),
    }).then(() => {
        console.log("Template added successfully!");
        resolve();
    }).catch((error) => {
        console.error("Error adding template: ", error);
        reject(error);
    });
});

export const removeTemplate = (updatedTemplates) => new Promise(async(resolve, reject) => {
    const userUid = auth.currentUser.uid;
    const docRef = doc(db, "users", userUid);
    await updateDoc(docRef, {
        templates: updatedTemplates
    }).then(() => {
        console.log("Template deleted successfully!");
        resolve();
    }).catch((error) => {
        console.error("Error deleting template: ", error);
        reject(error);
    });
});
  
  
export const updateTemplate = (templateId, newData) => new Promise(async(resolve, reject) => {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("No authenticated user found.");
    }
  
    const userDocRef = doc(db, "users", user.uid);
  
    // get the current templates array from Firestore
    const userDocSnapshot = await userDocRef.get();
    const templatesArray = userDocSnapshot.data()?.templates || [];
  
    // find the index of the template with the specified ID
    const index = templatesArray.findIndex((template) => template.id === templateId);
    if (index === -1) {
        throw new Error(`No template found with ID ${templateId}`);
    }
  
    // remove the old template from the array
    const updatedTemplatesArray = templatesArray.filter((_, i) => i !== index);
  
    // add the new template to the array in the same position
    updatedTemplatesArray.splice(index, 0, { id: templateId, ...newData });
  
    // update the templates array in Firestore
    await updateDoc(userDocRef, {
      templates: updatedTemplatesArray,
    });
    console.log("Template updated in Firestore!");
})
  
  
  