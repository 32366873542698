import { StyleSheet  } from 'react-native';

const styles = StyleSheet.create({
    container:{
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#f5f2f2',
    },
    subCategoryButton:{
        flexDirection: 'row',
        borderRadius: 20,
        marginHorizontal: -20,
        padding: 20,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    chatButton:{
        borderRadius: 45,
        width: 45,
        height: 45,
        backgroundColor: 'gray',
        alignItems: 'center',
        justifyContent: 'center'

    },  
   
});

export default styles;
